/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SELECTED_PACKS } from './actions';
const initialState = {};

export default function packagesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SELECTED_PACKS:
            return Object.assign({}, state, {
                selectedPack: payload
            });
        default:
            break;
    }
    return state;
}