/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { withSuspense, isEmptyOrSpaces, redirectToPage } from '../../components/common/utils';
import { getItemFromBrowserStorage } from '../../components/common/utils/storage.utils';
import { NAVIGATE_MEMBER_DASHBOARD, NAVIGATE_MEMBER_LOGIN } from '../../components/common/utils/urlConstants';
import CommonMessage from '../../components/ui/errors/CommonMessage';
import { _IMAGE_BASEURL } from '../../components/common/config/config';
import { store } from '../../components/common/middleware/store';
import parse from 'html-react-parser';

class _CommonErrorPage extends Component {
    componentDidMount() {
        const state = store.getState()
        const { token } =  state.tokenReducer
        if (isEmptyOrSpaces(token)) {
            redirectToPage(NAVIGATE_MEMBER_LOGIN)
        } else {
            const props = {
                includeHeader: false
            }
            this.props.setErrorPageInfo(props)
        }
    }
    render() {
        const { t } = this.props
        return <CommonMessage
            id="id-error-message"
            image={``}
            imageType="external"
            imgAlt="system outage image"
            title={`<h2>${t('errorMessages.title')}</h2>`}
            description1={parse(t('errorMessages.desc1'))}
            description2={t('errorMessages.desc2')}
            buttons={[
                {
                    id: "",
                    label: "Go to Your Airpoints™",
                    link: NAVIGATE_MEMBER_DASHBOARD
                }
            ]}
        />
    }
}

export default withSuspense()(withTranslation()(_CommonErrorPage));