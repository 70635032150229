/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const ACTION_RETRIEVE_ACCOUNT_USERS_SUCCESS = "ACTION_RETRIEVE_ACCOUNT_USERS_SUCCESS"
export const ACTION_ADD_ACCOUNT_USER = "ACTION_ADD_ACCOUNT_USER"

export const ACTION_UPDATE_ACCOUNT_USER_ROLE = "ACTION_UPDATE_ACCOUNT_USER_ROLE"
export const ACTION_UPDATE_ACCOUNT_USER_DELETE = "ACTION_UPDATE_ACCOUNT_USER_DELETE"

export const ACTION_DELETE_ACCOUNT_USER_SUCCESS = "ACTION_DELETE_ACCOUNT_USER_SUCCESS"

export const EDIT_ROLE = "edit_role"
export const DELETE_MEMBER = "delete_member"
export const ADD_ACCOUNT_USER = "ADD_ACCOUNT_USER"
export const RESPONSE_SUCCESS = "SUCCESS"
export const ACCOUNT_HOLDER = "Account Holder"
export const VALIDATE_MEMBER = "validateMember"
export const ADD_MEMBER = "addMember"
export const ACCOUNT_STATUS = "nomineeStatus"

export const SUCCESS = "success"
export const DANGER = "danger"
export const DELETED = "Deleted"
export const PENDING = "Pending"
export const ACTIVE = "A"
export const TAB_MEMBER = "member"
