/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { getCurrentPageUri, isEmptyOrSpaces, redirectToPage } from ".";
import { _WHITELIST_LANGUAGES } from "../config/config";
import { ROUTER_CONFIG } from "../config/routing";
import { store } from "../middleware/store";
import { CLIENT_ID, CLIENT_TYPE, REDIRECT_TO, SECRET } from "./Constants";
import {
    BROWSER_STORAGE_KEY_ACCESS_KEY,
    BROWSER_STORAGE_KEY_CID,
    BROWSER_STORAGE_KEY_i18_LANG,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE,
    BROWSER_STORAGE_KEY_SELECTED_TAB,
    getItemFromBrowserStorage,
    setItemToBrowserStorage
} from "./storage.utils";
import { NAVIGATE_MEMBER_LOGIN } from "./urlConstants";
import { _URL_PARAMS_ACCESS_KEY, _URL_PARAMS_CID, _URL_PARAMS_LANG, _URL_PARAMS_TAB, _URL_PARAMS_SOURCE } from "./urlParams.utils";
import { isPatternMatch } from "./validation.utils";

/**
 * @description Method to extract the token from the URL
 * @author Somdas M
 */
export const handleQueryParamsInURL = (p) => {
    const { search, href } = window.location
    const queryParams = new URLSearchParams(search);
    let url = href.split("?")
    let _appUrl = url[0]
    let found = false
    queryParams.forEach((value, key) => {
        const pattern = ROUTER_CONFIG.urlParamConfig[key] ? ROUTER_CONFIG.urlParamConfig[key].pattern : ["."]
        if (isPatternMatch(pattern, getCurrentPageUri())) {
            found = true
            switch (key) {
                case _URL_PARAMS_ACCESS_KEY:
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY, value);
                    break;
                case _URL_PARAMS_CID:
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_CID, value);
                    break;
                case _URL_PARAMS_LANG:
                    const language = _WHITELIST_LANGUAGES.find(l => l.code == value)
                    if (language && Object.keys(language).length) {
                        setItemToBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG, JSON.stringify(language));
                    }
                    break;
                case _URL_PARAMS_TAB:
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_SELECTED_TAB, value);
                    break;
                case _URL_PARAMS_SOURCE:
                    
                    break;

                default:
                    if (_appUrl.includes("?")) {
                        _appUrl += `&${key}=${value}`
                    } else {
                        _appUrl += `?${key}=${value}`
                    }
                    console.debug("received [", key, "]=[", value, "]")
            }
        }
    })
    if(found) {
        window.history.pushState({}, null, _appUrl)
        return
    }
}

/**
 * @description Method to set the redirection url
 * @author Somdas M
 */
export const handlePageRedirectionURL = () => {
    const pageConfig = getCurrentPageConfigFromRouterConfig()
    if (pageConfig && pageConfig.isProtected) {
        const { search } = window.location
        if (pageConfig.url.includes('/member/benefits')) {
            redirectToPage(`${ROUTER_CONFIG.default.url}?${REDIRECT_TO}=${'/member/benefits'}`)
        } else {
            redirectToPage(`${ROUTER_CONFIG.default.url}?${REDIRECT_TO}=${pageConfig.url+search}`)
        }
    }
}
export const getCurrentPageConfigFromRouterConfig = () => {
    const currentPage = getCurrentPageUri()
    return ROUTER_CONFIG.routes.find(config => config.url.includes(currentPage))

}

/**
 * @description Method to redirect to the redirectTo url
 * @author Somdas M
 */
export const redirectPage = (defaultConfig, _url = undefined, _fetchToken = undefined) => {
    const state = store.getState()
    const { token } =  state.tokenReducer
    const _programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
    const accessKey = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY)
    const cid = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CID)
    const { search } = window.location
    let redirectionBaseUrl = _url
    if (search && search.includes("?")) {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has(REDIRECT_TO)) {
            const { search } = window.location
            redirectionBaseUrl = search.split("?redirectTo=")?.[1]
            if (queryParams.has(CLIENT_TYPE)) {
                if (!redirectionBaseUrl.includes(_URL_PARAMS_ACCESS_KEY)) {
                    redirectionBaseUrl = redirectionBaseUrl.concat(`${redirectionBaseUrl.includes("?") ? '&' : '?'}${_URL_PARAMS_ACCESS_KEY}=${encodeURIComponent(accessKey)}`)
                }
                if (!redirectionBaseUrl.includes(_URL_PARAMS_CID)) {
                    redirectionBaseUrl = redirectionBaseUrl.concat(`${redirectionBaseUrl.includes("?") ? '&' : '?'}${_URL_PARAMS_CID}=${encodeURIComponent(cid)}`)
                }
            }
        }
    }
    const pageConfig = getCurrentPageConfigFromRouterConfig()
    if((isEmptyOrSpaces(accessKey) || isEmptyOrSpaces(cid)) && (pageConfig && pageConfig.isProtected)){
        redirectToPage(`${ROUTER_CONFIG[_programType].loginUrl}`)
    }else if (_fetchToken) {
        // _fetchToken(defaultConfig , redirectionBaseUrl)
    }
    else if (redirectionBaseUrl && !isEmptyOrSpaces(token)) {
        redirectToPage(redirectionBaseUrl)
    }
}

