/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { TAB_SECTION } from "./Constants";

export default function tabsReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = ""
    if (type === TAB_SECTION) {
        return {
            tabSection: payload
        }
    }
    return state;
}
