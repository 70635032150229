/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const API_LOADING_START = "API_LOADING_START"
export const API_LOADING_STOP = "API_LOADING_STOP"
export const BTN_LOADING_START = "BTN_LOADING_START"
export const BTN_LOADING_STOP = "BTN_LOADING_STOP"

export const startApiLoading = (actionName) => {
    return dispatch => {
        dispatch({
            type: API_LOADING_START,
            payload: {
                pointer: 1,
                actionName
            }
        })
    };
};

export const stopApiLoading = (actionName) => {
    return dispatch => {
        dispatch({
            type: API_LOADING_STOP,
            payload: {
                pointer: -1,
                actionName
            }
        })
    };
};

export const startButtonSpinner = (id, actionName) => {
    return dispatch => {
        dispatch(startApiLoading(actionName))
        dispatch({
            type: BTN_LOADING_START,
            payload: {
                id
            }
        })
    };
};

export const stopButtonSpinner = (id, actionName) => {
    return dispatch => {
        dispatch(stopApiLoading(actionName))
        dispatch({
            type: BTN_LOADING_STOP,
            payload: {
                id
            }
        })
    };
};