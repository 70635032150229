/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SET_POINTS } from './actions';
const initialState = {};

export const convertPointsReducer = (state, action) => {
    const { type, payload } = action;
    if(!state) state = initialState
    if (type === SET_POINTS) {
        return {
            pointsConverted: payload
        }
    } else {
        return state;
    }
}