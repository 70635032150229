/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

/**
 * Performs Regex validations on value and returns a result object.
 * @param {*} validations array of validation objects
 * @param {*} value value to be validated
 * @author Ajmal V Aliyar
 */
export const performValidationsOnValue = (validations, value) => {
    let response ={
        isError: false,
        errorMessages:[]
    }
    const _value = JSON.stringify(value)
    if (validations && Array.isArray(validations)) {
        validations.forEach(validation => {
            if (!isPatternMatch(validation.pattern, value)) {
                let isError = true
                if (validation.ignoreValues &&
                    validation.ignoreValues.length &&
                    typeof _value === "string") {
                    validation.ignoreValues.forEach(v => {
                        const _v = JSON.stringify(v)
                        if (isError && _value.replace(/ /g, '').toLowerCase() === _v.replace(/ /g, '').toLowerCase()) isError = false
                    })
                }
                response.isError = isError
                if (isError) response.errorMessages.push(validation.customMessageId)
            }
        })
    } else {
        console.error("Validations should be array. Received instead : ", validations);
    }
    return response
}

/**
 * Returns if the value matches the Regex pattern
 * @param {string} pattern
 * @param {string} value
 * @author Somdas M
 */
export const isPatternMatch = (pattern, value)=>{
    value = value?value:""
    const regex = new RegExp(pattern)
    return regex.test(value)
}