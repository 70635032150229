/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

// Action Type
export const SELECTED_PACKS = "SELECTED_PACKS";

export const selectPackage = config => {
  return {
      type: SELECTED_PACKS,
      payload: config
  }
}