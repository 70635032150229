/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SET_PIN_CHANGE } from './actions';

const initialState = {};

/**
 * @description Reducer for change PIN
 * @param {*} state default state to be returned
 * @param {*} action Contains the type of action and the payload
 * @author Ajmal Aliyar
 */
export default function changePinReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = initialState
    if (type === SET_PIN_CHANGE) {
        return {
            changedPin: payload
        }
    } else {
        return state;
    }
}