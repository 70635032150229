/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
    // GET_TRANSACTION_DATA,
    CONFIG_MY_ACTIVITY_SUCCESS,
    // MY_ACTIVITY_AUTHENTICATE_TRANSACTION_SUCCESS,
    // MY_ACTIVITY_LOGOUT_TRANSACTION_SUCCESS,
    RETRIEVE_ACTIVITY_DETAIL_SUCCESS
} from './actions';

/**
 * Reducer for the page My Activity.
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 * @author Ajmal Aliyar
 */
export default function myActivityReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    switch (type) {
        case RETRIEVE_ACTIVITY_DETAIL_SUCCESS:
            return {
                ...state,
                activityDetail: payload.object
            }
        case CONFIG_MY_ACTIVITY_SUCCESS:
            return {
                ...state,
                config: payload.config
            }
        // case MY_ACTIVITY_AUTHENTICATE_TRANSACTION_SUCCESS:
        //     return {
        //         ...state,
        //         authenticateTransaction: payload.object
        //     }
        // case MY_ACTIVITY_LOGOUT_TRANSACTION_SUCCESS:
        //     return {
        //         ...state,
        //         logoutTransaction: payload.object
        //     }

        default:
            return state;
    }
}
