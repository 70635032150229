/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE = "memberPreferenceDetails"
export const KEY_DISPLAYORDER = "displayOrder"
export const MESSAGE_TYPE_SUCCESS = "success"
export const MESSAGE_TYPE_DANGER = "danger"
export const UPDATE_PREFERENCE_BTN = "update-preference-button"