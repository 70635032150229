/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const ID_SEND_BUTTON = "id-send-button"
export const ID_CANCEL_BUTTON = "id-cancel-button"

export const SET_LOGIN_DATA = "SET_LOGIN_DATA";
export const SET_SOCIAL_LOGIN_DATA = "SET_SOCIAL_LOGIN_DATA"
export const REFERRAL_DATA = "SET_REFERRAL_DATA";
export const REFERRAL_ERROR = "SET_REFERRAL_ERROR";

export const REFERRAL_API_REFERENCE = "referral";

