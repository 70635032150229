/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { startButtonSpinner, stopButtonSpinner } from '../../../common/components/fieldbank/loader/action';
import {
    _URL_ADD_FAMILY_MEMBER,
    _URL_FAMILY_ENROLMENT, _URL_SEARCH_MEMBER
} from '../../../common/config/config';
import { SET_FAMILY_MEMBERS } from '../../../common/middleware/redux/commonAction';
import { getApiErrorMessage } from '../../../common/utils';
import { doPost } from '../../../common/utils/api';
// Action Type
export const NEW_FAMILY_MEMBER_SEARCH = "NEW_FAMILY_MEMBER_SEARCH"
export const NEW_FAMILY_MEMBER_SEARCH_ERROR = "NEW_FAMILY_MEMBER_SEARCH_ERROR"
export const ADD_FAMILY_MEMBER = "ADD_FAMILY_MEMBER"
export const ADD_FAMILY_MEMBER_ERROR = "ADD_FAMILY_MEMBER_ERROR"
export const ADD_FAMILY_CONFIG = "ADD_FAMILY_CONFIG"
export const ADD_FAMILY_CONFIG_ERROR = "ADD_FAMILY_CONFIG_ERROR"
export const ENROL_FAMILY_MEMBER = "ENROL_FAMILY_MEMBER"
export const FETCH_FAMILY_LIST = "FETCH_FAMILY_LIST"

export const searchMember = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "searchMember"))
        await doPost(_URL_SEARCH_MEMBER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "searchMember"))
                dispatch({
                    type: NEW_FAMILY_MEMBER_SEARCH,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "searchMember"))
                dispatch({
                    type: NEW_FAMILY_MEMBER_SEARCH_ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const addFamilyMember = (payload, id) => {
    if (!payload.object.customerDynamicAttribute.find(attr => attr.attributeCode == "61")) {
        payload.object.customerDynamicAttribute.push(
            {
                "dynamicAttributeGroupName": "Program Status",
                "attributeCode": "61",
                "attributeValue": "SADM",
                "type": "C",
                "operationFlag": "I"
            }
        )
    }
    return dispatch => {
        dispatch(startButtonSpinner(id, "addFamilyMember"))
        doPost(_URL_ADD_FAMILY_MEMBER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "addFamilyMember"))
                dispatch({
                    type: ADD_FAMILY_MEMBER,
                    payload: response.data
                })
                // dispatch({
                //     type: ERROR,
                //     payload: { error: ['add_family_member.add_existing_member.successMessage'] }
                // })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "addFamilyMember"))
                dispatch({
                    type: ADD_FAMILY_MEMBER_ERROR,
                    //payload: {error: getApiErrorMessage(error.response.data.error)}
                    payload: error.response.data

                })
            })
    }

}

export const enrolFamilyMember = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "enrolFamilyMember"))
        dispatch({
            type: ENROL_FAMILY_MEMBER,
            payload: {}
        })
        await doPost(_URL_FAMILY_ENROLMENT, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "enrolFamilyMember"))
                dispatch({
                    type: ENROL_FAMILY_MEMBER,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "enrolFamilyMember"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const clearFamilyMember = () => {
    return async (dispatch) => {
        dispatch({
            type: ADD_FAMILY_MEMBER,
            payload: {}
        })
    }
}