/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
    FETCH_ACCOUNT_SUMMARY,
    MASTER_SET_GENDER,
    MASTER_SET_ISD_CODES,
    SET_MASTER_DATA,
    SET_CONFIG_DATA,
    FETCH_USERINFO,
    LOGOUT,
    FETCH_CUSTOMER_PROFILE,
    RESET_STATE,
    ACTION_FETCH_PROFILEDATA_SUCCESS,
    ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS,
    ACTION_FETCH_CURRENT_USER_SUCCESS,
    ACTION_PRIVILEGES,
    FETCH_QRCODE_IMAGE,
    FETCH_ACCOUNT_NOMINEE_SUCCESS,
    PAGE_REFERENCE,
    SET_FAMILY_MEMBERS,
    CURRENT_MENU,
    SET_TIER_DETAILS,
    SET_VALIDATE_MEMBER_DETAIL,
    ACTION_ADD_DELETE_NOMINEE,
    CSS_LOADER,
    SET_SELECTED_TAB,
    FETCH_TOKEN,
    MANAGE_PROFILE_ATTRIBUTE,
    ACTION_MANAGE_PROFILE_ATTRIBUTE,
    ALL_PROGRAMS_RETRIEVE,
    MEMBER_BENEFIT_SERVICE,
    MEMBER_DIGITALCARD_SERVICE,
    ACTION_GET_AVAILABLE_BALANCE,
    FETCH_NEW_TOKEN,
    CLEAR_FETCH_TOKEN,
    OTHER_CARDS_SERVICE,
    ADHOC_ACTIVITY,
    FETCH_PRODUCT_DETAILS
} from './commonAction'
import { CONFIG_SECTION_DEFAULT } from '../../utils/Constants';
import { prepareMasterEntityLookup, prepareMasterEntityLookupFilters } from '../../utils/common.utils';

export function userInfoReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === FETCH_USERINFO) {
        const userInfo = payload.object
        return {
            userInfo
        }
    } else {
        return state;
    }
}

export function retrieveAllProgramsDataReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === ALL_PROGRAMS_RETRIEVE) {
        const allPrograms = payload
        return {
            allPrograms
        }
    } else {
        return state;
    }
}

export function retrieveMemberBenefitsDataReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === MEMBER_BENEFIT_SERVICE) {
        let benefitIndex = -1;
        payload.benefitDetails.filter((record, index) => {
            if (record.additionalInfo && record.additionalInfo.isParent && record.additionalInfo.isParent == 'N') {
                benefitIndex = index;
            }
        });
        if (benefitIndex >= 0) {
            payload.benefitDetails.splice(benefitIndex, 1);
        }

        // payload.benefitDetails.push({
        //     "i18Code": "partner_fin_AEAC",
        //     "pointType": "",
        //     "benefitCount": 0,
        //     "benefitType": "partner_financial",
        //     "order":1
        // })
        // payload.benefitDetails.push({
        //     "i18Code": "benefits_elitePartner",
        //     "pointType": "",
        //     "benefitCount": 0,
        //     "benefitType": "dynamic_ep",
        //     "order":1,
        //     "additionalInfo": {
        //         "epStatus" :"EPN"
        //     }
        // })
        // payload.benefitDetails.push({
        //     "i18Code": "benefits_elitePartner",
        //     "pointType": "",
        //     "benefitCount": 0,
        //     "benefitType": "dynamic_ep",
        //     "order":1,
        //     "additionalInfo": {
        //         "epStatus" :"EPA",
        //         "epDisplayDate": "23-Feb-2023"
        //     }
        // })
        // payload.benefitDetails.push({
        //     "i18Code": "benefits_elitePartner",
        //     "pointType": "",
        //     "benefitCount": 0,
        //     "benefitType": "dynamic_ep",
        //     "order":1,
        //     "additionalInfo": {
        //         "epStatus" :"EPU",
        //         "epDisplayDate": "23-Feb-2023"
        //     }
        // })
        payload.benefitDetails = payload.benefitDetails.sort(
            (p1, p2) => (parseFloat(p1.order) < parseFloat(p2.order)) ? -1 : (parseFloat(p1.order) > parseFloat(p2.order)) ? 1 : 0
        );

        const benefits = payload
        return {
            benefits
        }
    } else {
        return state;
    }
}

export function retrieveDigitalCardDataReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === MEMBER_DIGITALCARD_SERVICE) {
        const digitalCards = payload
        return {
            digitalCards
        }
    } else {
        return state;
    }
}

export function accountSummaryReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === FETCH_ACCOUNT_SUMMARY) {
        const accountSummary = payload.object
        return {
            accountSummary
        }
    } else {
        return state;
    }
}

const initialMasterData = {
    genders: [],
    ISDCodes: [],
    securityQuestions: []
}

export function masterData(state, action) {
    const { type, payload } = action;
    if (!state) state = initialMasterData
    switch (type) {
        case MASTER_SET_GENDER:
            return {
                ...state,
                genders: payload
            }
        case MASTER_SET_ISD_CODES:
            return {
                ...state,
                ISDCodes: payload
            }
        case SET_MASTER_DATA:
            return {
                ...state,
                [payload.type]: payload.data
            }
        default:
            return state;
    }
}

export function configurationReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === SET_CONFIG_DATA) {
        return {
            ...state,
            [payload.type]: payload.config
        }
    } else {
        return state;
    }
}

export function masterEntityDataReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = { masterEntityLookup : {}, masterEntityLookupFilters: []}
    if (type === SET_CONFIG_DATA &&
        payload.type === CONFIG_SECTION_DEFAULT) {
        try {
            return {
                masterEntityLookup: prepareMasterEntityLookup(payload.config.data.masterEntityData),
                masterEntityLookupFilters: prepareMasterEntityLookupFilters(payload.config.data.masterEntityData)
            }
        } catch (error) {
            console.log("error: masterEntityDataReducer : ", error)
        }
    }
    return state;
}

export function logOutReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = false
    if (type == LOGOUT || type == RESET_STATE) {
        document.cookie = "token=null"
        document.cookie = "refreshtoken=null"
        return {
            logout: payload
        }
    } else {
        return state;
    }
}

/**
 * Returns the payload, if the action type is of FETCH_CUSTOMER_PROFILE
 * @param {*} state
 * @param {*} action
 */
export const fetchCustomerProfileReducer = (state, action) => {
    const { type, payload } = action;
    if (!state) state = {}
    if (type == FETCH_CUSTOMER_PROFILE) {
        return payload
    } else {
        return state;
    }
}

/**
 * @description Reducer for setting member profile data
 * @param {*} state 
 * @param {*} action 
 * @author Ajmal Aliyar
 */
export const profileDataReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action;

    if (type == ACTION_FETCH_PROFILEDATA_SUCCESS) {
        return {
            profileData: payload
        }
    }
    else {
        return state;
    }
}

/**
 * @description Reducer for setting member profile data
 * @param {*} state
 * @param {*} action
 * @author Geo George
 */
 export const defaultUserProfileDataReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action;

    if (type == ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS) {
        return {
            profileData: payload
        }
    }
    else {
        return state;
    }
}

/**
 * @description Reducer for setting current logged in user data
 * @param {*} state 
 * @param {*} action 
 * @author Rohith
 */
export const currentLoginUserDataReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action;

    if (type == ACTION_FETCH_CURRENT_USER_SUCCESS) {
        return {
            currentUserData: payload
        }
    }
    if(type == FETCH_NEW_TOKEN){
        return ({
            ...state,
            newToken : payload
        })
    }
    if(type == CLEAR_FETCH_TOKEN){
        return ({
            ...state,
            newToken : null
        })
    }
    else {
        return state;
    }
}

/**
 * @description Reducer for setting permissions/privileges
 * @param {*} state 
 * @param {*} action 
 * @author Rohith
 */
export const privilegesReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action;

    if (type == ACTION_PRIVILEGES) {
        return {
            privileges: payload
        }
    }
    else {
        return state;
    }
}

export function qrCodeImageReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type == FETCH_QRCODE_IMAGE) {
        const qrImage = payload.object
        if(qrImage && qrImage.qrCode) {
            qrImage["qrCode"] = 'data:image/png;base64, ' + qrImage.qrCode
        }
        return {
            qrImage
        }
    } else {
        return state;
    }
}

export function retriveAccountNomineeReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type == FETCH_ACCOUNT_NOMINEE_SUCCESS) {
        const corporateNominees = payload.object
        return {
            corporateNominees
        }
    } else {
        return state;
    }
}

export function pageReferenceReducer(state, action){
    const { type, payload } = action;
    if (!state) state = {}
    if (type == PAGE_REFERENCE) {
        return {
            pageRef: payload
        }
    } else {
        return state;
    }
}

export function familyListReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {
        members: null
    }
    if(type === SET_FAMILY_MEMBERS) {
        return {
            members: payload
        }
    } else {
        return state
    }
}
export function productDetailsReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {
        details: {}
    }
    if(type === FETCH_PRODUCT_DETAILS) {
        return {
            details: payload
        }
    } else {
        return state
    }
}

export function menuReferenceReducer(state, action ) {
    const { type, payload } = action;
    if(!state) state = {payload: ""}
    if(type === CURRENT_MENU){
        return {
            payload
        }
    } else {
        return state;
    }
}

/**
 * @description Reducer for setting member tier details
 * @param {*} state 
 * @param {*} action 
 * @author Gowthaman A
 */
export function setTierDetailsReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = { payload: "" }
    if (type === SET_TIER_DETAILS) {
        return {
            payload
        }
    } else {
        return state;
    }
}

export function validateMemberDetailReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = { payload: "" }
    if (type === SET_VALIDATE_MEMBER_DETAIL) {
        const validateMemberData = payload
        return {
            validateMemberData
        }
    } else {
        return state;
    }
}

/**
 * Reducer for Add travel companion
 * @param {*} state
 * @param {*} action action with type and payload
 *
 * @author Geo George
 */
 export const addDeleteNomineeReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_ADD_DELETE_NOMINEE) {
        return { response: payload }
    } else {
        return state
    }
}

export const cssLoadReducer = (state, action) => {
    if (!state) state = false
    const { type, payload } = action
    if (type == CSS_LOADER) {
        return payload
    } else {
        return state
    }
}

export const selectedTabReducer = (state="", action) => {
    const { type, payload } = action
    if(type == SET_SELECTED_TAB){
        return payload
    }
    return state
}

export const tokenReducer = (state="", action) => {
    const { type, payload } = action
    if(type == FETCH_TOKEN){
        return payload
    }
    return state
}


export const manageProfileAttribute = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_MANAGE_PROFILE_ATTRIBUTE) {
        return { response: payload }
    } else {
        return state
    }
}

export const availableBalanceReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_GET_AVAILABLE_BALANCE) {
        return payload
    } else {
        return state
    }
}

export const adhocActivityReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ADHOC_ACTIVITY) {
        return payload
    } else {
        return state
    }
}

export function retrieveOtherCardsDataReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === OTHER_CARDS_SERVICE) {
        let payloadSorted = payload.partnerCardDetails.sort(
            (p1, p2) => (parseFloat(p1.order) < parseFloat(p2.order)) ? -1 : (parseFloat(p1.order) > parseFloat(p2.order)) ? 1 : 0
        );
        const otherCards = payloadSorted
        return {
            otherCards
        }
    } else {
        return state;
    }
}

