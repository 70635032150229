/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SET_PARTNERSBENEFITS_CONTENT } from "./actions";

/**
* Reducer for the page Promotions.
* @param {Object} state Initialised as empty object
* @param {Object} action Object that contains request payload and action
* @author Gowthaman A
*/
export function partnersBenefitsReducer(state, action) {
   const { type, payload } = action;
   if(!state) state = {}
   
   if(type == SET_PARTNERSBENEFITS_CONTENT){
       return {
           ...state,
           partnersbenefitscontent: payload.object
       }
   }
   return state
}