
import {
    startButtonSpinner,
    stopButtonSpinner
} from '../../../../common/components/fieldbank/loader/action';
import {
    _URL_PRODUCT_COMP_REGISTER, _URL_VALIDATE_BUSINESS_NUMBER
} from '../../../../common/config/config';
import {
    getApiErrorMessage
} from '../../../../common/utils';
import { doPost } from '../../../../common/utils/api';

// Action Type
export const ENROLMENT_DATA = "ENROLMENT_DATA";
export const ENROLMENT_ERROR = "ENROLMENT_ERROR";
export const PREAFFLIATED_DATA = "PREAFFLIATED_DATA";
export const PREAFFLIATED_ERROR = "PREAFFLIATED_ERROR";
export const PREAFFILIATED_ENROLMENT_DATA = "PREAFFILIATED_ENROLMENT_DATA";
export const REGISTER_COMP = "REGISTER_COMP"
export const VALIDATE_BUSINESS_NUMBER = "VALIDATE_BUSINESS_NUMBER"

export const registerCompany = (payload, id) => {
    return dispatch => {
        dispatch(startButtonSpinner(id, "updateCompanyProfile"))
        return doPost(_URL_PRODUCT_COMP_REGISTER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "updateCompanyProfile"))
                dispatch({
                    type: REGISTER_COMP,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "updateCompanyProfile"))
                dispatch({
                    type: REGISTER_COMP,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const validateBusinessNumber = (payload, id) => {
    return dispatch => {
        dispatch({
            type: VALIDATE_BUSINESS_NUMBER,
            payload: {}
        })
        dispatch(startButtonSpinner(id, "validateBusinessNumber"))
        return doPost(_URL_VALIDATE_BUSINESS_NUMBER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "validateBusinessNumber"))
                dispatch({
                    type: VALIDATE_BUSINESS_NUMBER,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "validateBusinessNumber"))
                dispatch({
                    type: VALIDATE_BUSINESS_NUMBER,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}


