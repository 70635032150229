/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SET_LANGUAGE, SET_HEADERDETAILS, GET_NOTIFICATIONS, GET_NOTIFICATIONS_ERROR } from './actions';
import { _DEFAULT_LANGUAGE, _WHITELIST_LANGUAGES } from '../../common/config/config';
import { setItemToBrowserStorage, BROWSER_STORAGE_KEY_i18_LANG, getItemFromBrowserStorage } from '../../common/utils/storage.utils';

const initialState = _WHITELIST_LANGUAGES.find(e=>e.code == _DEFAULT_LANGUAGE)

export const initialConfig = {
    headerdetails: {}
}

export function language( state, action ) {
    const { type, payload } = action;
    if(!state) {
        const lang = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
        if (!lang || 
            lang === "null" || 
            lang === "undefined" || 
            !lang.bootstrap_css_href || 
            !lang.direction || 
            !lang.css_href) {
            state = initialState
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG, JSON.stringify(state))
        } else {
            state = JSON.parse(lang)
        }
    }
    if(type === SET_LANGUAGE) {
        const { lang } = payload
        setItemToBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG, JSON.stringify(lang))
        return {...lang, languageMap: _WHITELIST_LANGUAGES}
    } else {
        return {...state, languageMap: _WHITELIST_LANGUAGES};
    }
}

export function getHeaderDetailsReducer( state, action ) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type == SET_HEADERDETAILS){
        return{
            headerdetails : payload
        }
    } else {
        return state;
    }
}


/**
 * Reducer for getting notifications
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 * @author Amrutha J Raj
 */
export function getNotificationsReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.object
            }
        case GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                notifications: {}
            }
        default:
            return state;
    }

}