/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React, { Component } from 'react';
import CommonErrorPage from './CommonErrorPage';
import loadable from '@loadable/component';
import { ROUTER_CONFIG } from '../../components/common/config/routing';
const HeaderComponent = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../../components/ui/header/index'));

export default class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
        hideHeroBanner: false
      };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      console.error("Error: ",error," INFO: ",info)
      //logErrorToMyService(error, info);
    }

    setErrorPageInfo = (props) => {
      this.setState({ hasError: true, hideHeroBanner: true });
    }
  
    render() {
      const { hideHeroBanner } = this.state
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <>
            <HeaderComponent includeHeader={false} apiTriggerConfig={{}} imageBannerConfig={{}}  hideHeroBanner={hideHeroBanner}/>
            <CommonErrorPage setErrorPageInfo={this.setErrorPageInfo}/>
            <ROUTER_CONFIG.footer.component />
        </>;
      }
      return this.props.children;
    }
  }