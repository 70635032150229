/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { doPost } from '../../common/utils/api';
import { 
    _URL_VIEW_OFFERPARTNERDETAIL
} from '../../common/config/config';
import { getApiErrorMessage} from '../../common/utils'
import { startApiLoading, stopApiLoading } from '../../common/components/fieldbank/loader/action';

export const ERROR = 'ERROR';
export const SET_PARTNERSBENEFITS_CONTENT = "SET_PARTNERSBENEFITS_CONTENT";

export const getPartnersBenefitsContent = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("promotions"))
        await doPost(_URL_VIEW_OFFERPARTNERDETAIL, payload)
            .then((response) => {
                dispatch(stopApiLoading("promotions"))
                dispatch({
                    type: SET_PARTNERSBENEFITS_CONTENT,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("promotions"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}