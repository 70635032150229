/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const CONFIG_LAYOUT_ELEMENT_TYPE_POPUP = "popup"
export const CONFIG_LAYOUT_ELEMENT_TYPE_TABLE = "table"

export const PLACEHOLDER_CORPORATE_MEMBERSHIP_NUMBER = "${corporateMembershipNumber}"

export const TAB_TRAVELLERS = "traveller"
export const TAB_ACCOUNT_USERS = "nominee"

export const COLUMN_TYPE_TEXT = "text"
export const COLUMN_TYPE_NAME_WITH_TITLE = "customNameWithTitle"
export const COLUMN_TYPE_ACTION = "action"

export const COLUMN_ACTION_TYPE_EDIT = "edit"
export const COLUMN_ACTION_TYPE_DELETE = "delete"

export const POPUP_FUNCTION_ADD = "add"
export const POPUP_FUNCTION_EDIT = "edit"
export const POPUP_FUNCTION_DELETE = "delete"

export const PRIVILEGE_ATTRIBUTES_KEY_FULL_ACCESS = "fullAccessPrivilege"
export const PRIVILEGE_ATTRIBUTES_KEY_LIMITED_ACCESS = "limitedAccessPrivilege"

export const REQUEST_TEMPLATE = {
    [TAB_ACCOUNT_USERS]: {
        [POPUP_FUNCTION_ADD]: {
            "object": {
                "companyCode": "",
                "programCode": "",
                "membershipNumber": "",
                "accountStatus": "",
                "membershipType": "",
                "preferredLanguage": "",
                "nomineeDetails": [
                    {
                        "nomineeReferenceNumber": "",
                        "memberType": "",
                        "customerNumber": "",
                        "accountGroupType": "",
                        "membershipNumber": "",
                        "relationship": "",
                        "title": "",
                        "givenName": "",
                        "secondName": "",
                        "familyName": "",
                        "displayName": "",
                        "gender": "",
                        "dateOfBirth": "",
                        "passportNumber": "",
                        "countryOfResidence": "",
                        "emailAddress": "",
                        "phoneISDCode": "",
                        "phoneAreaCode": "",
                        "phoneNumber": "",
                        "enrollmentSource": "",
                        "preferredLanguage": "",
                        "idNumber": "",
                        "nationality": "",
                        "mobileISDCode": "",
                        "mobileAreaCode": "",
                        "mobileNumber": "",
                        "nomineeStatus": "",
                        "customerDynamicAttribute": []
                    }
                ],
                "nomineeAccessPrivilege": undefined
            }
        },
        [POPUP_FUNCTION_EDIT]: {
            "object": {
                "companyCode": "",
                "programCode": "",
                "corpMembershipNumber": "",
                "accountGroupType": "",
                "customerProfile": undefined,
                "nomineeAccessPrivilege": undefined
            }
        }
    },
    [TAB_TRAVELLERS]: {
        [POPUP_FUNCTION_ADD]: {
            "object": {
                "companyCode": "",
                "programCode": "",
                "membershipNumber": "",
                "accountStatus": "",
                "membershipType": "",
                "preferredLanguage": "",
                "nomineeDetails": [
                    {
                        "nomineeReferenceNumber": "",
                        "memberType": "",
                        "customerNumber": "",
                        "accountGroupType": "",
                        "membershipNumber": "",
                        "relationship": "",
                        "title": "",
                        "givenName": "",
                        "secondName": "",
                        "familyName": "",
                        "displayName": "",
                        "gender": "",
                        "dateOfBirth": "",
                        "passportNumber": "",
                        "countryOfResidence": "",
                        "emailAddress": "",
                        "phoneISDCode": "",
                        "phoneAreaCode": "",
                        "phoneNumber": "",
                        "enrollmentSource": "",
                        "preferredLanguage": "",
                        "idNumber": "",
                        "nationality": "",
                        "mobileISDCode": "",
                        "mobileAreaCode": "",
                        "mobileNumber": "",
                        "nomineeStatus": "",
                        "customerDynamicAttribute": []
                    }
                ]
            }
        },
        [POPUP_FUNCTION_EDIT]: {
            "object": {
                "companyCode": "",
                "programCode": "",
                "corpMembershipNumber": "",
                "accountGroupType": "",
                "customerProfile": undefined
            }
        }
    },
    RETRIEVE_CUSTOMER: {
        "object": {
            "companyCode": "",
            "customerNumber": "",
            "membershipNumber": "",
            "prefferedEmail": ""
        }
    },
    RETRIEVE_NOMINEES: {
        "object": {
            "companyCode": "",
            "programCode": "",
            "membershipNumber": "",
            "nomineeReferenceNumber": "",
            "accountGroupType": "",
            "nomineeStatus": "",
            "idNumber": ""
        }
    },
    DELETE_NOMINEE: {
        "object": {
            "companyCode": "",
            "programCode": "",
            "membershipNumber": "",
            "accountStatus": "",
            "membershipType": "",
            "preferredLanguage": "",
            "nomineeDetails": []
        }
    }
}