/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SET_FEED_BACK } from './actions';
const initialState = {};

export default function setFeedBack(state, action) {
    if (!state) state = initialState;
    const { type, payload } = action;
    if (type == SET_FEED_BACK) {
        return {
            feedBackDetails: payload
        }
    }
    else {
        return state;
    }
}