import React, {useEffect, useState} from 'react';
import {
    getItemFromBrowserStorage,
    setItemToBrowserStorage,
    BROWSER_STORAGE_KEY_MFA_TOKEN,
    BROWSER_STORAGE_KEY_MFA_REDIRECT,
    BROWSER_STORAGE_TYPE_SESSION
} from '../../components/common/utils/storage.utils';

import {
    NAVIGATE_MEMBER_DASHBOARD
} from '../../components/common/utils/urlConstants';

import {
    _CONTEXT_PATH,
    _URL_MFA_VALIDATE_TOKEN
} from '../../components/common/config/config';

import Loader from '../../components/common/components/fieldbank/loader/Loader';
import CustomMessage from '../../components/common/components/custommessage';

import { useLocation  } from 'react-router-dom';

import { doGet } from "../../components/common/utils/api";

const MfaCallbackPage = (props) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idToken = searchParams.get('id_token');
    const [mfaError, setMfaError] = useState(false)


    useEffect(() => {
        validateToken(idToken)
        .then(data => {
            if (data) {
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_MFA_TOKEN, idToken);
                let mfaRedirect = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MFA_REDIRECT, BROWSER_STORAGE_TYPE_SESSION)
                if (mfaRedirect) {
                    window.location.href = `${_CONTEXT_PATH}${mfaRedirect}`
                } else {
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_MEMBER_DASHBOARD}`
                }
            } else {
                setMfaError(true)
            }
        }).catch(err => {
            setMfaError(true)
        })
    }, [idToken])


    const validateToken = async (token) => {
        const response = await doGet(_URL_MFA_VALIDATE_TOKEN, {mfaIdToken: token});
        if (response.status == 200 && response.data.statuscode == '200') {
            if (response.data?.object?.tokenValid) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // if (idToken) {
    //     setItemToBrowserStorage(BROWSER_STORAGE_KEY_MFA_TOKEN, idToken);
    //     let mfaRedirect = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MFA_REDIRECT, BROWSER_STORAGE_TYPE_SESSION)
    //     if (mfaRedirect) {
    //         window.location.href = `${_CONTEXT_PATH}${mfaRedirect}`
    //     } else {
    //         window.location.href = `${_CONTEXT_PATH}${NAVIGATE_MEMBER_DASHBOARD}`
    //     }
    // } else {
    //     window.location.href = `${_CONTEXT_PATH}${NAVIGATE_MEMBER_DASHBOARD}`
    // }

    return (
      <>
        {mfaError && (
            <div class="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form">
                            <CustomMessage type={"danger"}
                                message={["We could not verify your multifactor authentication. Retry action to proceed"]}
                                data-test="customMessageComponent"
                                canTranslate={true} />
                        </div>
                    </div>
                </div>
            </div>
        )}
        {!mfaError && <Loader />}
        <br /><br /><br /><br /><br />
      </>
    );
}

export default MfaCallbackPage;