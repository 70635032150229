/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/
import {
    NAVIGATE_CORPORATE_PROFILE,
    NAVIGATE_CORPORATE_MANAGE_PEOPLE,
    NAVIGATE_CORPORATE_TRANSFER,
    NAVIGATE_FAMILY_MEMBER_ADD,
    NAVIGATE_MEMBER_OVERVIEW,
    NAVIGATE_MEMBER_DASHBOARD,
    NAVIGATE_CORPORATE_ADD_ACCOUNTUSERS
} from './urlConstants'
//breadcrumb title keys
export const OFFERS = "benefits"
export const PARTNERS = "partners"
export const HOME = "home"
export const PROFILE = "my_profile"
export const DASHBOARD = "airnz_overview"
export const BOOK_FLIGHT = "book_flight"
export const MY_FAMILY = "my_family"
export const COUPONS = "coupons"
export const REFERRAL = "referral"
export const ADD_FAMILY = "add_family"
export const BUY = "buy"
export const TRANSFER = "transfer"
export const MY_ACTIVITY = "my_activity"
export const CLAIM_POINTS = "claim"
export const COMPANION_LIST = "companion_list"
export const CLAIM_LIST = "claim_list"
export const SUBMIT_CLAIM = "submit_claim"
export const CALCULATOR = "calculator"
export const CHANGEPIN = "changepin"
export const FAMILY_POOLING = "family_pooling"
export const MY_FLIGHT = "myflight"
export const FEEDBACK = "feedback"
export const MILEAGE_OVERVIEW = "mileage_overview"
export const LOGIN = "login"
export const MIGRATE_POINTS = "migratepoints"
export const CLUB_MEMBERSHIP_HOME = "club_membership_home"
export const CLUB_MEMBERSHIP_SUBSCRIBE = "club_membership_subscribe"
export const CLUB_MEMBERSHIP_ADD_BOOSTER = "club_membership_add_booster_pack"
export const CONFIG_SECTION_PRIVACY = "privacy"
export const TRANSACTIONS = "transactions"
export const REWARD_BOOKING = "reward_booking"
export const MEMBER = "member"
export const ENROL = "enrol"
export const FORGOT_PASSWORD = "forgot_password"
export const RESET_PASSWORD = "reset_password"
export const USER_ACTIVATION = "user_activation"
export const PROFILE_COMPLETION = "profile_completion"
export const EXTEND_MILES_EXPIRY ="extend_miles"
export const CORPORATE = "corporate.title"
export const CORPORATE_OVERVIEW = "corporate.overview"
export const CORPORATE_ACTIVITY = "corporate.my_activity"
export const CORPORATE_PROFILE = "corporate.my_profile"
export const CORPORATE_MANAGE_PEOPLE = "corporate.manage_people"
export const CORPORATE_ADD = "corporate.add_nominee"
export const CORPORATE_REGISTER = "corporate.register"

export const AIRNZ_OVERVIEW = "airnz_overview"
export const AIRNZ_BALANCE = "airnz_balance"
export const AIRNZ_BENEFIT = "airnz_benefit"
export const AIRNZ_DIGITALCARD = "airnz_digitalcard"
export const AP4B = "ap4b"
export const AP = "ap"
export const AP4B_USERS = "ap4b_users"
export const AP4B_PROFILE = "ap4b_profile"


export const E403 = "403"

export const AIRPORT = "airport"
export const AIRPORT_MC = "airport_mc"
export const AIRLINES = "airlines"
export const TITLE_GENDER = "title"
export const SECURITY_QUESTIONS = "securityQuestions"
export const MILEAGE = "mileage"
export const COUNTRY_ISD = "country"
export const RESET_POINT = "resetPoint"
export const RELATIONSHIP="designation"
export const ACCOUNT_USER_PRIVILEGE="permissionLevel"
export const ACCOUNT_USER_STATUS="nomineeStatus"
export const AIRLINE_BOOKING_CLASS = "airline_booking_class"
export const LANGUAGE = "language"
export const STATE = "state"
export const CITY = "city"
export const PROGRAM_TYPE_INDIVIDUAL = "individual"
export const PROGRAM_TYPE_CORPORATE = "corporate"
export const MOBILE_APP = "MOBILE_APP"
export const MOBILE_WEBVIEW = "mobileWebView"
export const POINT_TRANSACTION = "points_transaction"
export const HTTP = "http"
export const CHARITY = "charity"
export const TITLE_GENDER_GF = "title_GF"
export const SERVICE_REQUEST_CATEGORY = "servicerequestcategory"
export const SERVICE_REQUEST_TYPE = "servicerequesttype_"
export const FILE_TYPE = "filetype"
export const CABIN_CLASS = "cabinClass"

//TODO: Can remove this once the master entity lookup resucer start working
// export const MASTER_LOOKUP_TABLE = {
//     [AIRPORT]: "ARPMST",
//     [AIRLINES]: "ARLMST",
//     [TITLE_GENDER]: "TLEMST",
//     [MILEAGE]: "MILMST",
//     [COUNTRY_ISD]: "CNTMST",
//     [AIRLINE_BOOKING_CLASS]: "ARLBKGCLSMST",
//     [LANGUAGE]: "LNGMST",
//     [STATE]: "STTMST",
//     [CITY]: "CTYMST"
// }

export const DATA_SOURCE_DEFAULT = "default"
export const DATA_SOURCE_MASTER = "master"

export const CONFIG_SECTION_DEFAULT = "default"
export const CONFIG_SECTION_DASHBOARD = "dashboard"
export const CONFIG_SECTION_ACCOUNT_SUMMARY = "accountsummaryimpl"
export const CONFIG_SECTION_BUYPOINT = "buypoint"
export const CONFIG_SECTION_TRANSFERPOINT = "transferpoint"
export const CONFIG_SECTION_MYFAMILY = "family"
export const CONFIG_SECTION_ACTIVITYDETAILS = "activitydetails"
export const CONFIG_SECTION_CLAIMMILES = "claimmiles"
export const CONFIG_SECTION_TRAVEL_COMPANION = "travelcompanion"
export const CONFIG_SECTION_CUSTOMQUERY = "customquery"
export const CONFIG_SECTION_ENROL = "enrol"
export const CONFIG_SECTION_MASTER = "master"
export const CONFIG_SECTION_MILEAGECALCULATOR = "mileagecalculator"
export const CONFIG_SECTION_PROFILE = "profile"
export const CONFIG_SECTION_PROFILE_COMPLETION = "profilecompletion"
export const CONFIG_SECTION_SUMMARY = "summary"
export const CONFIG_SECTION_FORGOTPSW = "forgotpsw"
export const CONFIG_SECTION_LOGIN = "login"
export const CONFIG_SECTION_FEEDBACK = "servicerequest"
export const CONFIG_SECTION_SECURITY = "security"
export const CONFIG_SECTION_REFERRAL = "referral"
export const CONFIG_SECTION_PREFERENCE = "preference"
export const CONFIG_SECTION_MYFLIGHTS = "myflights"
export const CONFIG_SECTION_CUSTOMER_PROFILE = "customerprofile"
export const CONFIG_SECTION_SUBMITMILES = "submitmiles"
export const CONFGI_SECTION_TRAVELLER = "traveller"
export const CONFIG_SECTION_TRAVELLER = "traveller"
export const CONFIG_SECTION_NOMINEE = "nominee"
export const CONFIG_SECTION_MANAGEPEOPLE = "managepeople"
export const CONFIG_SECTION_MANAGE_ACCOUNT_USERS = "manageaccountusers"
export const CONFIG_SECTION_OVERVIEW = "overview"
export const CONFIG_SECTION_MIGRATEPOINTS = "migratepoints"
export const CONFIG_SECTION_MEMBERCHECK = "memberCheck"
export const CONFIC_SECTION_ALREADY_HAVE_FFNO = "alreadyHaveFFNo"
export const ENROLMENT_SOURCE_PARTNER = "P"
export const CONFIG_SECTION_ACTIVATION = "activation"
export const CONFIG_SECTION_NOTIFICATION = "notification"
export const CONFIG_SECTION_EXTEND_EXPIRY = "extendexpiry"
export const CONFIG_SECTION_SIMULATE_RULE = "simulaterule"
export const CONFIG_SECTION_RETRIEVE_QUOTE = "retrievequote"
export const CONFIG_SECTION_MODIFY_USER_DETAILS = "modifyuserdetails"
export const CONFIG_SECTION_RENEWPOINT = "renewpoint"
export const CONFIG_SECTION_NOMINEEACTIVATION = "nomineeactivation"
export const CONFIG_SECTION_COMPANY_PROFILE = "companyprofile"

export const YYYY_MM_DD_HH_MM_SS = "yyyy-MM-dd HH:mm:ss"
export const YYYY_MM_DD_HH_MM = "yyyy-MM-dd HH:mm"
export const YYYY_MM_DD = "yyyy-MM-dd"
export const YYYYMMDD = "YYYYMMDD"
export const DD_MM_YYYY = "DD-MM-YYYY"
export const DD_MMM_YY = "DD-MMM-YY"
export const DD_MMM_YYYY = "DD-MMM-YYYY"
export const DD_MMM_YYYY_HH_MM = "DD-MMM-YYYY HH:mm"
export const DD_MM_YYYY_HH_MM_SS = "DD-MMM-YYYY HH:mm:ss"
export const HH_MM = "HH.mm"
export const EXPORT_PDF = "exportpdf"
export const EXPORT_XLSX = "exportxlsx"
export const EXPORT_CSV = "exportcsv"
export const CALENDAR_SELECTION_MODE = "range"

/**
 * MASTER_REARRANGE is used to maintain the alterations to be made on the masterdata list that is fetched.
 * defaultValue: The value that is to be rendered as default (in case of dropdowns like country, nationality)
 */
export const MASTER_REARRANGE = {
    "country": {
        "defaultValue": "XX" // 'XX' refers to 'Select One' in the masterData list.
    }
}
export const ACTIVE="A"

export const REDIRECT_TO = "redirectTo"
export const CLIENT_ID = "clientId"
export const SECRET = "secret"
export const RTL = "rtl"
export const LTL = "ltl"
export const CLIENT_TYPE = "clientType"
export const RESPONSIVE_WIDTH_BENCHMARK = 992
export const RESPONSIVE_WIDTH_BENCHMARK_MOBILE = 768

export const TIER_BASE_CODE = "AJ"
export const CUSTOMER_GUID_DYNAMIC_ATTR_CODE = "01"

export const MFA = {
    AUTHSIGNAL : {
        tenantId: "2130a76a-ed75-428e-a5f0-2f5f80b6da1b",
        baseUrl: "https://au-challenge.authsignal.com/v1"
    },
    ENABLED_ROUTES: [
        NAVIGATE_CORPORATE_PROFILE,
        NAVIGATE_CORPORATE_MANAGE_PEOPLE,
        NAVIGATE_CORPORATE_TRANSFER,
        NAVIGATE_FAMILY_MEMBER_ADD,
        NAVIGATE_CORPORATE_ADD_ACCOUNTUSERS
    ]
}


export const NO_OF_EMPLOYEES = "noOfEmployees"
export const TYPE_OF_INDUSTRY = "typeofindustry"

