import { SET_REACTIVATION_LINK } from "./action";

const initialState = {};

export  function reactivationReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = initialState
    if(type === SET_REACTIVATION_LINK) {
            return {
                reactivation: payload
            }
        } else {
            return state;
    }
}