/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const TAB_EARN_MILES = "earnMiles"
export const TAB_USE_MILES = "useMiles"
export const TAB_UPGRADE_MILES = "upgradeUsingMiles"
export const CALCULATE_BTN = "calculate-buttion"