/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { doPost } from '../../common/utils/api'
import {
  _URL_REQUEST_CLAIM, 
}  from '../../common/config/config';
import {
  getApiErrorMessage
} from '../../common/utils'
import { stopButtonSpinner, startButtonSpinner } from '../../common/components/fieldbank/loader/action';

export const REQUEST_CLAIM = "REQUEST_CLAIM";
export const CLAIM_REQUEST_ERROR = "CLAIM_REQUEST_ERROR";
export const FETCH_CLAIM_SUBMIT_CONFIG = "FETCH_CLAIM_SUBMIT_CONFIG";

export const requestClaim = (payload, id) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "requestClaim"))
    await doPost(_URL_REQUEST_CLAIM, payload)
      .then((response) => {
        dispatch(stopButtonSpinner(id, "requestClaim"))
        dispatch({
          type: REQUEST_CLAIM,
          payload: response.data
        })
      })
      .catch((error) => {
        dispatch(stopButtonSpinner(id, "requestClaim"))
        dispatch({
          type: CLAIM_REQUEST_ERROR,
          payload: {error:getApiErrorMessage(error.response.data.error)}
        })
      })
  };
}
