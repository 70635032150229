/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { ACTION_UPDATE_PREFERENCE_SUCCESS } from './actions'

/**
 * @description Reducer for storing save prefernece response
 * @param {*} state 
 * @param {*} action 
 * @author Ajmal Aliyar
 */
export const preferenceUpdateReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action;

    if (type == ACTION_UPDATE_PREFERENCE_SUCCESS) {
        return {
            preference: payload
        }
    } else {
        return state;
    }
}