/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { ACTION_FETCH_FLIGHTS_SUCCESS } from './actions';

export default function myFlightsReducer(state, action) {
    if (!state) state = {}
    const { type, payload } = action;

    if (type === ACTION_FETCH_FLIGHTS_SUCCESS) {
        const { object, dataType } = payload
        if(object) {
            let list = []
            const keys = Object.keys(object)
            keys.forEach((key) => {
                list.push(...object[key])
            })
            return {
                    ...state,
                    [dataType]: list
            }
        }
    }
    return state;
}