/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React from 'react';
import { Router, Switch, Route, Redirect, Link, HashRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    CONFIG_SECTION_DEFAULT,
    CONFIG_SECTION_ACCOUNT_SUMMARY,
    MOBILE_APP,
    MOBILE_WEBVIEW,
    PROGRAM_TYPE_CORPORATE,
    CONFIG_SECTION_PROFILE,
    CONFIG_SECTION_CUSTOMER_PROFILE,
    MFA
} from '../components/common/utils/Constants'
import {
    isEmptyOrSpaces, redirectToPage
} from '../components/common/utils'
import {
    clearLogoutData,
    fetchConfiguration,
    setPageReference,
    setMenuReference,
    resetError,
    fetchProfileData,
    fetchToken
} from '../components/common/middleware/redux/commonAction'

import { handleQueryParamsInURL, handlePageRedirectionURL, getCurrentPageConfigFromRouterConfig } from '../components/common/utils/sso.utils';
import { loginDetails } from '../components/ui/login/actions';
import loadable from '@loadable/component';
import {
    NAVIGATE_CALLBACK,
    NAVIGATE_403,
    NAVIGATE_500,
    NAVIGATE_PROFILE_COMPLETION,
    NAVIGATE_COMMON_ERROR,
    NAVIGATE_LOGOUT,
    NAVIGATE_MEMBER_LOGIN,
    NAVIGATE_CORPORATE,
    NAVIGATE_CORPORATE_PROFILE,
    NAVIGATE_CORPORATE_MYACTIVITY,
    NAVIGATE_CORPORATE_TRANSFER,
    NAVIGATE_CORPORATE_MANAGE_PEOPLE,
    NAVIGATE_ACTIVATE,
    NAVIGATE_MFA_CALLBACK,
    NAVIGATE_MEMBER_DASHBOARD
} from '../components/common/utils/urlConstants';
import {
    getItemFromBrowserStorage,
    setItemToBrowserStorage,
    clearBrowserStorage,
    BROWSER_STORAGE_KEY_CURRENCY_CODE,
    BROWSER_STORAGE_KEY_COMPANY_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_REQUEST_TIMEOUT,
    BROWSER_STORAGE_TYPE_LOCAL,
    BROWSER_STORAGE_TYPE_SESSION,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE,
    BROWSER_STORAGE_KEY_APP_ENV,
    BROWSER_STORAGE_KEY_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_FIRST_TIME_USER,
    removeItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING,
    BROWSER_STORAGE_KEY_TOKEN,
    BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_CUSTOMER_NO,
    BROWSER_STORAGE_KEY_i18_LANG,
    BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_DATE_FORMAT,
    BROWSER_STORAGE_KEY_DATE_TIME_FORMAT,
    BROWSER_STORAGE_KEY_IS_HASH_ROUTING_ENABLED,
    BROWSER_STORAGE_KEY_ACCESS_KEY,
    BROWSER_STORAGE_KEY_CID,
    BROWSER_STORAGE_KEY_MFA_TOKEN,
    BROWSER_STORAGE_KEY_MFA_REDIRECT,
    BROWSER_STORAGE_KEY_IDP_TOKEN
} from '../components/common/utils/storage.utils';
import MfaCallBackPage from './callback/MfaCallbackPage';
import { memberTier } from '../components/common/utils/tier.utils';
import Loader from '../components/common/components/fieldbank/loader/Loader';
import { ROUTER_CONFIG } from '../components/common/config/routing';
const _403Page = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./errors/403'), { fallback: <Loader /> });
const _404Page = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./errors/404'), { fallback: <Loader /> });
const _500Page = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./errors/500'), { fallback: <Loader /> });
const PortalBreadcrumb = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/header/PortalBreadcrumb'));
const SidePanel = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/container/SidePanel'));
const _403 = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/errors/403'));
const _404 = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/errors/404'));
const CallbackPage = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./callback/CallbackPage'), { fallback: <Loader /> });
const BuildInfoPage = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/common/components/buildinfo'), { fallback: <Loader /> });
const HeaderComponent = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/header/index'));

import { getCurrentPageUri } from '../components/common/utils';
import { havePrivilege, CONTEXT_PAGE } from '../components/common/privileges';
import { TOKEN } from '../components/ui/enrolment/Constants';
import { doGet } from '../components/common/utils/api';
import {
  setUrl,
  _URL_ENV_CONFIG_URL,
  _WHITELIST_LANGUAGES,
  _DEFAULT_LANGUAGE,
  _URL_MFA_CALLBACK,
  _URL_MFA_REDIRECT
} from "../components/common/config/config";
// import DigitalCard from '../components/common/components/fieldbank/DigitalCard';
import WelcomeMessage from '../components/common/components/fieldbank/WelcomeMessage';
import { getProgramFromDefaultConfigByProgramIdentifier } from '../components/common/utils/configurationFiles.utils';
import { changeLanguage } from '../components/ui/header/actions'
import MfaContext from '../context/MfaContext';
import MultiFactorAuthentication from '../components/common/components/mfa/MultiFactorAuthentication';
import ErrorBoundary from './errors/ErrorBoundary';
import history from '../components/common/utils/history';
import CommonErrorPage from './errors/CommonErrorPage';
import checkTokenExpiry from '../components/common/utils/jwt';
let isTokenFetchRequred = true
class Main extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tierName: "",
            isApiLoaded: false,
            isDefaultConfigLoaded: false,
            includeHeader: true,
            apiTriggerConfig: {},
            imageBannerConfig: {},
            isTokenExist: false,
            currentPath: "",
            programData:{}
        }
        this.setValuesToLocalStorageFromDefaultConfig = this.setValuesToLocalStorageFromDefaultConfig.bind(this)
    }

    componentDidMount() {
        console.log(COMMIT_HASH);
        console.log(BUILD_TIME_MILLI);
        this.setRoutingType()
        handleQueryParamsInURL()
        if (isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)) && isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IDP_TOKEN))) {
            if (isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY)) ||
                isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CID))) {
                handlePageRedirectionURL()
            } else {
                removeItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING)
            }
        }
        this.getUrlConfiguration().then(res => {
            const { defaultConfig, fetchConfiguration } = this.props
            if (!defaultConfig) {
                fetchConfiguration(CONFIG_SECTION_DEFAULT)
            }
            if (!isEmptyOrSpaces(this.props.token)) {
                this.fetchProfileConfiguration()
            }
            this.setLanguage()
            const currentPageUri = getCurrentPageUri()
            ROUTER_CONFIG.routes.map(routeConfig => {
                if(currentPageUri == routeConfig.url){
                    const { browserStorage } = routeConfig
                    if (browserStorage) {
                        browserStorage.forEach(storageItem => {
                            //set items to the breowserStorage
                            const { setItems, type } = storageItem
                            if (setItems) {
                                setItems.forEach(item => {
                                    setItemToBrowserStorage(item.key, item.value, type)
                                })
                            }
                        })
    
                    }
                }
            })
        }).catch(error => {
            console.error("Error occured while loading initial configuration!")
            this.setLanguage()
            if (!this.props.defaultConfig) {
                fetchConfiguration(CONFIG_SECTION_DEFAULT)
            }
        })
    }

    setRoutingType = () => {
        const { config } = ROUTER_CONFIG
        // const isHashRoutingEnabled = config.isHashRoutingEnabled ? config.isHashRoutingEnabled: true
        setItemToBrowserStorage(BROWSER_STORAGE_KEY_IS_HASH_ROUTING_ENABLED, config.isHashRoutingEnabled)
    }

    setLanguage = () => {
        let lang = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
        if (!lang) {
            lang = _WHITELIST_LANGUAGES.find(e => e.code == _DEFAULT_LANGUAGE)
        } else {
            lang = JSON.parse(lang)
        }
        this.props.changeLanguage(lang)
    }

    fetchProfileConfiguration() {
        const programCodeInBrowser = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        if (!this.props.profileConfig ||
            (this.props.profileConfig &&
                this.props.profileConfig.programCode != programCodeInBrowser)) {
            // this.props.fetchConfiguration(CONFIG_SECTION_PROFILE);
        }
        if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) == PROGRAM_TYPE_CORPORATE
            && (!this.props.customerConfig ||
                (this.props.customerConfig &&
                    this.props.customerConfig.programCode != programCodeInBrowser))) {
            // this.props.fetchConfiguration(CONFIG_SECTION_CUSTOMER_PROFILE);
        }
    }
    componentWillReceiveProps() {
        if (location.href !== this.state.currentPath) {
            this.setState({
                currentPath: location.href,
                hideHeroBanner: false
            })
        }
    }
    componentDidUpdate(prevProp) {
        handleQueryParamsInURL()
        const newState = {};
        const { isTokenExist } = this.state
        const { defaultConfig, accountSummary, accountSummaryConfig, profileConfig, fetchToken, currentUserData, allProgramsData } = this.props
        
        const pageConfig = getCurrentPageConfigFromRouterConfig()
        if (defaultConfig && 
            pageConfig && 
            pageConfig.isProtected && 
            isEmptyOrSpaces(this.props.token) &&
            isTokenFetchRequred) {
            let currentPageUri = getCurrentPageUri()
            if (currentPageUri == NAVIGATE_MEMBER_LOGIN  || currentPageUri == "/" ) {
                currentPageUri = undefined
            }
            fetchToken(defaultConfig, currentPageUri)
            newState["isTokenExist"] = false
            isTokenFetchRequred = false
        }
        
        if(pageConfig && 
            !pageConfig.isProtected && 
            !isTokenExist) {
            newState["isTokenExist"] = true
        } else if(pageConfig && 
            pageConfig.isProtected && 
            currentUserData &&
            !isTokenExist &&
            !isEmptyOrSpaces(this.props.token)) {
            newState["isTokenExist"] = true
        }

        if (!isEmptyOrSpaces(this.props.token) &&
            !profileConfig) {
            this.fetchProfileConfiguration()
        }

        if (defaultConfig &&
            Object.keys(defaultConfig).length > 0 && !this.state.isDefaultConfigLoaded) {
            this.setValuesToLocalStorageFromDefaultConfig(defaultConfig)
            newState["isDefaultConfigLoaded"] = true
        }
        if (accountSummary &&
            this.state.tierName != accountSummary.tierName &&
            accountSummaryConfig &&
            accountSummaryConfig.tiers) {
            newState["tierName"] = accountSummary.tierName
            const memberTierObj = memberTier(accountSummary, accountSummaryConfig)
            if (memberTierObj && memberTierObj.tierDetails) {
                const className = memberTierObj.tierDetails.topThemeClass;
                document.body.classList.remove(document.body.classList.item(0));
                document.body.classList.add(className);
            }
        }

        const appEnv = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_APP_ENV)
        document.body.classList.remove(MOBILE_WEBVIEW);
        if (appEnv === MOBILE_APP) {
            document.body.classList.add(MOBILE_WEBVIEW);
        }

        if (prevProp.socialLogin != this.props.socialLogin) {
            newState["socialLogin"] = true
        }

        if (prevProp.forgotPsw != this.props.forgotPsw) {
            window.location.reload();
        }
        if (prevProp.register != this.props.register) {
            window.location.reload();
        }
        if (
            (prevProp.logout != this.props.logout) &&
            (this.props.logout === true)
        ) {
            clearBrowserStorage(BROWSER_STORAGE_TYPE_SESSION)
            clearBrowserStorage(BROWSER_STORAGE_TYPE_LOCAL)
            this.props.clearLogoutData()
            window.location.reload();
        }
        if (this.state.confSection &&
            this.state.config &&
            this.props.configCollection[this.state.confSection]) {
            newState["confSection"] = ""
            newState["config"] = false
        }
        if (Object.keys(newState).length) this.setState(newState)


        $('.header__portal-menu .nav-link').on('click', function(e){
            var navtext = $(this).text();
            // $(".nav-text").html(navtext);
            $(".navbar-collapse.collapse").removeClass("show");
            $(".navbar-toggler").addClass("collapsed");
        })
        let currentPageUri = getCurrentPageUri()
        if (JSON.stringify(prevProp.allProgramsData) != JSON.stringify(this.props.allProgramsData)) {
            if (currentPageUri == NAVIGATE_CORPORATE_MANAGE_PEOPLE || currentPageUri == NAVIGATE_CORPORATE_MYACTIVITY
                || currentPageUri == NAVIGATE_CORPORATE_TRANSFER || currentPageUri == NAVIGATE_CORPORATE_PROFILE) {
                if (allProgramsData?.ap4bRole == "K" || allProgramsData?.ap4bRole == "C") {
                    redirectToPage(`${currentPageUri}`)
                } else {
                    redirectToPage(`${NAVIGATE_CORPORATE}`)
                }
            }
        }
    }

    /**
     * Once the defaultConfig is received,
     * the default program to be be loaded
     * is selected. The criteria to select
     * default program is -  The program
     * should have
     * `programType: "individual"`
     * and
     * `isActive: "true"`
     *
     * The following values from deaultConfig
     * will be stored in Browser Storage.
     *  1.  BROWSER_STORAGE_KEY_COMPANY_CODE in local storage
     *  2.  BROWSER_STORAGE_KEY_REQUEST_TIMEOUT in local storage
     *  3.  BROWSER_STORAGE_KEY_CURRENCY_CODE in local storage
     *  4.  BROWSER_STORAGE_KEY_PROGRAM_CODE in local storage
     * @author Ajmal V Aliyar
     */
    setValuesToLocalStorageFromDefaultConfig(defaultConfig, configuration = undefined, section = undefined) {
        // const { defaultConfig } = this.props
        setItemToBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE, "AP")
        if (defaultConfig) {
            // const _companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
            const _programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
            const _defaultProgramCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE)
            const _timeOut = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_REQUEST_TIMEOUT)
            const _currencyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE)
            const _dateFormat = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT)
            const _dateTimeFormat = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DATE_TIME_FORMAT)
            const _programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)


            setItemToBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE, defaultConfig.companyCode)
            if (isEmptyOrSpaces(_timeOut)) {
                setItemToBrowserStorage(
                    BROWSER_STORAGE_KEY_REQUEST_TIMEOUT,
                    defaultConfig.requestTimedOutInMs
                )
            }
            if (isEmptyOrSpaces(_programCode)
                || isEmptyOrSpaces(_currencyCode)
                || isEmptyOrSpaces(_defaultProgramCode)
                || isEmptyOrSpaces(_dateFormat)
                || isEmptyOrSpaces(_dateTimeFormat)
                || isEmptyOrSpaces(_programType)){
                // Selecting default program based on the program type
                // Get the default program, which has the isActive & isDefault flag as true
                let defaultProgram = undefined
                if (defaultConfig.programs && defaultConfig.programs.length > 0) {
                    // let programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                    // programType = programType ? programType : PROGRAM_TYPE_INDIVIDUAL
                    defaultProgram = defaultConfig.programs.find(program => {
                        return program.isActive && program.isDefault
                    })
                }
                if (defaultProgram) {

                    //  3.  set BROWSER_STORAGE_KEY_CURRENCY_CODE in local storage
                    if (isEmptyOrSpaces(_currencyCode)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE, defaultProgram.defaults.currency)
                    if (isEmptyOrSpaces(_dateFormat)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT, defaultProgram.defaults.dateFormat)
                    if (isEmptyOrSpaces(_dateTimeFormat)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_TIME_FORMAT, defaultProgram.defaults.dateTimeFormat)
                    if (isEmptyOrSpaces(_programType)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE, defaultProgram.programType)
                    //  4.  set BROWSER_STORAGE_KEY_PROGRAM_CODE in local storage
                    if (isEmptyOrSpaces(_programCode)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE, defaultProgram.programCode)
                    if (isEmptyOrSpaces(_defaultProgramCode)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE, defaultProgram.programCode)
                    if (section && configuration && configuration.programCode != defaultProgram.programCode) {
                        this.props.fetchConfiguration(section)
                    }

                } else {
                    redirectToPage(NAVIGATE_403)
                }
            }
        }
    }

    handlePrivileges() {
        const { userPrivileges, accountSummary, corporateNominees } = this.props
        const porgramType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
        const userData = porgramType === PROGRAM_TYPE_CORPORATE ? corporateNominees : accountSummary
        if (userPrivileges && userData) {
            this.setState({
                privileges: havePrivilege(
                    { "url": getCurrentPageUri(), "tab": "" },
                    userData,
                    CONTEXT_PAGE,
                    userPrivileges)
            }, () => {
                const { privileges } = this.state
                if (privileges && privileges.canDisplay) {
                    // window.location.href = "#" + NAVIGATE_403
                }
            })
        }
    }

    renderMainview(routeConfig) {

        const { defaultProps, hasSidePanel } = routeConfig
        return <>
            {
                hasSidePanel &&
                <SidePanel />
            }
            {
                this.renderComponent(routeConfig.component, defaultProps, routeConfig)
            }
        </>
    }

    renderComponent(Component, defaultProps, routeConfig) {
        const pageReference = routeConfig.key && routeConfig.key.pageReference ? routeConfig.key.pageReference : ""
        const menuReference = routeConfig.key && routeConfig.key.menuReference ? routeConfig.key.menuReference : ""
        if (routeConfig.enabled) {
            const { isOpen } = this.context
            return this.state.config ?
                (<div style={{"minHeight": window.visualViewport.height}}></div>) :
                <>
                    {
                        isOpen &&
                        <MultiFactorAuthentication />
                    }
                    <Component
                        {...defaultProps}
                        browserStorage={routeConfig.browserStorage}
                        refreshStorageData={this.setValuesToLocalStorageFromDefaultConfig}
                        pageReference={pageReference}
                        menuReference={menuReference}
                        setPageInfo={this.setPageInfo}
                        isRedirect = {routeConfig.isRedirect}
                        timeoutInSeconds = {routeConfig.timeoutInSeconds}
                        includeHeader={routeConfig.header != undefined ? routeConfig.header : true}
                        isProtected={routeConfig.isProtected}
                        programIdentifier={routeConfig.programIdentifier}
                        subClassName={routeConfig.subClassName ? routeConfig.subClassName : ""}
                        apiTriggerConfig={routeConfig.apiTriggerConfig ? routeConfig.apiTriggerConfig : {}}
                        imageBannerConfig={routeConfig.imageBannerConfig ? routeConfig.imageBannerConfig : {}}
                        accessConfig={routeConfig.accessConfig ? routeConfig.accessConfig : {}}
                    />
                </>
        } else {
            return <_404 />
        }
    }

    getUrlConfiguration = () => {
        return doGet(_URL_ENV_CONFIG_URL).then(res => {
            try {
                setUrl(res.data)
            } catch (e) {
                console.log("Error in Loading Configuration - Please check config.js", e)
            }
            this.setState({
                isApiLoaded: true
            })
            return;
        }).catch(error => {
            this.setState({
                isApiLoaded: true
            })
            return;
        })
    }

    setPageInfo = (props, configMap) => {
        const newState = {};
        const { pageReference, menuReference, title, t, desrcription, programIdentifier } = props
        const _program = getProgramFromDefaultConfigByProgramIdentifier(this.props.defaultConfig, programIdentifier)
        const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
        if (_program && _program[BROWSER_STORAGE_KEY_PROGRAM_CODE] != programCode) {
            const _programCode = _program[BROWSER_STORAGE_KEY_PROGRAM_CODE]
            let loginProgram = this.props.currentUserData ? this.props.currentUserData.userDetails.programs[_program.programType + "Info"] : [];
            loginProgram = loginProgram.find(lp =>
                lp.companyCode == companyCode &&
                lp.programCode == _programCode
            )
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE, _programCode)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE, _program.programType)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE, _program.defaults.currency)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT, _program.defaults.dateFormat)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_TIME_FORMAT, _program.defaults.dateTimeFormat)
            if (loginProgram) {
                const customerNumber = loginProgram.nominee ? loginProgram.nominee.customerNumber : loginProgram.customerNumber ? loginProgram.customerNumber : ""
                const membershipNumber = loginProgram.membershipNumber
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO, membershipNumber);
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO, customerNumber);

            }
            window.location.reload()
        }
        newState["includeHeader"] = props.includeHeader != undefined ? props.includeHeader : true
        newState["apiTriggerConfig"] = props.apiTriggerConfig ? props.apiTriggerConfig : {}
        newState["imageBannerConfig"] = props.imageBannerConfig ? props.imageBannerConfig : {}
        const profileData = this.props.profileData && this.props.profileData.object
        const seoTitle = title ? t(title) : t(ROUTER_CONFIG.default.page.title)
        const seoDescription = desrcription ? t(description) : t(ROUTER_CONFIG.default.page.description)
        if (!props.isProtected && props.clearToken) {
            // removeItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        }
        document.title = seoTitle
        document.querySelector('meta[name="description"]').setAttribute('content', seoDescription);
        if (configMap && Object.keys(configMap).length &&
            (!configMap.config ||
                Object.keys(configMap.config).length == 0)) {
            this.props.fetchConfiguration(configMap.confSection)
            newState["config"] = true
            newState["confSection"] = configMap.confSection
        }

        this.handlePrivileges()
        if (this.props.pageRef != pageReference) {
            window.scrollTo(0, 0);
            this.props.setPageReference(pageReference)
            this.props.setMenuReference(menuReference)
            this.props.resetError()
        }

        if (profileData && !profileData.areMandatoryFieldsFilled && getCurrentPageUri() != NAVIGATE_PROFILE_COMPLETION) {
            redirectToPage(`${NAVIGATE_PROFILE_COMPLETION}`)
        }
        
        const _token = this.props.token
        const _programType =  getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
        const _memNo =  getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO)
        if(!isEmptyOrSpaces(_token) && 
            !isEmptyOrSpaces(_programType) &&
            !isEmptyOrSpaces(_memNo)) {
            if(!isEmptyOrSpaces(this.state.memNo) && 
                !isEmptyOrSpaces(this.state.programType) &&
                this.state.memNo != _memNo && 
                this.state.programType == _programType) {
                    window.location.reload();
            } else {
                if(isEmptyOrSpaces(this.state.memNo)) {
                    newState["memNo"] = _memNo
                }
                if(isEmptyOrSpaces(this.state.programType)) {
                    newState["programType"] = _programType
                }
            }
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState)
        }
    }

    setErrorPageInfo = (props) => {
        const newState = {};
        newState["includeHeader"] = props.includeHeader != undefined ? props.includeHeader : true
        newState["hideHeroBanner"] = props?.hideHeroBanner ? true: false;
        if (Object.keys(newState).length > 0) {
            this.setState(newState)
        }
    }

    mapRoutes = (routeConfig, idx) => {
        if (routeConfig.isProtected) {
            const membershipNumber = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO)
            const isFirstTimeUser = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_FIRST_TIME_USER)
            return <Route exact path={routeConfig.url} key={"route-" + idx}>
                {
                    ROUTER_CONFIG.welcomeMessageConfig.enable && isFirstTimeUser == "true" && <WelcomeMessage memno={membershipNumber} key={"canvas-" + idx} />
                }
                <main role="main" className={routeConfig.mainClassName} key={"main-" + idx}>
                    {/* <PortalBreadcrumb breadcrumbs={routeConfig.breadcrumbs} /> */}
                    {
                        routeConfig.hasClassFormRow ?
                            <div className="form-row">
                                {this.renderMainview(routeConfig)}
                            </div> :
                            this.renderMainview(routeConfig)
                    }
                </main>
                {
                    // this.props.accountSummary && <DigitalCard />
                }
            </Route>
        }
        return <Route exact path={routeConfig.url} key={"route-" + idx}>
            <main role="main" className={routeConfig.mainClassName} key={"main-" + idx}>
                <PortalBreadcrumb breadcrumbs={routeConfig.breadcrumbs} />
                {
                    routeConfig.hasClassFormRow ?
                        <div className="form-row">
                            {
                                this.renderComponent(routeConfig.component, routeConfig.defaultProps, routeConfig)
                            }
                        </div> : this.renderComponent(routeConfig.component, routeConfig.defaultProps, routeConfig)
                }
            </main>
            {
                // this.props.accountSummary && <DigitalCard />
            }
        </Route>
    }

    mfaStatus(url) {
        if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MFA_TOKEN) && !checkTokenExpiry(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MFA_TOKEN))) {
            removeItemFromBrowserStorage(BROWSER_STORAGE_KEY_MFA_TOKEN)
        }
        if (MFA.ENABLED_ROUTES.indexOf(url) >= 0 && getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MFA_TOKEN)) {
            return true;
        } else if (MFA.ENABLED_ROUTES.indexOf(url) == -1) {
            return true;
        } else {
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_MFA_REDIRECT, url, BROWSER_STORAGE_TYPE_SESSION)
            let mfaRedirect = _URL_MFA_REDIRECT.replace("##REDIRECT##", encodeURI(_URL_MFA_CALLBACK));
            window.location = mfaRedirect
        }
    }

    render() {
        const { isApiLoaded, isDefaultConfigLoaded, includeHeader, apiTriggerConfig, imageBannerConfig, isTokenExist, hideHeroBanner } = this.state
        let isHashRoutingEnabled = eval(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_HASH_ROUTING_ENABLED))
        const RouterComponent = isHashRoutingEnabled ? HashRouter: Router
        return (
            isApiLoaded && this.props.isCssLoaded ? 
            <RouterComponent history={history} >
                <ErrorBoundary hideHeroBanner={hideHeroBanner}>
                    {/* <Link className="sr-only sr-only-focusable" to="#login-component-title">Skip to main content</Link> */}
                    {
                        (getCurrentPageUri()== NAVIGATE_MEMBER_LOGIN || getCurrentPageUri()== "/") ? 
                        <></> : 
                        <HeaderComponent includeHeader={includeHeader} apiTriggerConfig={apiTriggerConfig} imageBannerConfig={imageBannerConfig} hideHeroBanner={hideHeroBanner}/>
                    }
                    {
                        isDefaultConfigLoaded && (this.props.allProgramsData || getCurrentPageUri()== NAVIGATE_CALLBACK || getCurrentPageUri()== NAVIGATE_MEMBER_LOGIN || getCurrentPageUri() == "/" ) && this.mfaStatus(getCurrentPageUri()) ?
                        <Switch>
                            {
                                ROUTER_CONFIG && ROUTER_CONFIG.default.url &&
                                <Route exact path="/">
                                    <Redirect to={ROUTER_CONFIG.default.url} />
                                </Route>}
                            {
                                ROUTER_CONFIG && ROUTER_CONFIG.routes && ROUTER_CONFIG.routes.map((routeConfig, idx) => {
                                    return this.mapRoutes(routeConfig, idx)
                                })
                            }
                            <Route exact path={NAVIGATE_MFA_CALLBACK + '/:id_token?'} component={MfaCallBackPage} />
                            <Route exact path={NAVIGATE_CALLBACK}>
                                <CallbackPage />
                            </Route>
                            <Route exact path={`/${COMMIT_HASH}/info`}>
                                <BuildInfoPage />
                            </Route>
                            <Route exact path={NAVIGATE_403}>
                                <_403Page setErrorPageInfo={this.setErrorPageInfo} />
                            </Route>
                            <Route exact path={NAVIGATE_500}>
                                <_500Page setErrorPageInfo={this.setErrorPageInfo} />
                            </Route>
                            <Route exact path={NAVIGATE_COMMON_ERROR}>
                                <CommonErrorPage setErrorPageInfo={this.setErrorPageInfo} />
                            </Route>
                            <Route render={() => (<_404Page setErrorPageInfo={this.setErrorPageInfo} />)} />
                        </Switch>: (getCurrentPageUri()== NAVIGATE_MEMBER_DASHBOARD || getCurrentPageUri()== NAVIGATE_MEMBER_LOGIN || getCurrentPageUri()== "/") ? <div style={{"minHeight": window.visualViewport.height}}></div> :<Loader/>
                    }
                    {(getCurrentPageUri()== NAVIGATE_MEMBER_LOGIN || getCurrentPageUri()== "/") ? <div style={{"minHeight": window.visualViewport.height}}></div> :<ROUTER_CONFIG.footer.component />}
                </ErrorBoundary>
            </RouterComponent>
                :
                <div style={{"minHeight": window.visualViewport.height}}></div>
        )
    }
}

Main.contextType = MfaContext
function mapStateToProps(state) {
    return {
        accountSummary: state.accountSummaryReducer.accountSummary,
        login: state.login.loginInfo,
        logout: state.logOutReducer.logout,
        socialLogin: state.login.socialLogin,
        forgotPsw: state.login.forgotPsw,
        register: state.login.entrolment,
        defaultConfig: state.configurationReducer[CONFIG_SECTION_DEFAULT],
        accountSummaryConfig: state.configurationReducer[CONFIG_SECTION_ACCOUNT_SUMMARY],
        userPrivileges: state.privilegesReducer.privileges,
        corporateNominees: state.retriveAccountNomineeReducer.corporateNominees,
        customerConfig: state.configurationReducer[CONFIG_SECTION_CUSTOMER_PROFILE],
        profileConfig: state.configurationReducer[CONFIG_SECTION_PROFILE],
        pageRef: state.pageReferenceReducer.pageRef,
        qrcodeImage: state.qrCodeImageReducer.qrImage,
        profileData: state.profileDataReducer.profileData,
        configCollection: state.configurationReducer,
        currentUserData: state.currentLoginUserDataReducer.currentUserData,
        isCssLoaded: state.cssLoadReducer,
        token : state.tokenReducer.token,
        allProgramsData: state.retrieveAllProgramsDataReducer?.allPrograms?.programDetails
    }
}

const mapDispatchToProps = { loginDetails, clearLogoutData, fetchConfiguration, setPageReference, fetchProfileData, setMenuReference, resetError, changeLanguage, fetchToken }
export default connect(mapStateToProps, mapDispatchToProps)(Main);
