/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { ACTIVATE } from "./action";

export const activateReducer = (state={}, action) => {
    const { type, payload } = action;
    switch(type) {
        case ACTIVATE:
            return payload.object
        default:
            return state;
    }
}