/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { 
    SET_PROFILE_DATA, 
    SET_PROFILE_DATA_COMP, 
    SET_PROFILE_PICTURE, 
    //SET_PROFILE_CONFIG 
} from './actions';

const initialState = {};

export default function setProfileData(state, action) {
    const { type, payload } = action;
    if(!state) state = initialState
    switch (type) {
        case SET_PROFILE_DATA:
            return {
                profileData: payload
            }
        case SET_PROFILE_PICTURE:
            return {
                profilePicture: payload
            }
        default:
            return state;
    }
}

export function companyProfileData(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === SET_PROFILE_DATA_COMP) {
        return({
            ...payload
        })
    } else {
        return state;
    }
}