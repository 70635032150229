/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { startApiLoading, stopApiLoading } from "../../common/components/fieldbank/loader/action"
import { doPost } from "../../common/utils/api"
import { _URL_SIMULATE_RULE } from "../../common/config/config"
import { ERROR } from "../promotions/actions"
import { getApiErrorMessage } from "../../common/utils"

export const PROFILE_COMPLETENESS_PERCENTAGE = "PROFILE_COMPLETENESS_PERCENTAGE"

export const getProfileCompleteness = (payload) => {
    return async (dispatch) => {
        dispatch(startApiLoading("profileCompletenessPercentage"))
        await doPost(_URL_SIMULATE_RULE, payload).then(response=>{
            dispatch(stopApiLoading("profileCompletenessPercentage"))
            let data = "0"
            const { object } = response.data
            const executionItem = object.executionItems.find(e=>e.ruleOutputStatus==="success")
            if (executionItem 
                    && Object.keys(executionItem).length > 0 
                    && executionItem.variables 
                    && executionItem.variables.length>0
                    && executionItem.variables[0].value) {
                data = executionItem.variables[0].value
            }
            dispatch({
                type: PROFILE_COMPLETENESS_PERCENTAGE,
                payload: data
            })
        }).catch(error=>{
            dispatch(stopApiLoading("profileCompletenessPercentage"))
            //HINT: Code commented to hide the error message on profile page when simulate rule fails
            // dispatch({
            //     type: ERROR,
            //     payload: { error: getApiErrorMessage(error.response.data.error) }
            // })
        })
    }
}