/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withSuspense
} from '../../utils'
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';

class CustomMessage extends Component {

    renderMessage = (message, canTranslate) => {
        const { t } = this.props
        let _message = ""
        try {
            if(canTranslate) {
                _message = parse(t(message))
            } else {
                _message = parse(message)
            }
        } catch (error) {
            if(canTranslate) {
                _message = t(message)
            } else {
                _message = message
            }
        }
        return _message
    }
    render() {
        const { type, message, canTranslate, className } = this.props
        if(message && message.length) {
            return (
                <div className='col-lg-12'>
                <div className={`alert alert-${type} alert--borderless hide-fontawesome alert-danger--dark`} role="alert">
                      {type == "danger" && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clipRule="evenodd" d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM8.78784 14.25C8.78784 13.5596 9.34749 13 10.0378 13C10.7282 13 11.2878 13.5596 11.2878 14.25C11.2878 14.9404 10.7282 15.5 10.0378 15.5C9.34749 15.5 8.78784 14.9404 8.78784 14.25ZM10 4C9.18945 4 8.54912 4.6877 8.60687 5.49619L8.92875 10.0025C8.9689 10.5646 9.43655 11 10 11C10.5634 11 11.0311 10.5646 11.0712 10.0025L11.3931 5.49619C11.4509 4.6877 10.8106 4 10 4Z"
                                fill="white"></path>
                            <mask id="mask0_6869_7632" style={{ "mask-type": "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                <path fill-rule="evenodd" clipRule="evenodd" d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM8.78784 14.25C8.78784 13.5596 9.34749 13 10.0378 13C10.7282 13 11.2878 13.5596 11.2878 14.25C11.2878 14.9404 10.7282 15.5 10.0378 15.5C9.34749 15.5 8.78784 14.9404 8.78784 14.25ZM10 4C9.18945 4 8.54912 4.6877 8.60687 5.49619L8.92875 10.0025C8.9689 10.5646 9.43655 11 10 11C10.5634 11 11.0311 10.5646 11.0712 10.0025L11.3931 5.49619C11.4509 4.6877 10.8106 4 10 4Z"
                                    fill="white"></path>
                            </mask>
                            <g mask="url(#mask0_6869_7632)"> </g>
                        </svg>}
                        {type=="success" &&  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99986 14.1727L3.53319 10.706C3.14291 10.3158 2.51014 10.3158 2.11986 10.706C1.72958 11.0963 1.72958 11.7291 2.11986 12.1194L6.29275 16.2923C6.68328 16.6828 7.31644 16.6828 7.70697 16.2923L18.2932 5.70604C18.6835 5.31576 18.6835 4.68299 18.2932 4.29271C17.9029 3.90243 17.2701 3.90243 16.8799 4.29271L6.99986 14.1727Z"
                                fill="white"></path>
                            <mask id="mask0_6869_9148" style={{ "mask-type": "alpha" }} maskUnits="userSpaceOnUse" x="1" y="4" width="18" height="13">
                                <path d="M6.99986 14.1727L3.53319 10.706C3.14291 10.3158 2.51014 10.3158 2.11986 10.706C1.72958 11.0963 1.72958 11.7291 2.11986 12.1194L6.29275 16.2923C6.68328 16.6828 7.31644 16.6828 7.70697 16.2923L18.2932 5.70604C18.6835 5.31576 18.6835 4.68299 18.2932 4.29271C17.9029 3.90243 17.2701 3.90243 16.8799 4.29271L6.99986 14.1727Z"
                                    fill="white"></path>
                            </mask>
                            <g mask="url(#mask0_6869_9148)"> </g>
                        </svg>}
                    {
                        message.length == 1 ? 
                        this.renderMessage(message[0], canTranslate):
                        <ul>
                        {
                            message.map((msg, idx) => {
                                return <li key={idx}>{this.renderMessage(msg, canTranslate)}</li>
                            })
                        }
                        </ul>
                    }
                </div>
                </div>
            );
        } else {
            return <></>
        }
    }
}

CustomMessage.propTypes = {
    message: PropTypes.array,
    type: PropTypes.string
};

CustomMessage.defaultProps = {
    message: [],
    type: "success",
    canTranslate: false
}
export default withSuspense()(withTranslation()(CustomMessage));