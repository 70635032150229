/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
    ENROLMENT_DATA,
    PREAFFLIATED_DATA,
    PREAFFLIATED_ERROR,
    PREAFFILIATED_ENROLMENT_DATA
} from './action';

export default function setEnrolmenteData(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case ENROLMENT_DATA:
            return {
                enrolmentData: payload
            }
        case PREAFFLIATED_DATA:
            return {
                preaffliatedData: payload
            }
        case PREAFFLIATED_ERROR:
            return {
                preaffliatedError: payload
            }               
        default:
            return state;
    }

}
