/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export default class Loader extends React.Component {
    render() {
        return <div className="container" style={{textAlign: "center", "minHeight": window.visualViewport.height}}><ProgressSpinner {...this.props}/></div>
    }
}