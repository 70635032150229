/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { isEmptyOrSpaces } from "."

export const _URL_PARAMS_ACCESS_KEY = "accessKey"
export const _URL_PARAMS_CID = "cid"
export const _URL_PARAMS_LANG = "locale"
export const _URL_PARAMS_TRANSACTION_ID = "tid"
export const _URL_PARAMS_TAB = "tab"
export const _URL_PARAMS_SOURCE = "source"

export const _RESPONSE_HEADER_TOKEN = "ps-token"
export const _RESPONSE_HEADER_REFRESH_TOKEN = "ps-refreshtoken"

/**
 * @author Somdas M
 * @description To update the value of a query param of the URL
 * @param String key The query param key present 
 * @param String value The new value 
 */
// export const updateQueryParamsAndNavigate = (key, value = "") => {
//     let { search } = window.location
//     const keyEquals = key + "="
//     if (search.includes("?")) {
//         if (search.includes(keyEquals)) {
//             let params = search.split(keyEquals)
//             if (params.length > 1 && params[1]) {
//                 let tabContent = params[1]
//                 tabContent = tabContent.split("&")
//                 tabContent.splice(0, 1, value)
//                 if (!isEmptyOrSpaces(value)) {
//                     tabContent = keyEquals + tabContent.join("&")
//                 }
//                 if(typeof tabContent == 'object'){
//                     tabContent = tabContent.filter(e=>e!="")
//                     tabContent = tabContent.join("&")
//                 }
//                 params.splice(1, 1, tabContent)
//                 search = params.join("")
//             }
//         } else if (!isEmptyOrSpaces(value)) {
//             search += "&&" + keyEquals + value
//         }

//     } else if (!isEmptyOrSpaces(value)) {
//         search += "?" + keyEquals + value
//     }
//     // window.location.search = search
// }

/**
 * @author Somdas M
 * @description Method to retrieve value from then respective query param in URL
 * @param String key The key to which value is to be extracted
 */
export const getValueFromQueryParam = (key, href=window.location.href) => {
    // let { href } = window.location
    let queryParam = ""
    const keyEquals = key + "="
    if (href.includes("?") && href.includes(keyEquals) && !isEmptyOrSpaces(key)) {
        const param = href.split(keyEquals)
        if (param.length > 1 && param[1]) {
            if (param[1].includes("&")) {
                queryParam = param[1].split("&")[0]
            } else {
                queryParam = param[1]
            }
        }
    }
    return queryParam
}