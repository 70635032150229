/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { store } from './components/common/middleware/store';
import { Provider } from 'react-redux';
import './components/common/i18n/i18n';
import Main from './pages';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/customPrimereact/datatable.scss';
import './styles/customPrimereact/main.scss';
import { MfaProvider } from './context/MfaContext';

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import {_URL_SSO_CONFIG} from "./components/common/config/config"

// MSAL configuration
const configuration_UAT = {
  auth: {
    "clientId": "b41a2236-a4d5-4c3f-8bb8-4c3bbd0589a2",
    "authority": "https://identity.qual.airnewzealand.com/qCustomerairnz.onmicrosoft.com/B2C_1A_AIRNZ_SUSI",
    "knownAuthorities": [
        "identity.qual.airnewzealand.com"
    ],
    "redirectUri": window.location.origin + "/airpoints-account/airpoints/auth/login",
    "postLogoutRedirectUri": "https://auth.airnewzealand.co.nz/vauth/oauth2/logout"
  }
};

const configuration_PROD = {
  auth: {
    "clientId": "5cfd7b3b-f095-4a1e-9860-5f03ade4d715",
    "authority": "https://identity.airnewzealand.com/customerairnz.onmicrosoft.com/B2C_1A_AIRNZ_SUSI",
    "knownAuthorities": [
        "identity.airnewzealand.com"
    ],
    "redirectUri": window.location.origin + "/airpoints-account/airpoints/auth/login",
    "postLogoutRedirectUri": "https://auth.airnewzealand.co.nz/vauth/oauth2/logout"
  }
};
let configuration = configuration_PROD;
// console.log(configuration)
const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <MfaProvider>
          <MsalProvider instance={pca}>
            <Main />
          </MsalProvider>
        </MfaProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
