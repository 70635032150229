/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { doPost } from '../../common/utils/api';
import * as API_URL from '../../common/config/config'
import { _URL_MIELAGE_CALCULATOR_EARN, _URL_MIELAGE_CALCULATOR_BURN } from '../../common/config/config';
import { getApiErrorMessage, isApiResponseAuthorized } from '../../common/utils'
import { TAB_EARN_MILES, TAB_USE_MILES, TAB_UPGRADE_MILES } from './Constants';
import { ERROR } from '../../common/middleware/redux/commonAction';
import { createEarnMilesResponse, createUseMilesResponse, createUpgradeUsingMilesResponse } from './utils';
import { startButtonSpinner, stopButtonSpinner } from '../../common/components/fieldbank/loader/action';

export const MILEAGE_CALCULATOR_SUCCESS = "MILEAGE_CALCULATOR_SUCCESS"
export const MILEAGE_CALCULATOR_FAILED = "MILEAGE_CALCULATOR_FAILED"
export const CLEAR_MILEAGE_CALCULATOR = "CLEAR_MILEAGE_CALCULATOR"

const MILEAGE_CALCULATOR_ACTION = {
    [TAB_EARN_MILES]:
    {
        "url": "_URL_MIELAGE_CALCULATOR_EARN",
        "actionType": TAB_EARN_MILES
    },
    [TAB_USE_MILES]:
    {
        "url": "_URL_MIELAGE_CALCULATOR_BURN",
        "actionType": TAB_USE_MILES
    },
    [TAB_UPGRADE_MILES]:
    {
        "url": "_URL_MIELAGE_CALCULATOR_BURN",
        "actionType": TAB_UPGRADE_MILES
    },
    
}
export const calculateMileage = (payload, tabName, pageConfig, cabinClass, responsePayload, currentPointsDetails, upgradeTo, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "calculateMileage"))
        await doPost(API_URL[MILEAGE_CALCULATOR_ACTION[tabName].url], payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "calculateMileage"))
                if(tabName == TAB_EARN_MILES){
                    createEarnMilesResponse(response.data.object, pageConfig, responsePayload, currentPointsDetails)
                } else if (tabName == TAB_USE_MILES){
                    createUseMilesResponse(response.data.object, pageConfig, cabinClass, responsePayload, currentPointsDetails)
                } else if (tabName == TAB_UPGRADE_MILES){
                    createUpgradeUsingMilesResponse(response.data.object, pageConfig, cabinClass, responsePayload, upgradeTo, currentPointsDetails)
                }
                dispatch({
                    type: MILEAGE_CALCULATOR_ACTION[tabName].actionType,
                    payload: responsePayload
                })
                
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "calculateMileage"))
                isApiResponseAuthorized(error)
                dispatch({
                    type: MILEAGE_CALCULATOR_ACTION[tabName].actionType,
                    payload: responsePayload
                })
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const clearExisitingData = (tabName) => {
    return async (dispatch) => {
        dispatch({
            type: MILEAGE_CALCULATOR_ACTION[tabName].actionType,
            payload: {}
        })
    }
}