/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { SET_FEED_BACK, SET_FEEDBACK_DYSECTION } from "./actions";

const initialState = {dynamicSection:[]};

export default function feedbackReducer(state, action) {
    if (!state) state = initialState;
    const { type, payload } = action;
    if (type == SET_FEED_BACK) {
        return {
            ...state,
            feedBackDetails: payload
        }
    } else if (type == SET_FEEDBACK_DYSECTION) {
        return {
            ...state,
            dynamicSection: payload
        }
    } else {
        return state;
    }
}

