/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { startApiLoading, startButtonSpinner, stopApiLoading, stopButtonSpinner } from '../../components/fieldbank/loader/action'
import {
    _DEFAULT_CLIENT_TYPE,
    _URL_ACCOUNT_SUMMARY,
    _URL_ADD_DELETE_NOMINEE,
    _URL_CONFIGURATION_SERVICE,
    _URL_FETCH_FAMILY_MEMBERS,
    _URL_FETCH_PROFILE_DETAILS,
    _URL_FETCH_PROFILE_IMAGE,
    _URL_FETCH_QRCODE_IMAGE,
    _URL_FETCH_TOKEN, _URL_LOGOUT,
    _URL_MANAGE_PROFILE_ATTRIBUTE,
    _URL_MASTER_RETRIEVE,
    _URL_RETRIEVE_CUSTOMER_PROFILE,
    _URL_RETRIEVE_NOMINEES,
    _URL_RETRIEVE_RENEW_EXPIRY_POINT_DETAILS,
    _URL_SEARCH_MEMBER,
    _URL_SOCIAL_LOGGED_USER,
    _URL_VALIDATE_MEMBER_DETAIL,
    _URL_ALL_PROGRAMS_RETRIEVE,
    _URL_MEMBER_BENEFIT_SERVICE,
    _URL_MEMBER_DIGITALCARD_SERVICE,
    _URL_GET_AVAILABLE_BALANCE,
    _URL_FETCH_NEW_TOKEN,
    _CONTEXT_PATH,
    _URL_MEMBER_OTHERCARDS_SERVICE,
    _URL_ADHOC_ACTIVITY,
    _URL_PRODUCT_DETAILS,
    _URL_FETCH_PS_TOKEN_WITH_ID_TOKEN
} from '../../config/config'
import { ROUTER_CONFIG } from '../../config/routing'
import {
    decryptConfig,
    getApiErrorMessage, getCurrentPageUri, isApiResponseAuthorized, isEmptyOrSpaces,
    redirectToPage
} from '../../utils'
import { doGet, doPost } from '../../utils/api'
import { getLogoutMessageData } from '../../utils/common.utils'
import {
    CLIENT_ID, CONFIG_SECTION_DEFAULT, MASTER_REARRANGE, PROGRAM_TYPE_CORPORATE, PROGRAM_TYPE_INDIVIDUAL, REDIRECT_TO, SECRET, CUSTOMER_GUID_DYNAMIC_ATTR_CODE
} from '../../utils/Constants'
import { getCurrentPageConfigFromRouterConfig, redirectPage } from '../../utils/sso.utils'
import {
    BROWSER_STORAGE_KEY_ACCESS_KEY,
    BROWSER_STORAGE_KEY_CID,
    BROWSER_STORAGE_KEY_COMPANY_CODE,
    BROWSER_STORAGE_KEY_CUSTOMER_NO,
    BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_EMAIL,
    BROWSER_STORAGE_KEY_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE, 
    BROWSER_STORAGE_TYPE_SESSION,
    BROWSER_STORAGE_CUSTOMER_GUID,
    clearBrowserStorage,
    getItemFromBrowserStorage,
    setItemToBrowserStorage,
    removeItemFromBrowserStorage,
    BROWSER_STORAGE_REDIRECT_TO,
    BROWSER_STORAGE_KEY_TOKEN,
    BROWSER_STORAGE_KEY_IDP_TOKEN
} from '../../utils/storage.utils'
import { memberTier } from '../../utils/tier.utils'
import {
    NAVIGATE_403,
    NAVIGATE_404,
    NAVIGATE_500,
    NAVIGATE_CALLBACK,
    NAVIGATE_CORPORATE,
    NAVIGATE_CORPORATE_OVERVIEW,
    NAVIGATE_MEMBER_DASHBOARD,
    NAVIGATE_MEMBER_LOGIN,
    NAVIGATE_PROFILE_COMPLETION
} from '../../utils/urlConstants'
import { _RESPONSE_HEADER_REFRESH_TOKEN, _RESPONSE_HEADER_TOKEN } from '../../utils/urlParams.utils'
import { store } from '../store'

export const ACTION_FETCH_CURRENT_USER_SUCCESS = "ACTION_FETCH_CURRENT_USER_SUCCESS"
export const ACTION_FETCH_CURRENT_USER_FAILURE = "ACTION_FETCH_CURRENT_USER_FAILURE"
export const ACTION_FETCH_PROFILEDATA_SUCCESS = "ACTION_FETCH_PROFILEDATA_SUCCESS"
export const ACTION_FETCH_PROFILEDATA_FAILURE = "ACTION_FETCH_PROFILEDATA_FAILURE"
export const FETCH_ACCOUNT_SUMMARY = "FETCH_ACCOUNT_SUMMARY"
export const FETCH_ACCOUNT_SUMMARY_ERROR = "FETCH_ACCOUNT_SUMMARY_ERROR"
export const MASTER_SET_GENDER = "MASTER_SET_GENDER";
export const MASTER_SET_ISD_CODES = "MASTER_SET_ISD_CODES";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const SET_CONFIG_DATA = "SET_CONFIG_DATA"
export const RESET_ERROR = "RESET_ERROR"
export const ERROR = 'ERROR';
export const LOGOUT = "LOGOUT";
export const RESET_STATE = "RESET_STATE"
export const FETCH_USERINFO = 'FETCH_USERINFO';
export const ALL_PROGRAMS_RETRIEVE = 'ALL_PROGRAMS_RETRIEVE';
export const MEMBER_BENEFIT_SERVICE = 'MEMBER_BENEFIT_SERVICE';
export const MEMBER_DIGITALCARD_SERVICE = 'MEMBER_DIGITALCARD_SERVICE';
export const OTHER_CARDS_SERVICE = 'OTHER_CARDS_SERVICE';
export const ADHOC_ACTIVITY = 'ADHOC_ACTIVITY';

export const FINDING_USER_ERROR = 'FINDING_USER_ERROR';
export const SET_FAMILY_MEMBERS = "SET_FAMILY_MEMBERS"
export const FETCH_PROFILE_IMAGE = 'FETCH_PROFILE_IMAGE';
export const FETCH_CUSTOMER_PROFILE = 'FETCH_CUSTOMER_PROFILE'
export const ACTION_PRIVILEGES = "SET_PRIVILEGES"
export const FETCH_QRCODE_IMAGE = 'FETCH_QRCODE_IMAGE';
export const FETCH_QRCODE_IMAGE_ERROR = 'FETCH_QRCODE_IMAGE_ERROR';
export const FETCH_ACCOUNT_NOMINEE_SUCCESS = 'FETCH_ACCOUNT_NOMINEE_SUCCESS';
export const FETCH_ACCOUNT_NOMINEE_FAILURE = 'FETCH_ACCOUNT_NOMINEE_FAILURE';
export const PAGE_REFERENCE = "PAGE_REFERENCE";
export const CURRENT_MENU = 'CURRENT_MENU';
export const SET_TIER_DETAILS = "SET_TIER_DETAILS";
export const SET_VALIDATE_MEMBER_DETAIL = "SET_VALIDATE_MEMBER_DETAIL"
export const RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS = "RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS"
export const SET_SELECTED_TAB = "SET_SELECTED_TAB"
export const FETCH_PRODUCT_DETAILS = "FETCH_PRODUCT_DETAILS"

export const ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS = "ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS";
export const ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE = "ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE";

export const ACTION_ADD_DELETE_NOMINEE = "ACTION_ADD_DELETE_NOMINEE"
export const ACTION_MANAGE_PROFILE_ATTRIBUTE = "ACTION_MANAGE_PROFILE_ATTRIBUTE"
export const ACTION_GET_AVAILABLE_BALANCE = "ACTION_GET_AVAILABLE_BALANCE"


export const ADD_OR_DELETE = "addOrDeleteNominee"
export const MANAGE_PROFILE_ATTRIBUTE = "manageProfileAttribute"
export const GET_AVAILABLE_BALANCE = "getAvailableBalance"


export const CSS_LOADER = "CSS_LOADER"
export const FETCH_TOKEN = "FETCH_TOKEN"
export const SET_LOGIN_DATA = "SET_LOGIN_DATA"

export const FETCH_NEW_TOKEN = "FETCH_NEW_TOKEN"
export const CLEAR_FETCH_TOKEN = "CLEAR_FETCH_TOKEN"



/**
 * Creates Action of type <i>_URL_SEARCH_MEMBER</i> with given data
 *
 * @function fetchUserInfo
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_SEARCH_MEMBER</i>
 *
 */
export const fetchUserInfo = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchUserInfo"))
        return await doPost(_URL_SEARCH_MEMBER, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchUserInfo"))
                dispatch({
                    type: FETCH_USERINFO,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchUserInfo"))
                dispatch({
                    type: FINDING_USER_ERROR,
                    payload: error
                })
            })
    };
};

/**
 * @function retrieveAllProgramsData
 * @returns Action of type <i>_URL_ALL_PROGRAMS_RETRIEVE</i>
 */

export const retrieveAllProgramsData = () => {
    let payload = {
        "object": {
            "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            "membershipNumber": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO),
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchUserInfo"))
        return await doPost(_URL_ALL_PROGRAMS_RETRIEVE, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveAllProgramsData"))
                if (response.status == 200) {
                    dispatch({
                        type: ALL_PROGRAMS_RETRIEVE,
                        payload: response.data.object
                    })
                } else {
                    dispatch({
                        type: ALL_PROGRAMS_RETRIEVE,
                        payload: null
                    })
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveAllProgramsData"))
                dispatch({
                    type: ALL_PROGRAMS_RETRIEVE,
                    payload: null
                })
            })
    };
};

/**
 * @function retrieveMemberBenefitsData
 * @returns Action of type <i>_URL_MEMBER_BENEFIT_SERVICE</i>
 */

 export const retrieveMemberBenefitsData = () => {
    let payload = {
        "object": {
            "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            "membershipNumber": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("retrieveMemberBenefitsData"))
        return await doPost(_URL_MEMBER_BENEFIT_SERVICE, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveMemberBenefitsData"))
                if (response.status == 200) {
                    dispatch({
                        type: MEMBER_BENEFIT_SERVICE,
                        payload: response.data.object
                    })
                } else {
                    dispatch({
                        type: MEMBER_BENEFIT_SERVICE,
                        payload: null
                    })
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveMemberBenefitsData"))
                dispatch({
                    type: MEMBER_BENEFIT_SERVICE,
                    payload: null
                })
            })
    };
};

/**
 * @function retrieveDigitalCardData
 * @returns Action of type <i>_URL_MEMBER_DIGITALCARD_SERVICE</i>
 */

 export const retrieveDigitalCardData = () => {
    let payload = {
        "object": {
            "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            "membershipNumber": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("retrieveDigitalCardData"))
        // let mock = {
        //     "statuscode": "200",
        //     "statusMessage": "SUCCESS",
        //     "object": {
        //       "companyCode": "NZ",
        //       "programCode": "AP",
        //       "cardDetails": [
        //         {
        //           "cardType": "AIRPOINTS",
        //           "name": "Emma Smith",
        //           "membershipNumber": "1234567",
        //           "tierCode": "SN",
        //           "tierExpiryDate": "31-12-2022",
        //           "imageUrl": "iVBORw0KGgoAAAANSUhEUgAAAMgAAABkAQAAAADr/UKmAAAAOUlEQVR4XmP4ObMh/eHDNjm5ivSfMxvnSVQ2PmyfV1c/cz7DqMyozKjMqMyozKjMqMyozKjMCJYBABWTaXSFjmAgAAAAAElFTkSuQmCC",
        //           "additionalInfo": {
        //             "rawData": "1234567890345",
        //             "isElitePriorityOne": "YES"
        //           }
        //         },
        //         {
        //           "cardType": "STAR",
        //           "name": "Emma Smith",
        //           "membershipNumber": "7654321",
        //           "tierCode": "GN",
        //           "tierExpiryDate": "31-Dec-20222",
        //           "imageUrl": "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAAB9klEQVR4Xu2ZW24DIQxFLbGALmm2zpJmAUjU59ohbdT2O1eqhSgxZz5u/IBJY/9tM149L/YPlL0NMCOukX/va9wx7is+etMIWJGeuRhy35GeAXylxwbo3R+HEbDHniuCGGWksIzdrJC5ABmdHOnMOf01Dzy5sAEywTIuqQiGmZSrdX8PFoB2Sxp6M3bqAFlNLdMBSKusU7xItiGxtDUfQDLTSb2DVSvjqa/fw7sDWy337HbKIRyzASj5ZAjW4ChspRUvF4Dyjz7TS9quwlEH8AECmTSuY1qfnHQA0pYUrVMyqAYrmR7ALYgAzRJZ4XvKtAAQyFrdLI37Va5Luw2AzFm6zpyqdVfxAZRsqYutEvhRsZNmE0Ce34YNQFxKkDz4dYEMzhcbIBNs1ZnOllIuT5abj3rMBUiZE13P8ue4D8T6AOhCGowMmd0QfICgX5WosvW4n1gBkQN/iWylvFU9C8cASEUci0q23edLH5RGADJXX66ikm11BzACSDnNXfLdx3hbtwHYxblpAiVZH49MC2ApzRZ9eCJTqjdvhWA+wNQgNKmxtlic8ncAkMPvCanx3HWrG7RMD+BWmsGgTr+lF8zDNsCmRrR7Sd2UQJSeYL0/8Ei5H4cNQJpJ0TfTpeWRcgbA4oobR9rQv5lGH/E2wN/2D5R5AJ/aLn/s//fRrgAAAABJRU5ErkJggg==",
        //           "additionalInfo": {
        //             "isElitePriorityOne": "NO"
        //           }
        //         },
        //         {
        //           "cardType": "ELITEPARTNER",
        //           "name": "Emma Smith",
        //           "membershipNumber": "01928374",
        //           "tierCode": "SN",
        //           "tierExpiryDate": "31-12-2022",
        //           "imageUrl": "",
        //           "additionalInfo": {
        //             "dynamicAttrValue": "31-Dec-2022",
        //             "isElitePriorityOne": "NO"
        //           }
        //         }
        //       ]
        //     }
        //   }
        let mock = {
            "statuscode": "200",
            "statusMessage": "SUCCESS",
             "object": {
             "companyCode": "NZ",
             "programCode": "AP",
             "digitalCardDetailsList": [
             {
             "cardType": "AIRPOINTS",
             "programCode": "AP",
             "name": "Aruna",
             "membershipNumber": "6187232",
             "tierCode": "AE",
             "tierExpiryDate": "26-Jul-2024",
             "imageUrl": "iVBORw0KGgoAAAANSUhEUgAAAMgAAABkAQAAAADr/UKmAAAAN0lEQVR4XmP4ObMh/eHDtgr5torEmY3PJCrnP//Z3gakGEZlRmVGZUZlRmVGZUZlRmVGZUawDACNP3ncDI00KwAAAABJRU5ErkJggg==",
             "additionalInfo": {
             "isElitePriorityOne": "YES",
             "rawData": "2642002026312"
             },
             "uiconfig": {
             "theme": "theme1"
             }
             },
             {
             "cardType": "STAR",
             "programCode": "AP",
             "name": "Aruna",
             "membershipNumber": "6187232",
             "tierCode": "AE",
             "tierExpiryDate": "26-Jul-2024",
             "imageUrl": "iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQAAAACgl2eQAAAB9klEQVR4Xu2ZW24DIQxFLbGALmm2zpJmAUjU59ohbdT2O1eqhSgxZz5u/IBJY/9tM149L/YPlL0NMCOukX/va9wx7is+etMIWJGeuRhy35GeAXylxwbo3R+HEbDHniuCGGWksIzdrJC5ABmdHOnMOf01Dzy5sAEywTIuqQiGmZSrdX8PFoB2Sxp6M3bqAFlNLdMBSKusU7xItiGxtDUfQDLTSb2DVSvjqa/fw7sDWy337HbKIRyzASj5ZAjW4ChspRUvF4Dyjz7TS9quwlEH8AECmTSuY1qfnHQA0pYUrVMyqAYrmR7ALYgAzRJZ4XvKtAAQyFrdLI37Va5Luw2AzFm6zpyqdVfxAZRsqYutEvhRsZNmE0Ce34YNQFxKkDz4dYEMzhcbIBNs1ZnOllIuT5abj3rMBUiZE13P8ue4D8T6AOhCGowMmd0QfICgX5WosvW4n1gBkQN/iWylvFU9C8cASEUci0q23edLH5RGADJXX66ikm11BzACSDnNXfLdx3hbtwHYxblpAiVZH49MC2ApzRZ9eCJTqjdvhWA+wNQgNKmxtlic8ncAkMPvCanx3HWrG7RMD+BWmsGgTr+lF8zDNsCmRrR7Sd2UQJSeYL0/8Ei5H4cNQJpJ0TfTpeWRcgbA4oobR9rQv5lGH/E2wN/2D5R5AJ/aLn/s//fRrgAAAABJRU5ErkJggg==",
             "additionalInfo": {
             "isElitePriorityOne": "YES",
             "rawData": "FFPC001NZ202631 TURNER MICHAEL NZG0324 N ^001"
             },
             "uiconfig": {
             "theme": "theme2"
             }
             }
             ]
             }
            }
        return await doPost(_URL_MEMBER_DIGITALCARD_SERVICE, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveDigitalCardData"))
                if (response.status == 200) {
                    dispatch({
                        type: MEMBER_DIGITALCARD_SERVICE,
                        payload: response.data.object
                    })
                } else {
                    dispatch({
                        type: MEMBER_DIGITALCARD_SERVICE,
                        payload: null
                    })
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveDigitalCardData"))
                dispatch({
                    type: MEMBER_DIGITALCARD_SERVICE,
                    payload: {}
                })
            })
    };
};

export const fetchAccountSummary = (payload) => {
    if (!payload) {
        payload = {
            object: {
                companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
                programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
                membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
                isBonusRequired: "true",
                tierOptionsRequired: true,
                creditBalance:"Y"
            }
        }
    }

    return async dispatch => {
        dispatch(startApiLoading("fetchAccountSummary"))
        return await doPost(_URL_ACCOUNT_SUMMARY, payload)
            .then(response => {
                dispatch(stopApiLoading("fetchAccountSummary"))
                dispatch({
                    type: FETCH_ACCOUNT_SUMMARY,
                    payload: response.data
                })
                return response.data.object;
            }).catch(error => {
                dispatch(stopApiLoading("fetchAccountSummary"))
                dispatch({
                    type: FETCH_ACCOUNT_SUMMARY_ERROR,
                    payload: error
                })
                isApiResponseAuthorized(error)
                if (error && error.response && error.response.status == 500) {
                    redirectToPage(NAVIGATE_500)
                }
            })
    }
}

export const setGenderData = payload => {
    return ({
        type: MASTER_SET_GENDER,
        payload
    })
}

export const fetchMasterData = (entityCode, masterEntityLookup, masterEntityLookupFilters) => {
    return async dispatch => {
        dispatch(startApiLoading(`fetchMasterData[${entityCode}]`))
        const requestBody = {
            "object": {
                "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
                "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
                "entityCode": masterEntityLookup[entityCode]
            }
        }

        if (masterEntityLookupFilters && masterEntityLookupFilters[entityCode] && Object.keys(masterEntityLookupFilters[entityCode]).length) {
            requestBody.object["filterFieldDetail"] = masterEntityLookupFilters[entityCode]
        }

        dispatch({
            type: SET_MASTER_DATA,
            payload: { data: [], type: entityCode }
        });
        return await doPost(_URL_MASTER_RETRIEVE, requestBody)
            .then(response => {
                dispatch(stopApiLoading(`fetchMasterData[${entityCode}]`))
                if (response.data.object && response.data.object.masterRecordList) {
                    let { masterRecordList } = response.data.object
                    if (MASTER_REARRANGE[entityCode]) {
                        const rearrangeConfig = MASTER_REARRANGE[entityCode]
                        if (rearrangeConfig.defaultValue) {
                            let index = -1, defaultObj = {};
                            masterRecordList.some(function (entry, i) {
                                if (entry.CountryCode == rearrangeConfig.defaultValue) {
                                    index = i;
                                    defaultObj = entry;
                                    return true;
                                }
                            });
                            if (index != -1) {
                                masterRecordList.splice(index, 1);
                                masterRecordList.unshift(defaultObj);
                            }
                        }
                    }

                    dispatch({
                        type: SET_MASTER_DATA,
                        payload: { data: masterRecordList, type: entityCode }
                    });

                } else {
                    dispatch({
                        type: SET_MASTER_DATA,
                        payload: { data: [], type: entityCode }
                    });
                }
            }).catch(error => {
                dispatch(stopApiLoading(`fetchMasterData[${entityCode}]`))
                isApiResponseAuthorized(error)
            })
    }
}

export const fetchConfiguration = section => {
    let _URL = `${_URL_CONFIGURATION_SERVICE}/${section}`, canProceed = true
    if (section != CONFIG_SECTION_DEFAULT) {
        const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
        const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        canProceed = !isEmptyOrSpaces(companyCode) && !isEmptyOrSpaces(programCode)
        _URL += `/${companyCode}/${programCode}`
    }
    return async dispatch => {
        if(canProceed) {
            dispatch(startApiLoading(`fetchConfiguration[${section}]`))
            return await doGet(_URL)
                .then(response => {
                    const decryptResponse= decryptConfig(response.data.object, ROUTER_CONFIG.encr)
                    if (section == CONFIG_SECTION_DEFAULT) {
                        let clientTypeMapping = decryptResponse?.data?.clientTypeMapping
                        if (clientTypeMapping) {
                            window.sessionStorage.setItem(SECRET, clientTypeMapping[_DEFAULT_CLIENT_TYPE]?.secret)
                            window.sessionStorage.setItem(CLIENT_ID, clientTypeMapping[_DEFAULT_CLIENT_TYPE]?.clientId)
                        }
                        let currentPageUri = getCurrentPageUri()
                        if (currentPageUri == NAVIGATE_MEMBER_LOGIN  || currentPageUri == "/" ) {
                            currentPageUri = undefined
                        }
                        const pageConfig = getCurrentPageConfigFromRouterConfig()
                        if (
                            pageConfig && pageConfig.isProtected &&
                            !isEmptyOrSpaces(BROWSER_STORAGE_KEY_ACCESS_KEY) &&
                            !isEmptyOrSpaces(BROWSER_STORAGE_KEY_CID)) {
                            dispatch(fetchToken(response.data.object, currentPageUri))
                        }
                    }
                    dispatch(stopApiLoading(`fetchConfiguration[${section}]`))
                    dispatch({
                        type: SET_CONFIG_DATA,
                        payload: { config: decryptResponse, type: section }
                    });
                }).catch(error => {
                    dispatch(stopApiLoading(`fetchConfiguration[${section}]`))
                    isApiResponseAuthorized(error)
                })
        } else {
            dispatch({
                type: SET_CONFIG_DATA,
                payload: { config: {}, type: section }
            });
        }
    }
}

export const resetError = () => {
    return ({
        type: RESET_ERROR
    })
}

export const setError = messageArray => {
    return {
        type: ERROR,
        payload: {
            error: messageArray
        }
    }
}

export const logOut = (loading = true, action = "self") => {
    resetError()
    const state = store.getState()
    const { token, refreshToken } =  state.tokenReducer
    const requestHeader = {
        "RefreshToken": refreshToken,
        "Authorization": token,
        secret : window.sessionStorage.getItem(SECRET),
        clientId : window.sessionStorage.getItem(CLIENT_ID),
        clientType : _DEFAULT_CLIENT_TYPE

    }
    clearBrowserStorage()
    clearBrowserStorage(BROWSER_STORAGE_TYPE_SESSION)
    const logoutMessageParams = getLogoutMessageData(action)
    
    return async (dispatch) => {
        if (loading) {
            dispatch(startApiLoading("logOut"))
        }
        await doPost(_URL_LOGOUT, {"object":{companyCode: "NZ", programCode: "AP"}}, requestHeader)
            .then(response => {
                dispatch(stopApiLoading("logOut"))
                dispatch({
                    type: LOGOUT,
                    payload: response.data.success
                })
            })
            .catch(error => {
                dispatch(stopApiLoading("logOut"))
                console.log("Error occured during logout: ", error)
                dispatch({
                    type: LOGOUT,
                    payload: true
                })
            })
    }
}
export const clearLogoutData = () => {
    return dispatch => {
        dispatch({
            type: LOGOUT,
            payload: {}
        })
    }
}

export const clearState = () => {
    return dispatch => {
        dispatch({
            type: RESET_STATE,
            payload: {}
        })
    }
}

/**
 * Get family members api call
 * @author Alan Kuriakose
 * @param {object} params - parameters for api call
 */
export const getFamilyMembers = params => {
    return async dispatch => {
        dispatch(startApiLoading("getFamilyMembers"))
        await doPost(_URL_FETCH_FAMILY_MEMBERS, params)
            .then(response => {
                dispatch(stopApiLoading("getFamilyMembers"))
                const members = response.data.object;
                dispatch({
                    type: SET_FAMILY_MEMBERS,
                    payload: members
                })
            }).catch(error => {
                dispatch(stopApiLoading("getFamilyMembers"))
                console.log(error);
            })
    }
}
export const getProductDetails = params => {
    return async dispatch => {
        dispatch(startApiLoading("getProductDetails"))
        await doPost(_URL_PRODUCT_DETAILS, params)
            .then(response => {
                dispatch(stopApiLoading("getProductDetails"))
                const details = response.data.object
                dispatch({
                    type: FETCH_PRODUCT_DETAILS,
                    payload: details
                })
            }).catch(error => {
                dispatch(stopApiLoading("getProductDetails"))
                console.log(error);
            })
    }
}

/**
 * Creates Action of type <i>_URL_FETCH_PROFILE_IMAGE</i> with given data
 *
 * @function fetchProfileImage
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_FETCH_PROFILE_IMAGE</i>
 *
 */
export const fetchProfileImage = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchProfileImage"))
        return await doPost(_URL_FETCH_PROFILE_IMAGE, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchProfileImage"))
                dispatch({
                    type: FETCH_PROFILE_IMAGE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchProfileImage"))
                dispatch({
                    type: ERROR,
                    payload: error
                })
            })
    };
};

/**
 * Get the customer profile data
 * @author Somdas M
 * @param {object} payload - request body for api call
 */
export const fetchCustomerProfileData = () => {
    const payload = {
        "object": {
            "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            "customerNumber": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchCustomerProfileData"))
        return await doPost(_URL_RETRIEVE_CUSTOMER_PROFILE, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchCustomerProfileData"))
                const object = response.data.object;
                dispatch({
                    type: FETCH_CUSTOMER_PROFILE,
                    payload: { object }
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchCustomerProfileData"))
                dispatch({
                    type: ERROR,
                    payload: error
                })
                isApiResponseAuthorized(error)
            })
    };
}

/**
 * @description API call for fetching profile data
 * @author Ajmal Aliyar
 */
export const fetchProfileData = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO),
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchProfileData"))
        return await doPost(_URL_FETCH_PROFILE_DETAILS, requestBody)
            .then(response => {

                dispatch(stopApiLoading("fetchProfileData"))
                const changeKey = (oldKey, newKey, objectParam) => {
                    objectParam[newKey] = objectParam[oldKey];
                    delete objectParam[oldKey];
                }
                const deleteKeys = (keys, objectParam) => {
                    keys.forEach(key => {
                        delete objectParam[key];
                    })
                }
                const object = response.data.object;

                /**
                 * Store Customer GUID into browser storage
                 * Change is been added for Authsignal MFA
                 */
                let guidArray = object.memberAccount.memberDynamicAttributes.filter(data => data.attributeCode == CUSTOMER_GUID_DYNAMIC_ATTR_CODE);
                if (guidArray.length) {
                    setItemToBrowserStorage(BROWSER_STORAGE_CUSTOMER_GUID, guidArray[0].attributeValue);
                }
                /**
                 * END of Store Customer GUID into browser storage
                 */
                if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) == PROGRAM_TYPE_INDIVIDUAL) {
                    // clean object
                    deleteKeys(['isAccrualValid', 'isRedemptionValid', 'memberArtefactDetail'], object);
                    object.paymentDetail = null;
                    // clean object.memberAccount
                    // object.memberAccount.enrolmentDate = String('0' + new Date().toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }).replace(/ /ig, '-')).slice(-11);
                    changeKey('accountStatusCode', 'accountStatus', object.memberAccount);
                    changeKey('accountPeriodType', 'periodType', object.memberAccount);
                    changeKey('accountExpiryinMonths', 'period', object.memberAccount);
                    //Commented this to update the profile %
                    //deleteKeys(['memberPreferences', 'accrualSegment'], object.memberAccount);
                    // clean object.memberAccount.memberProfile
                    deleteKeys(['companyCode', 'membershipNumber', 'enrollmentSource', 'corporateInfo', 'enrollmentSourceCode', 'customerPassword'], object.memberAccount.memberProfile)
                    changeKey('membershipStatusCode', 'membershipStatus', object.memberAccount.memberProfile);
                    object.memberAccount.memberProfile.corporateInfo = null;
                }
                if (object.memberAccount.memberProfile && object.memberAccount.memberProfile.individualInfo) {
                    // update keys in object.memberAccount.memberProfile.individualInfo
                    if (!object.memberAccount.memberProfile.individualInfo.designation) {
                        object.memberAccount.memberProfile.individualInfo.designation = "";
                    }
                    // sort object.memberAccount.memberProfile.indivitualInfo.memberContactInfos
                    // const memberContactInfos = object.memberAccount.memberProfile.individualInfo.memberContactInfos.sort((a,b)=>String(a.addressType).localeCompare(b.addressType));
                    const homeAddress = object.memberAccount.memberProfile.individualInfo.memberContactInfos.find(ob => ob.addressType === 'H') || { addressType: 'H' };
                    const businessAddress = object.memberAccount.memberProfile.individualInfo.memberContactInfos.find(ob => ob.addressType === 'B') || { addressType: 'B' };
                    const memberContactInfos = [homeAddress, businessAddress];
                    deleteKeys(['memberContactInfos'], object.memberAccount.memberProfile.individualInfo)
                    object.memberAccount.memberProfile.individualInfo.memberContactInfos = memberContactInfos;
                    if(object.memberAccount.memberProfile.individualInfo.displayName){
                        object.memberAccount.memberProfile.individualInfo.preferredName =  object.memberAccount.memberProfile.individualInfo.displayName + " " +  object.memberAccount.memberProfile.individualInfo.familyName 
                        object.memberAccount.memberProfile.individualInfo.preferredFirstName =  object.memberAccount.memberProfile.individualInfo.displayName
                    }
                    else{
                        object.memberAccount.memberProfile.individualInfo.preferredFirstName =  object.memberAccount.memberProfile.individualInfo.givenName
                        object.memberAccount.memberProfile.individualInfo.preferredName =  object.memberAccount.memberProfile.individualInfo.givenName + " " +  object.memberAccount.memberProfile.individualInfo.familyName 
                    }
                }
                // console.log({object});
                dispatch({
                    type: ACTION_FETCH_PROFILEDATA_SUCCESS,
                    payload: { object }
                })
                if (object.areMandatoryFieldsFilled == false) {
                    redirectToPage(`${NAVIGATE_PROFILE_COMPLETION}`)
                }


            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchProfileData"))
                if (error && error.response && error.response.data && error.response.data.error) {
                    const payload = getApiErrorMessage(error.response.data.error).filter(ob => ob);
                    if (payload.length === 0) {
                        payload.push("Request failed")
                    }
                    dispatch({
                        type: ERROR,
                        payload: { error: payload }
                    })
                }
            })
    };
}

/**
 * @description API call for fetching profile data
 * @author Geo George
 */
export const fetchDefaultUserProfileData = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchDefaultUserProfileData"))
        return await doPost(_URL_FETCH_PROFILE_DETAILS, requestBody)
            .then(response => {
                dispatch(stopApiLoading("fetchDefaultUserProfileData"))
                dispatch({
                    type: ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS,
                    payload: response.data.object
                })
                if (response.data.object.areMandatoryFieldsFilled == false) {
                    redirectToPage(`${NAVIGATE_PROFILE_COMPLETION}`)
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchDefaultUserProfileData"))
                dispatch({
                    type: ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE,
                    payload: error
                })
            })
    };
}
/**
 * @description API call for fetching user/current login user data
 * @author Rohith
 */
export const fetchCurrentLoginUserData = (defaultConfig, currentUrl = undefined) => {  
    const headers = {
         secret : window.sessionStorage.getItem(SECRET),
         clientId : window.sessionStorage.getItem(CLIENT_ID),
         clientType : _DEFAULT_CLIENT_TYPE
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchCurrentLoginUserData"))
        return await doGet(_URL_SOCIAL_LOGGED_USER,headers)
            .then(response => {
                dispatch(stopApiLoading("fetchCurrentLoginUserData"))
                const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
                const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
                const defaultProgramCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE)
                let loginProgram = response.data.userDetails.programs[programType + "Info"];
                loginProgram = loginProgram ? loginProgram.find(lp =>
                    lp.companyCode == companyCode &&
                    lp.programCode == programCode
                ) : undefined
                if (defaultConfig) {
                    const defaultProgram = defaultConfig.programs.find(program => {
                        return program.isActive && program.isDefault
                    })
                    if (defaultProgram) {
                        let defaultLoginProgram = response.data.userDetails.programs[defaultProgram.programType + "Info"];
                        defaultLoginProgram = defaultLoginProgram ? defaultLoginProgram.find(lp =>
                            lp.companyCode == companyCode &&
                            lp.programCode == defaultProgramCode
                        ) : undefined
                        if (defaultLoginProgram) {
                            setItemToBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO, defaultLoginProgram.membershipNumber)
                            if (!loginProgram) loginProgram = defaultLoginProgram
                        }
                    }
                }
                if (loginProgram) {
                    const customerNumber = loginProgram.nominee ? loginProgram.nominee.customerNumber : loginProgram.customerNumber ? loginProgram.customerNumber : ""
                    const membershipNumber = loginProgram.membershipNumber
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_EMAIL, response.data.email)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO, membershipNumber)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO, customerNumber)
                    const privilegeMasterData = loginProgram.nominee ? loginProgram.nominee.privileges : loginProgram.privileges
                    if (privilegeMasterData && Object.keys(privilegeMasterData.ui).length > 0) {
                        dispatch({
                            type: ACTION_PRIVILEGES,
                            payload: privilegeMasterData.ui
                        })
                    }
                } else {
                    redirectToPage(NAVIGATE_403)
                    return
                }
                dispatch({
                    type: ACTION_FETCH_CURRENT_USER_SUCCESS,
                    payload: response.data
                })
                const { search } = window.location
                const queryParams = new URLSearchParams(search);
                const _programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                const currentUrl = getCurrentPageUri()
                const redirectURL = getItemFromBrowserStorage(BROWSER_STORAGE_REDIRECT_TO);
                if (redirectURL) {
                    removeItemFromBrowserStorage(BROWSER_STORAGE_REDIRECT_TO)
                    redirectPage(defaultConfig,redirectURL)
                } else if (queryParams.has(REDIRECT_TO)) {
                    redirectPage(defaultConfig,currentUrl)
                } else if  (currentUrl) {
                    // if(Object.keys(urlParams).length) {
                    const { search } = window.location
                    if (search) {
                        redirectToPage(currentUrl+search)
                    } else if (currentUrl != NAVIGATE_MEMBER_LOGIN && currentUrl != NAVIGATE_CALLBACK) {
                        redirectToPage(currentUrl)
                    } else {
                        redirectToPage(`${ROUTER_CONFIG[_programType].dashboardUrl}`)
                    }
                } else if (_programType === PROGRAM_TYPE_CORPORATE || 
                    _programType === PROGRAM_TYPE_INDIVIDUAL) {
                    redirectToPage(`${ROUTER_CONFIG[_programType].dashboardUrl}`)
                } else {
                    redirectToPage(`${NAVIGATE_404}`)
                }
                return response
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchCurrentLoginUserData"))
                dispatch({
                    type: ACTION_FETCH_CURRENT_USER_FAILURE,
                    payload: error
                })
                isApiResponseAuthorized(error)
            })
    };
}

/**
 * @function getQrCodeImage
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_FETCH_QRCODE_IMAGE</i>
 */
export const getQrCodeImage = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("getQrCodeImage"))
        return await doPost(_URL_FETCH_QRCODE_IMAGE, payload)
            .then((response) => {
                dispatch(stopApiLoading("getQrCodeImage"))
                dispatch({
                    type: FETCH_QRCODE_IMAGE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("getQrCodeImage"))
                dispatch({
                    type: FETCH_QRCODE_IMAGE_ERROR,
                    payload: error
                })
            })
    }
}

/**
 * @description API call for fetching account nominee
 * @author Rohith
 */
export const fetchAccountNominee = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
            // membershipNumber: "CC4962",
            // nomineeReferenceNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchAccountNominee"))
        return await doPost(_URL_RETRIEVE_NOMINEES, requestBody)
            .then(response => {
                dispatch(stopApiLoading("fetchAccountNominee"))
                dispatch({
                    type: FETCH_ACCOUNT_NOMINEE_SUCCESS,
                    payload: response.data
                })

            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchAccountNominee"))
                dispatch({
                    type: FETCH_ACCOUNT_NOMINEE_FAILURE,
                    payload: error
                })
                isApiResponseAuthorized(error)
                if (error && error.response && error.response.status == 500) {
                    redirectToPage(NAVIGATE_500)
                }
            })
    };
}

export const setPageReference = (pageReference) => {
    return dispatch => {
        dispatch({
            type: PAGE_REFERENCE,
            payload: pageReference
        })
    }
}



/**
 * Creates Action of type <i>CURRENT_BREADCRUMB</i> with given data
 *
 * @function setMenuReference
 * @param {string} menuReference name to be passed as current breadcrumb in Action
 * @returns Action of type <i>CURRENT_BREADCRUMB</i>
 *
 */

export const setMenuReference = (menuReference) => {
    return dispatch => {
        dispatch({
            type: CURRENT_MENU,
            payload: menuReference
        });
    };
};

/**
 * @description Action to set the MemberTier based on the Base and Sub Tier
 * @param {*} accountsummary 
 * @param {*} config 
 * @author Gowthaman A
 */
export const setTierDetails = (accountsummary, config) => {
    const response = memberTier(accountsummary, config)
    return dispatch => {
        dispatch({
            type: SET_TIER_DETAILS,
            payload: response
        });
    };
};


/**
 *
 * @param {*} params
 */
export const validateMemberDetails = (params) => {
    return async dispatch => {
        dispatch({
            type: SET_VALIDATE_MEMBER_DETAIL,
            payload: {}
        })
        dispatch(startApiLoading("validateMemberDetails"))
        return await doPost(_URL_VALIDATE_MEMBER_DETAIL, params)
            .then((response) => {
                dispatch(stopApiLoading("validateMemberDetails"))
                dispatch({
                    type: SET_VALIDATE_MEMBER_DETAIL,
                    payload: response.data.object
                })
            })
            .catch(error => {
                console.log("commonAction ", error.response.data);
                dispatch(stopApiLoading("validateMemberDetails"))
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
};

export const clearValidateResponse = () => {
    return async dispatch => {
        dispatch({
            type: SET_VALIDATE_MEMBER_DETAIL,
            payload: {}
        })
    }
}


/**
* Action call to add or delete nominee
* @param {JSON} payload Request payload to be dispatched
* @param {String} id
*
* @author Geo George
*/

export const addOrDeleteNominee = (payload, id = "", from = "") => {

    if (from != "") {
        payload.object.nomineeDetails[0].customerDynamicAttribute = payload.object.nomineeDetails[0].customerDynamicAttribute.filter(attr => {
            if(attr.attributeCode != '61') {
                return attr;
            }
        });
    }

    if (from == "adminDelete") {
        payload.object.nomineeDetails[0].customerDynamicAttribute.push(
            {
                "dynamicAttributeGroupName": "Program Status",
                "attributeCode": "61",
                "attributeValue": "SADM",
                "type": "C",
                "operationFlag": "U"
            }
        )
    } else if (from == "selfDelete") {
        payload.object.nomineeDetails[0].customerDynamicAttribute.push(
            {
                "dynamicAttributeGroupName": "Program Status",
                "attributeCode": "61",
                "attributeValue": "SSLF",
                "type": "C",
                "operationFlag": "U"
            }
        )
    }
    return async dispatch => {
        dispatch({
            type: ACTION_ADD_DELETE_NOMINEE,
            payload: {}
        })
        dispatch(startButtonSpinner(id, ADD_OR_DELETE))
        await doPost(_URL_ADD_DELETE_NOMINEE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, ADD_OR_DELETE))
                dispatch({
                    type: ACTION_ADD_DELETE_NOMINEE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, ADD_OR_DELETE))
                dispatch({
                    type: ACTION_ADD_DELETE_NOMINEE,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    }
//     return async dispatch => {
//             dispatch({
//                 type: ACTION_ADD_DELETE_NOMINEE,
//                 payload: {"statuscode":"200","statusMessage":"SUCCESS","object":{"membershipNumber":"8308173","activityNumber":"ACT170208","activityStatus":"P","activityCode":"AN"}}
//             })
// }
}

export const loadStyle = (value = false) => {
    return dispatch => {
        dispatch({
            type: CSS_LOADER,
            payload: value
        })
    }
}

/**
 * Action call to retrieve extend/renew expiry details.
 * @param {JSON} payload Request payload to be dispatched
 * @author Amrutha J Raj
 */

export const retrieveRenewOrExpiryPointDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("retrieveRenewOrExpiryPointDetails"))
        await doPost(_URL_RETRIEVE_RENEW_EXPIRY_POINT_DETAILS, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveRenewOrExpiryPointDetails"))
                dispatch({
                    type: RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveRenewOrExpiryPointDetails"))
            })
    };
}


export const setSelectedTab = (selectedTab="") => {
    return dispatch=>{
        dispatch({
            type: SET_SELECTED_TAB,
            payload: selectedTab
        })
    }
}

export const fetchToken = (defaultConfig, currentUrl = undefined) => {
    const headers= {
        accessKey : getItemFromBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY),
        cid : getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CID),
        clientType : _DEFAULT_CLIENT_TYPE
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchToken"))
        if (!(headers.accessKey || headers.cid)) {
            new Promise((resolve) => {
                dispatch(stopApiLoading("fetchToken"))
                let token = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN);
                let refreshToken = ""
                if (token) {
                    console.log("Set token in Token Reducer....")
                    dispatch({
                        type: FETCH_TOKEN,
                        payload: {
                            token,
                            refreshToken
                        }
                    })
                }
                resolve(1);
            });
        } else {
            await doGet(_URL_FETCH_TOKEN,headers)
            .then((response) => {
                    let responseHeader = response.headers
                    if (responseHeader) {
                        response.data["show"] = true
                        let token = responseHeader[_RESPONSE_HEADER_TOKEN]
                        let refreshToken = responseHeader[_RESPONSE_HEADER_REFRESH_TOKEN]
                        localStorage.setItem('token', token);
                        dispatch(stopApiLoading("fetchToken"))
                        dispatch({
                            type: FETCH_TOKEN,
                            payload: {
                                token,
                                refreshToken
                            }
                        })
                       dispatch(fetchCurrentLoginUserData(defaultConfig,currentUrl))
                    }
                else{
                    dispatch({
                        type: SET_LOGIN_DATA,
                        payload: {
                            success : false,
                            message : "Login failed"
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchToken"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
        }
    }
}

export const manageProfileAttribute = (payload, id) => {
    return async dispatch => {
        dispatch({
            type: ACTION_MANAGE_PROFILE_ATTRIBUTE,
            payload: {}
        })
        dispatch(startButtonSpinner(id, MANAGE_PROFILE_ATTRIBUTE))
        await doPost(_URL_MANAGE_PROFILE_ATTRIBUTE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, MANAGE_PROFILE_ATTRIBUTE))
                dispatch({
                    type: ACTION_MANAGE_PROFILE_ATTRIBUTE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, MANAGE_PROFILE_ATTRIBUTE))
                dispatch({
                    type: ACTION_MANAGE_PROFILE_ATTRIBUTE,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    }
}

export const clearAddorDeleteResponse = () => {
    return async dispatch => {
        dispatch({
            type: ACTION_ADD_DELETE_NOMINEE,
            payload: {}
        })
    }
}

export const getAvailableBalance = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("getAvailableBalance"))
        await doPost(_URL_GET_AVAILABLE_BALANCE, payload)
            .then((response) => {
                dispatch(stopApiLoading("getAvailableBalance"))
                dispatch({
                    type: ACTION_GET_AVAILABLE_BALANCE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("getAvailableBalance"))
                dispatch({
                    type: ACTION_GET_AVAILABLE_BALANCE,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    }
}

export const fetchNewToken = (id="") => {
    const state = store.getState()
    const { refreshToken } =  state.tokenReducer
    const headers= {
        accessKey : getItemFromBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY),
        cid : getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CID),
        clientType : _DEFAULT_CLIENT_TYPE,
        refreshToken 
    }
    return  async dispatch => {
    dispatch(startApiLoading("fetchNewToken"))
    if (!isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)) && !isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IDP_TOKEN))) {
        await doGet(_URL_FETCH_PS_TOKEN_WITH_ID_TOKEN, {"companyCode": "NZ"})
            .then(response => {
                dispatch(stopApiLoading("fetchNewToken"))
                let psToken = response.headers['ps-token'];
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_TOKEN, psToken);
                dispatch({
                    type: FETCH_TOKEN,
                    payload: {
                        token : response.headers['ps-token']
                    }
                })
                if(id == "businessActivation"){
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?inviteStatus=accepted`;
                }
                if(id == "selfDelete"){
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?deleteStatus=deleted`;
                }
                if(id == "selfUpdate"){
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?updateStatus=updated`;
                }
                dispatch(fetchCurrentLoginUserData())
            })
            .catch(error => {
                console.log("commonAction ", error.response.data);
                dispatch(stopApiLoading("fetchNewToken"))
            })
    } else {
        await  doGet(_URL_FETCH_NEW_TOKEN, headers)
            .then((response) => {
                dispatch(stopApiLoading("fetchNewToken"))
                dispatch({
                    type: FETCH_TOKEN,
                    payload: {
                        token : response.headers['ps-token'],
                        refreshToken
                    }
                })
                localStorage.setItem('token', response.headers['ps-token']);
                if(id == "businessActivation"){
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?inviteStatus=accepted`;
                }
                if(id == "selfDelete"){
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?deleteStatus=deleted`;
                }
                if(id == "selfUpdate"){
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?updateStatus=updated`;
                }
                dispatch(fetchCurrentLoginUserData())
            })
            .catch(error => {
                console.log("commonAction ", error.response.data);
                dispatch(stopApiLoading("fetchNewToken"))
                dispatch({
                    type: FETCH_NEW_TOKEN,
                    payload: error.response.data
                })
            })
        }
    }
}

/**
 * @function retrieveDigitalCardData
 * @returns Action of type <i>_URL_MEMBER_DIGITALCARD_SERVICE</i>
 */

export const retrieveOtherCardsData = () => {
    let payload = {
        "object": {
            "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            "membershipNumber": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("retrieveOtherCardsData"))
        return await doPost(_URL_MEMBER_OTHERCARDS_SERVICE, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveOtherCardsData"))
                if (response.status == 200) {
                    dispatch({
                        type: OTHER_CARDS_SERVICE,
                        payload :  response.data.object
                    })
                } else {
                    dispatch({
                        type: OTHER_CARDS_SERVICE,
                        payload: null
                    })
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveOtherCardsData"))
                dispatch({
                    type: OTHER_CARDS_SERVICE,
                    payload: {}
                })
            })
    };
};

export const adhocActivity = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "adhocActivity"))
        return await doPost(_URL_ADHOC_ACTIVITY, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "adhocActivity"))
                    dispatch({
                        type: ADHOC_ACTIVITY,
                        // payload: mock
                        payload :  response.data.object
                    })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "adhocActivity"))
                dispatch({
                    type: ADHOC_ACTIVITY,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    };
};


