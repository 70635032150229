/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { RENEW_POINT_PAYMENT_ERROR, RENEW_POINT_PAYMENT_SUCCESS, RETRIEVE_QUOTE_SUCCESS, RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_ERROR, RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS } from "./Constants";

/**
 * Reducer for the page extend expiry date/renew miles
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 * @author Amrutha J Raj
 */
export function extendExpiryReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS:
            return {
                ...state,
                retrievedRenewOrExpiryDetails: payload.object
            }
        case RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_ERROR:
            return {
                ...state,
                retrievedRenewOrExpiryDetails: []
            }
        default:
            return state;
    }

}

/**
 * Reducer for calling retrieve quote API
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 * @author Amrutha J Raj
 */
export function retrieveQuoteReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case RETRIEVE_QUOTE_SUCCESS:
            return {
                ...state,
                retrieveQuoteResponse: payload.object
            }
        default:
            return state;
    }

}

/**
 * Reducer for calling Renew Miles API
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 * @author Amrutha J Raj
 */
export function renewMilesPaymentReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case RENEW_POINT_PAYMENT_SUCCESS:
            return {
                ...state,
                renewMilesResponse: payload.object
            }
        default:
            return state;
    }

}