/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

export const TAB_MEMBER = "member"
export const TAB_NON_MEMBER = "nonMember"

export const VALIDATE_MEMBER = "validateMember"
export const ADD_MEMBER = "addMember"
export const ADD_NON_MEMBER = "addNonMember"


export const SUCCESS = "success"
export const DANGER = "danger"

export const ACTION_ADD_MEMBER_TRAVELLER = "ACTION_ADD_MEMBER_TRAVELLER"
export const ACTION_ADD_NON_MEMBER_TRAVELLER = "ACTION_ADD_NON_MEMBER_TRAVELLER"
export const ACTION_TRAVEL_COMPANION_DELETE = "ACTION_TRAVEL_COMPANION_DELETE"
export const ACTION_RETRIEVE_TRAVEL_COMPANION = "ACTION_RETRIEVE_TRAVEL_COMPANION"
export const RESPONSE_SUCCESS = "SUCCESS"

export const ADD_MEMBER_TRAVELLER = "addMemberTraveller"
export const ADD_NON_MEMBER_TRAVELLER = "addNonMemberTraveller"

export const ACCOUNT_STATUS = "nomineeStatus"
export const DELETED = "Deleted"
export const ACTIVE = "A"
