/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { doPost } from '../../../common/utils/api'
import { _URL_CHANGE_PSW_DETAILS } from '../../../common/config/config'
import { ERROR } from '../../../common/middleware/redux/commonAction';
import { getApiErrorMessage } from '../../../common/utils';
import { startButtonSpinner, stopButtonSpinner } from '../../../common/components/fieldbank/loader/action';

export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";

/**
 * Creates Action of type <i>_URL_CHANGE_PSW_DETAILS</i> with given data
 *
 * @function resetPassword
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_CHANGE_PSW_DETAILS</i>
 *
 */
export const changePassword = (payload, id) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "changePassword"))
    await doPost(_URL_CHANGE_PSW_DETAILS, payload).then((res) => {
      dispatch(stopButtonSpinner(id, "changePassword"))
      if (res.data) {
        dispatch({
          type: SET_CHANGE_PASSWORD,
          payload: res.data
        })
      }
    }).catch((error) => {
      dispatch(stopButtonSpinner(id, "changePassword"))
      dispatch({
        type: ERROR,
        payload: { error: getApiErrorMessage(error.response.data.error) }
      })
    })
  };
}

