/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { BROWSER_STORAGE_KEY_PROGRAM_CODE, getItemFromBrowserStorage, BROWSER_STORAGE_KEY_COMPANY_CODE, BROWSER_STORAGE_KEY_PROGRAM_TYPE } from "./storage.utils"

/**
 * Retrieves the current program based on
 * the programCode present in local storage
 * @param {object} defaultConfig 
 * @returns program which has the programCode from local storage
 * 
 * @author Ajmal V Aliyar
 */
export const getCurrentProgramFromDefaultConfig = (defaultConfig) => {
    if (defaultConfig && 
        defaultConfig[BROWSER_STORAGE_KEY_COMPANY_CODE] === getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)) {
        return defaultConfig.programs.find(e =>
            e[BROWSER_STORAGE_KEY_PROGRAM_CODE] == getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        )
    } else {
        console.error("getCurrentProgram: The received defaultConfig is empty")
    }
}

/**
 * Retrieves the program based on
 * the programType present in local storage
 * @param {object} defaultConfig 
 * @param {object} programType 
 * @returns program which has the programType from local storage
 * 
 * @author Rohith
 */
export const getCurrentProgramFromDefaultConfigByProgramType = (defaultConfig, programType) => {
    if (defaultConfig) {
        return defaultConfig.programs.find(e =>
            e[BROWSER_STORAGE_KEY_PROGRAM_TYPE] == programType
        )
    } else {
        console.error("getCurrentProgramFromDefaultConfigByProgramType: The received defaultConfig is empty")
    }
}

/**
 * Retrieves the current program based on the identifier
 * @param {object} defaultConfig 
 * @returns program which has the programCode from local storage
 * 
 * @author Rohith
 */
export const getProgramFromDefaultConfigByProgramIdentifier = (defaultConfig, programIdentifier) => {
    if (defaultConfig) {
        return defaultConfig.programs.find(e =>
            e.id == programIdentifier
        )
    } else {
        console.error("getProgramFromDefaultConfigByProgramIdentifier: The received defaultConfig is empty")
    }
}