/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { TAB_EARN_MILES, TAB_USE_MILES, TAB_UPGRADE_MILES } from './Constants';
import { CLEAR_MILEAGE_CALCULATOR } from './action';

export function mileageCalculatorReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === TAB_EARN_MILES) {
        const earnMiles = payload
        return {
            ...state,
            earnMiles
        }
    } else if(type === TAB_USE_MILES) {
        const useMiles = {...payload}       
        return {
            ...state,
            useMiles
        }
    } else if(type === TAB_UPGRADE_MILES) {
        const upgradeUsingMiles = payload
        return {
            ...state,
            upgradeUsingMiles
        }
    } else{
        return state
    }
}
