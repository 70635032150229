/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import checkPropTypes from 'check-prop-types';
import { applyMiddleware, createStore } from 'redux';
import { middlewares, store  } from '../middleware/store';
import rootReducer from '../middleware/reducers';
import React, { Suspense } from 'react';
import i18n from 'i18next';
import { DD_MM_YYYY, DD_MM_YYYY_HH_MM_SS, DD_MMM_YYYY, HH_MM, RTL } from './Constants';
import { clearBrowserStorage, BROWSER_STORAGE_TYPE_SESSION, BROWSER_STORAGE_KEY_TOKEN, setItemToBrowserStorage, getItemFromBrowserStorage, BROWSER_STORAGE_KEY_IS_HASH_ROUTING_ENABLED, BROWSER_STORAGE_KEY_ACCESS_KEY, BROWSER_STORAGE_KEY_CID, BROWSER_STORAGE_KEY_DATE_FORMAT } from './storage.utils';
import moment from 'moment'
import { getCurrentProgramFromDefaultConfig } from './configurationFiles.utils';
import { loadStyle } from '../middleware/redux/commonAction';
import { fetchCss, addStyleToElement } from './css.utils';
import { setLanguage } from '../../ui/header/actions'
import {  _URL_PARAMS_ACCESS_KEY, _URL_PARAMS_CID } from './urlParams.utils';
import history from "./history";
import { NAVIGATE_MEMBER_LOGIN } from './urlConstants';
import { _CONTEXT_PATH } from './../config/config';

export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const getDefaultFormattedDate = (arg) => {
    const date = new Date(arg)
    return date.getDate() + "-" + MONTHS[date.getMonth()] + "-" + date.getFullYear()
}

export const DISABLE_LOADING = {
    "loading": false
}
export const ENABLE_LOADING = {
    "loading": true
}

export const findByTestAtrr = (component, attr) => {
    const wrapper = component.find(`[data-test='${attr}']`);
    return wrapper;
};

export const checkProps = (component, expectedProps) => {
    const propsErr = checkPropTypes(component.propTypes, expectedProps, 'props', component.name);
    return propsErr;
};

export const testStore = (initialState) => {
    const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
    return createStoreWithMiddleware(rootReducer, initialState);
};

export const withSuspense = () => ((LoylatyMemberPortalComponent) => (
    class HOC extends React.Component {
        render() {
            return (
                <Suspense fallback="">
                    <LoylatyMemberPortalComponent {...this.props} />
                </Suspense>
            )
        }
    }
))


/**
 * Method to load the bootstrap, main and main-override styles.
 * This method is invoked when user changes the language, selecting from the dropdown list
 * @author Somdas M
 * @param {*} lang The updated language object
 * @param {*} dispatch To handle the loading spinner (page load)
 */
export const reloadCss = (lang, dispatch) => {
    var bootstrapEl = document.getElementById("bootstrap-style")
    var mainStyleEl = document.getElementById("main-style")
    var mainStyleOverrideEl = document.getElementById("main-style-override")
    dispatch(loadStyle(false))
    document.documentElement.setAttribute('lang', lang.code);
    if(lang.bodyClass) {
        if(!isEmptyOrSpaces(lang.bodyClass.remove)) document.body.classList.remove(lang.bodyClass.remove)
        if(!isEmptyOrSpaces(lang.bodyClass.add)) document.body.classList.add(lang.bodyClass.add);
    } 
    fetchCss(lang.bootstrap_css_href, bootstrapEl).then(bootstrap_css_data => {
        fetchCss(lang.css_href, mainStyleEl).then(css_data => {
            if(!isEmptyOrSpaces(lang.overide_css_href)) {
                fetchCss(lang.overide_css_href, mainStyleOverrideEl).then(overide_css_data => {
                    dispatch(loadStyle(true))
                })
            } else {
                addStyleToElement("", mainStyleOverrideEl)
                dispatch(loadStyle(true))
            }
        })
    })

    if (lang && lang.code) {
        i18n.changeLanguage(lang.code);
        document.body.dir = i18n.dir(lang.code);
        return;
    }
    i18n.changeLanguage('en');
}


export const isEmptyOrSpaces = (str) => {
    return str === null || str === "null" || str === undefined || str === "undefined" || str.match(/^ *$/) !== null;
}

/**
 * Return true if the user is logged in
 * @author Vinu S
 */
export const isLoggedIn = () => {
    return !isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN));
}

export const getApiErrorMessage = (error) => {
    let errorMessages = []
    if (error) {
        if (error.errorDetails && error.errorDetails.length > 0) {
            error.errorDetails.map((errorDetail) => {
                errorMessages.push(errorDetail.message)
            })
        }
        else if (error.error && error.error.errorDetails) {
            error.error.errorDetails.map((errorDetail) => {
                errorMessages.push(errorDetail.message)
            })
        } else if (error.message) {
            errorMessages.push(error.message)
        } else if (error.error && !isEmptyOrSpaces(error.error.message)) {
            errorMessages.push(error.error.message)
        } else if(isEmptyOrSpaces(error.message) && error.error){
            errorMessages.push(error.error)
        } else {
            errorMessages.push(error)
        }
    }
    return errorMessages
}

export const numberWithCommas = (x,parse=true) => {
    return x ?
        parse == true ? parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        :
        "0";
}

export const numberWithCommas1 = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
}
export const toTitleCase = (phrase) => {
    try {
        if (phrase) {
            return phrase
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        } else {
            return ""
        }
    } catch (err) {
        console.log(err)
        return phrase
    }
};

export const getDaysFromToday = (date) => {
    var Difference_In_Time = new Date().getTime() - new Date(date).getTime();
    // To calculate the no. of days between two dates 
    return parseInt(Difference_In_Time / (1000 * 3600 * 24));
}

export const getExpiryText = (expiryDetails, type, expiryMessage, expiryIn = -1) => {
    const _expiryIn = expiryIn == -1 ? 365 : expiryIn
    if (expiryDetails && expiryDetails.length > 0) {
        let sortedList = expiryDetails.filter(row => row.pointType === type).sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.expiryDate) - new Date(b.expiryDate);
        });
        if (sortedList.length > 0) {
            const ex = sortedList[0]
            const numberOfDays = getDaysFromToday(ex.expiryDate);
            if (numberOfDays > _expiryIn && numberOfDays < 0) {
                // 1,000 miles expiring in 25 days
                if (expiryMessage) {
                    return expiryMessage.replace("{POINTS}", numberWithCommas(parseInt(ex.points))).replace("{DAYS}", (numberOfDays * -1))
                } else {
                    return `${numberWithCommas(parseInt(ex.points))} miles expiring in ${(numberOfDays * -1)} days`
                }
            }
        }
    }
    return ""
}

export const setFieldInvalid = (id) => {
    if (id && document.getElementById(id))
        document.getElementById(id).className = document.getElementById(id).className + " is-invalid"
}

export const setFieldValid = (id) => {
    if (id && document.getElementById(id))
        document.getElementById(id).classList.remove("is-invalid")
}

export const queryStringParse = (string) => {
    let parsed = {}
    if (string != '') {
        string = string.substring(string.indexOf('?') + 1)
        let p1 = string.split('&')
        p1.map((value) => {
            let params = value.split('=')
            parsed[params[0]] = params[1]
        });
    }
    return parsed
}

// export const getTransactionFee = (config, selectedPoint) => {
//     if (config.txnPerUnit) {
//         const fees = selectedPoint * config.txnFeeAmount
//         const vat = fees * config.vat
//         return (fees + vat)
//     }
//     return config.txnFeeAmount + (config.vat * config.txnFeeAmount);
// }

/**
 * Added the 3rd Paramter to return 0 if the payment is not required
 * @param {*} config 
 * @param {*} pointsToTransfer 
 * @param {*} isPaymentRequired 
 * @author Gowthaman A
 */
export const getTransactionFee = (config, pointsToTransfer, isPaymentRequired = true) => {
    if (!isPaymentRequired || pointsToTransfer == undefined || pointsToTransfer == "") {
        return 0
    }
    if (config.perUnit) {
        const fees = pointsToTransfer * config.amount
        const vat = fees * config.vat
        return (fees + vat + config.serviceCharge)
    }
    return config.amount + (config.vat * config.amount) + config.serviceCharge;
}

export const toUpperCase = (value) => {
    return value.toUpperCase()
}


//---------------------------------Date utility---------------------------//

/**
 * Attach the provided time to provided date
 * @param {string} dateString 
 * @param {number} HH Hours
 * @param {number} mm Minutes
 * @param {number} ss Seconds
 * @param {number} sss Milliseconds
 * @returns Date with provided time
 * @author Ajmal Aliyar
 */

export const setTimeForDate = (dateString, HH = 0, mm = 0, ss = 0, format = DD_MM_YYYY_HH_MM_SS, inputDateFormat = DD_MM_YYYY_HH_MM_SS) => {
    return moment(moment(dateString, inputDateFormat).format(DD_MMM_YYYY), DD_MMM_YYYY).add(HH, 'hours').add(mm, 'minutes').add(ss, 'seconds').format(format)
}

export const getDiffByTwoDate = (fromDate, toDate, fromDateFormat = DD_MMM_YYYY, toDateFormat = DD_MMM_YYYY, measurement = "days") => {
    return moment(fromDate, fromDateFormat).diff(moment(toDate, toDateFormat), measurement);
}

/**
* Returns current date
* @returns {string} Current date in the format DD-Mon-YYYY
* @author Ajmal Aliyar
*/

export const getCurrentDate = (format = DD_MM_YYYY) => {
    return moment().format(format);
}

/**
* Convert date to desired format, say from dd/mm/yyyy to DD-Mon-YYYY
* @returns {string} Date in the desired format 
* @author Amrutha
*/
export const getDateFormatted = (date, FromFormat, ToFormat) => {
    return moment(date, FromFormat).format(ToFormat)
}


/**
* Subtract months from the current date
* @returns {string} Last date in the desired after subtracting the duration
* @author Amrutha
*/

export const getDateFromDuration = (durationInMonths, desiredFormat) => {
    return moment().subtract(durationInMonths, 'months').format(desiredFormat);

}

/**
* Relative date is [dateString] + [offsetDays]
* @param {string} dateString
* @param {number} offsetDays Integer[-n, n]
* @returns {string} Relative date
* @author Ajmal Aliyar
*/

export const getRelativeDate = (dateString = getCurrentDate(), offsetDays = 0, outputDateFormat = DD_MM_YYYY, inputDateFormat = undefined) => {

    if (offsetDays < 0) {
        if (inputDateFormat) {
            return moment(dateString, inputDateFormat).subtract(offsetDays * -1, 'days').format(outputDateFormat)
        } else {
            return moment(dateString).subtract(offsetDays * -1, 'days').format(outputDateFormat)
        }
    }
    if (inputDateFormat) {
        return moment(dateString, inputDateFormat).add(offsetDays, 'days').format(outputDateFormat)
    } else {
        return moment(dateString).add(offsetDays, 'days').format(outputDateFormat)
    }
}

/**
 * Returns date with offsetMonths added to it.
 * @param {string} dateString 
 * @param {number} offsetMonths Integer [-n, n]
 */
export const getDateWithRelativeMonth = (dateString = getCurrentDate(), offsetMonths = 0, outputDateFormat = DD_MMM_YYYY, inputDateFormat = undefined) => {
    if (offsetMonths < 0) {
        if (inputDateFormat) {
            return moment(dateString, inputDateFormat).subtract(offsetMonths * -1, 'months').format(outputDateFormat)
        } else {
            return moment(dateString).subtract(offsetMonths * -1, 'months').format(outputDateFormat)
        }
    }
    if (inputDateFormat) {
        return moment(dateString, inputDateFormat).add(offsetMonths, 'months').format(outputDateFormat)
    } else {
        return moment(dateString).add(offsetMonths, 'months').format(outputDateFormat)
    }
}

/**
 * Returns date,time in the specified format
 * @param {stirng} format the ouput date format
 * @param {string} date the string date
 * @author Somdas M
 */
export const getFormattedDate = (date, outputFormat = DD_MM_YYYY, inputFormat = undefined) => {
    // switch(format){
    //     case YYYY_MM_DD_HH_MM_SS: return formatDate_DD_Mon_YYYY_HH_mm_ss(date)
    //     case YYYY_MM_DD_HH_MM: return formatDate_DD_Mon_YYYY_HH_mm(date)
    //     case YYYY_MM_DD: return getNumericReversedDate(formatDate_DD_Mon_YYYY(date))
    //     case DD_MM_YYYY: return formatDate_DD_Mon_YYYY(date)
    //     default: return date
    // }
    if (inputFormat) {
        return moment(date, inputFormat).format(outputFormat)
    }
    return moment(date).format(outputFormat)

}
export const getLocalDateFormat = (date, inputFormat, outputFormat = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT)) => {
    return moment(date, inputFormat).format(outputFormat)
}
export const getLocalDateInCalenderFormat = (date, inputFormat) => {
    return moment(date, inputFormat).calendar()
}

/**
 * Returns date,time in the specified format
 * @param {stirng} Date Time to format
 * @param {string} Desired format
 * @author Amrutha
 */
export const formatTime = (dateTimeToFormat, format = HH_MM) => {
    return moment(dateTimeToFormat).format(format)
}

//---------------------------------Date utility---------------------------//

/**
 * Returns the list of points based on the minimum, maximum and packetSize
 * @param {object} pointDetails 
 * @author Somdas M
 */
export const getPointsArray = (pointDetails) => {
    let pointsArray = []
    if (pointDetails && Object.keys(pointDetails).length > 0) {
        const { packetSize, minimumPacket, maximumPacket } = pointDetails
        for (let index = minimumPacket; index <= maximumPacket; index++) {
            pointsArray.push(index * packetSize)
        }
    }
    return pointsArray
}

/**
 * Returns if the value matches the Regex pattern
 * @param {string} pattern
 * @param {string} value
 * @author Somdas M
 */
export const isPatternMatch = (pattern, value) => {
    if (isEmptyOrSpaces(pattern)) return false
    value = value ? value : ""
    const regex = new RegExp(pattern)
    return regex.test(value)
}

/**
 * Returns if a string value is null
 * @param {string} value
 * @author Somdas M
 */
export const isNull = (value) => {
    return (value == undefined || value == null || value.trim().length == 0)
}

/**
 * Method to check if the api responds with statusCode 401 (unauthorized)
 * @author Somdas M
 * @param {object} error 
 */
export const isApiResponseAuthorized = (error) => {
    if (error && error.response &&
        error.response.data &&
        error.response.status == 401
        // && 
        // !error.response.data.message.includes("Token expired")
    ) {
        clearStorage()
    } else if (error && error.response && error.response.status == 500) {
        // window.location.href = `#${NAVIGATE_500}`
    }
}

/**
 * Method to clear the storage
 */
export const clearStorage = () => {
    clearBrowserStorage()
    clearBrowserStorage(BROWSER_STORAGE_TYPE_SESSION)
    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_MEMBER_LOGIN}`;
    // window.location.reload()
}

/**
 * Method to populate the values to json object
 * @author Somdas M
 * @param {string} pList The JSON path 
 * @param {string} value The value to be populated
 * @param {object} schema The requestBody
 */
export const constructJsonPath = (pList, value, schema) => {
    if (pList && !isEmptyOrSpaces(pList)) {
        pList = pList.split('.')
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!isNaN(elem)) {
                elem = parseInt(elem)
                if (pList.length > (i + 1)) {
                    for (let k = 0; k <= elem; k++) {
                        if (!schema[k]) {
                            schema.push({})
                        }
                    }
                    schema = schema[elem]
                }
            } else {
                if (!schema[elem]) {
                    if (pList.length > (i + 1) && !isNaN(pList[i + 1])) {
                        schema[elem] = []
                    } else {
                        schema[elem] = {}
                    }
                }
                schema = schema[elem];
            }
        }
        schema[pList[len - 1]] = value;
    }
}

/**
 * Method to create the requestBody based on the path
 * @author Somdas M
 * @param {object} config The section configuration
 */
export const generateRequestBodyWithEmptyValue = (config, defaultValue = "") => {
    const requestBody = {}
    if (config && config.ui && config.ui.layout && config.ui.layout.order && config.ui.layout.elements) {
        config.ui.layout.order.forEach((order) => {
            const elements = config.ui.layout.elements[order]
            if (elements && elements.fields) {
                elements.fields.forEach((field) => {
                    if (field.visibility) {
                        if (field.additional) {
                            field.additional.forEach((additionalField) => {
                                if (additionalField.path && additionalField.path.request) {
                                    constructJsonPath(additionalField.path.request, defaultValue, requestBody)
                                }
                            })
                        }
                        if (field.path && field.path.request) {
                            constructJsonPath(field.path.request, defaultValue, requestBody)
                        }
                    }
                })
            }

        })
    }
    if (config && config.dynamicAttributes && config.ui && config.ui.request && config.ui.request.dynamicAttributesPath) {
        let finalDynamicAttributes = []
        Object.keys(config.dynamicAttributes).forEach(key => {
            const dynamicAttributes = JSON.parse(JSON.stringify(config.dynamicAttributes[key]))
            const sectionConfig = config[key]
            if (sectionConfig && sectionConfig.count) {
                for (let index = 1; index <= sectionConfig.count; index++) {
                    const da = JSON.parse(JSON.stringify(dynamicAttributes))
                    if (sectionConfig.overrideGroupInstanceID == undefined ||
                        sectionConfig.overrideGroupInstanceID == true) {
                        da.map(attr => {
                            attr["groupInstanceID"] = index.toString()
                            return da
                        })
                    }
                    finalDynamicAttributes = finalDynamicAttributes.concat(da)
                }
            } else {
                finalDynamicAttributes = finalDynamicAttributes.concat(dynamicAttributes)
            }
        })
        if (finalDynamicAttributes.length) {
            constructJsonPath(config.ui.request.dynamicAttributesPath, finalDynamicAttributes, requestBody)
        }
    }
    return requestBody
}

/**
 * Method to get the switching program list for current logged in user
 * @param {*} programs 
 * @param {*} defaultPrograms 
 * @param {*} currentUserData 
 * @param {*} programCode 
 */
export const getSwitchProgramList = (programs, defaultPrograms, currentUserData, programCode) => {
    const switchProgramList = []

    const programsList = [...programs.corporateInfo, ...programs.individualInfo]
    programsList.forEach(p => {
        if (p.programCode != programCode) {
            const dp = defaultPrograms.find(dp => dp.programCode == p.programCode && dp.isActive == true)
            if (dp) {
                switchProgramList.push({
                    programCode: dp.programCode,
                    companyCode: p.companyCode,
                    programName: dp.programName,
                    programType: dp.programType,
                    membershipNumber: p.membershipNumber,
                    email: currentUserData.email,
                    customerNo: p.nominee ? p.nominee.customerNumber : p.customerNumber ? p.customerNumber : ""
                })
            }
        }
    })
    return switchProgramList
}

/**
 * Method to get the current page URI
 */
export const getCurrentPageUri = () => {
    const { pathname } = window.location
    return pathname.replace(_CONTEXT_PATH, "")
    // return window.location.href.includes("#") ? window.location.href.split("#")[1].split("?")[0] : ""
}


export const getTotalPointsByPointTypeList = (accountSummary, pointTypeList) => {
    let point = 0
    if (accountSummary && accountSummary.pointDetails && pointTypeList && pointTypeList.length > 0) {
        accountSummary.pointDetails.map((pointData) => {
            if (pointTypeList.includes(pointData.pointType)) point += pointData.points
        })
    }
    return point;
}

/**
 * Method to get the precision to round currency
 * @param {*} defaultConfig The default configuration
 * @author Somdas M
 */
export const getPrecision = (defaultConfig) => {
    if (defaultConfig && defaultConfig.programs && defaultConfig.programs.length) {
        const program = getCurrentProgramFromDefaultConfig(defaultConfig)
        if (program && program.defaults) {
            return program.defaults.roundCurrencyToDecimalPrecision
        }
    }
    return 0
}

/**
 * Method to set items to the local/session storage based on the configuration specified in routing.js
 * @param {*} browserStorage The list containing the storage type, key and value
 * @returns {boolean} whether the storageItem was changed
 * @author Somdas M
 */
export const setItemsToBrowserStorageFromConfig = (browserStorage) => {
    let hasStorageItemChanged = false
    if (browserStorage) {
        browserStorage.forEach(storageItem => {
            const { setItems, type } = storageItem
            if (setItems && setItems.length) {
                hasStorageItemChanged = true
                setItems.forEach(item => {
                    setItemToBrowserStorage(item.key, item.value, type)
                })
            }
        })

    }
    return hasStorageItemChanged
}


export const getLink = (link, canAddToken = false) => {
    const accessKey = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY)
    const cid = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CID)
    let _link = link//(link && link.startsWith("/")) ? '#'.concat(link) : link
    if(canAddToken && !_link.includes(_URL_PARAMS_ACCESS_KEY)) {
        _link = _link.concat(`${_link.includes("?")?'&':'?'}${_URL_PARAMS_ACCESS_KEY}=${accessKey}`)
    } 
    if(canAddToken && !_link.includes(_URL_PARAMS_CID)) {
        _link = _link.concat(`${_link.includes("?")?'&':'?'}${_URL_PARAMS_CID}=${cid}`)
    }
    return _link
}


/**
 * Method to convert the pointDetails array to an array of object {label: "", value:""} format, 
 * as an input for the SearchDropDown component used in transfer/buy miles.
 * @param {Array} pointsList The list containing points
 * @returns {Array} pointList array in {label: "", value:""} format
 * @author Gowthaman A
 */

export const getTransferPointsList = (pointsList) => {
    let transferPointsList = [];
    pointsList.map(points => {
        transferPointsList.push({
            label: points.toString(),
            value: points.toString()
        });
    })
    return transferPointsList
}

export const defaultSearchDropDownTemplate = (obj) => {
    return `${obj.label}`
}

export const getFamilyDisplayName = (familyConfig, familyMemberDetails) => {
    let displayName = "";
    familyConfig && familyConfig.ui && familyConfig.ui.displayNameView.map(name => {
        displayName += (familyMemberDetails && familyMemberDetails[name] ? familyMemberDetails[name] : "") + " ";
    })
    return displayName
}

/**
 * Method to arrange the optionDetails as per the order specified in the overview configuration.
 * @param {Array} orders The order list from overview configuration
 * @param {Array} optionDetails The optionDetails from accountSummary
 * @author Somdas M
 */
export const getOptionsOrder = (orders, optionDetails) => {
    let finalOptions = []
    if(orders && orders.length){
      orders.map(order=>{
        const currentOption = optionDetails.find(e=>e.name==order)
        if(currentOption && Object.keys(currentOption).length){
          finalOptions.push(currentOption)
        }
      })
    } else {
      finalOptions = optionDetails
    }
    return finalOptions
  }

  /**
   * Method to redirect to the url based on the Routing type
   * @param {*} page The page uri 
   * @author Somdas M
   */
   export const redirectToPage = (page,isExternal = !page.startsWith("/")) => {
    let _page = page 
    const accessKey = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_ACCESS_KEY)
    const cid = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CID)
    if(_page.includes("{accessKey}")&& !isEmptyOrSpaces(accessKey)){
        _page = _page.replace("{accessKey}",encodeURIComponent(accessKey))
    }
    if(_page.includes("{cid}")&& !isEmptyOrSpaces(cid)){
        _page = _page.replace("{cid}",encodeURIComponent(cid))
    }
    if(isExternal){
        window.location = _page
    }
    else{
        const isHashRoutingEnabled = eval(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_HASH_ROUTING_ENABLED))
        if(isHashRoutingEnabled){
            window.location.hash = _page
            return
          // history.push(`#${page}`)
        } else {
            _page = _page.split(_CONTEXT_PATH).length > 1 ? _page.split(_CONTEXT_PATH)[1] : _page.split(_CONTEXT_PATH)[0]
          history.push(`${_page}`)
          return
        }
    }
}

export const decryptConfig = (encriptedData, encr) => {
    var CryptoJS = require("crypto-js");
    if(encr.enabled){
    var key = CryptoJS.PBKDF2(encr.p_key, encr.p_key.toString('binary'), { keySize: encr.k_size, iterations: encr.iter, hasher: CryptoJS.algo.SHA1 });
    var encriptedDataBase64 = CryptoJS.enc.Base64.parse(encriptedData);
    var encriptedDataBase64String = CryptoJS.enc.Base64.stringify(encriptedDataBase64);
    const result = CryptoJS.AES.decrypt(
        encriptedDataBase64String,
        key,
        {
            mode: CryptoJS.mode.ECB
        }
    )
    return JSON.parse(result.toString(CryptoJS.enc.Utf8))
    } else{
        return encriptedData
    }
}