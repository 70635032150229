/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React, { Component } from 'react';
import MfaContext from '../../../../context/MfaContext';
import { withTranslation } from 'react-i18next';
import { withSuspense } from '../../utils';
import { connect } from 'react-redux';
import { createSecurityToken, validateToken } from './action'
import { getItemFromBrowserStorage, BROWSER_STORAGE_KEY_COMPANY_CODE, BROWSER_STORAGE_KEY_PROGRAM_CODE, BROWSER_STORAGE_KEY_MEMBERSHIP_NO } from '../../utils/storage.utils';
import { NAVIGATE_FEEDBACK } from '../../utils/urlConstants';
import CustomMessage from '../custommessage';
import { CONFIG_SECTION_DEFAULT } from '../../utils/Constants';
import { fetchConfiguration } from '../../middleware/redux/commonAction';
import { Knob } from 'primereact/knob';
import { Link } from 'react-router-dom';

/**
 * @name MultiFactorAuthentication MultiFactorAuthentication component.
 * @description Component that is shown when a server error 'MFA_REQUIRED' occurs.
 * @author Somdas M
 */
class MultiFactorAuthentication extends Component {

    constructor (props) {
        super(props)
        this.state = {
            isTokenGenerated: false,
            token: "",
            object: {
                companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
                programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
                membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
                partnerCode: "" //fixMe: to be handled dynamic
            },
            responseMsgType: "",
            responseMsg: "",
            maxAttempts: 1,
            resendTimeoutInSeconds: 0,
            timer: 0,
            showRemainingAttempts: false
        }
        this.verify = this.verify.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (this.props.defaultConfig && this.props.defaultConfig.data && this.props.defaultConfig.data.mfa) {
            const { maxAttempts, resendTimeoutInSeconds } = this.props.defaultConfig.data.mfa
            const { object } = this.state
            object["partnerCode"] = this.props.defaultConfig.data.mfa.partnerCode ? this.props.defaultConfig.data.mfa.partnerCode : ""
            this.setState({
                maxAttempts,
                resendTimeoutInSeconds,
                object,
                timer: resendTimeoutInSeconds
            })
            this.createSecurityToken()
        }
    }

    /**
     * Function to invoke the api to create a security token
     * @author Somdas M
     */
    createSecurityToken = () => {
        this.clearResponseMessages()
        const { object } = this.state
        this.props.createSecurityToken({ object })
        this.startCountDown()
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.token != this.props.token) {
            this.setState({
                isTokenGenerated: true
            })
        }

        if (prevProps.response != this.props.response && this.props.response) {
            const { type, msg } = this.props.response
            this.setState({
                responseMsgType: type,
                responseMsg: msg
            })
        }

        if (prevProps.validationStatus != this.props.validationStatus && this.props.validationStatus) {
            const { invokeCallback, closeMfaTab } = this.context;
            closeMfaTab()
            invokeCallback()
        }

        if (prevProps.defaultConfig != this.props.defaultConfig && this.props.defaultConfig && this.props.defaultConfig.data && this.props.defaultConfig.data.mfa) {
            const { maxAttempts, resendTimeoutInSeconds } = this.props.defaultConfig.data.mfa
            const { object } = this.state
            object["partnerCode"] = this.props.defaultConfig.data.mfa.partnerCode ? this.props.defaultConfig.data.mfa.partnerCode : ""
            this.setState({
                maxAttempts,
                resendTimeoutInSeconds,
                object,
                timer: resendTimeoutInSeconds
            })
            this.createSecurityToken()
        }
    }

    /**
     * Function to validate the input token provided by the user by invoking the api.
     * @author Somdas M
     */
    verify = () => {
        this.clearResponseMessages()
        const { object, token } = this.state
        object["token"] = token
        this.props.validateToken({ object })
    }

    /**
     * Function to replace the characters of a string with 'x' till the specified length
     * @author Somdas M
     * @param {string} string - The string to be replaced
     * @param {number} length - The number of characters to be replaced
     */
    hideData = (string, length) => {
        return string.map((value, idx) => {
            if (idx < string.length - length)
                return string[idx] = "x";
            return string[idx]
        }).join("");
    }

    /**
     * Function to update the state variable
     * @author Somdas M
     * @param {string} field - The fieldname as described in the state
     * @param {string} value - The new value
     */
    updateState = (field, value) => {
        this.setState({
            [field]: value
        })
    }

    /**
     * Function to resend the token
     * @author Somdas M
     */
    resendToken = () => {
        const { maxAttempts, resendTimeoutInSeconds } = this.state
        if (maxAttempts >= 0) {
            this.setState({
                maxAttempts: maxAttempts - 1,
                showRemainingAttempts: true,
                timer: resendTimeoutInSeconds
            })
            this.createSecurityToken()
        }
    }

    /**
     * Function to clear the success/error message displayed
     * @author Somdas M
     */
    clearResponseMessages = () => {
        this.setState({
            responseMsgType: "",
            responseMsg: ""
        })
    }

    /**
     * Function to start the resend countdown.
     * @author Somdas M
     */
    startCountDown = () => {
        let countDown = setInterval(() => {
            const { timer } = this.state
            this.setState({
                timer: timer == 0 ? 0 : timer - 1
            }, () => {
                if (this.state.timer == 0) {
                    clearInterval(countDown)
                }
            })
        }, 1000)
    }

    componentWillUnmount() {
        const { isOpen, closeMfaTab } = this.context
        if (isOpen) {
            closeMfaTab()
        }
    }

    render() {
        const { t, profileData, defaultConfig } = this.props
        const { token, isTokenGenerated, responseMsgType, responseMsg, maxAttempts, resendTimeoutInSeconds, showRemainingAttempts, timer } = this.state
        const { closeMfaTab } = this.context
        // const { phone, email } = currentUserData
        let phoneNumber = "", emailAddress = ""
        if (profileData &&
            profileData.object &&
            profileData.object.memberAccount && 
            profileData.object.memberAccount.memberProfile &&
            profileData.object.memberAccount.memberProfile.individualInfo &&
            profileData.object.memberAccount.memberProfile.individualInfo.preferredEmailAddress &&
            profileData.object.memberAccount.memberProfile.individualInfo.preferredPhoneNumber &&
            profileData.object.memberAccount.memberProfile.individualInfo.memberContactInfos) {

            const { preferredEmailAddress, preferredPhoneNumber, memberContactInfos } = profileData.object.memberAccount.memberProfile.individualInfo
            phoneNumber = memberContactInfos.find(e => e.addressType == preferredPhoneNumber.split("")[0])?.phoneNumber
            emailAddress = memberContactInfos.find(e => e.addressType == preferredEmailAddress)?.emailAddress

        }

        return (
            <>
                <div className={`col-lg-12`}>
                    <div className="row justify-content-md-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                            <p>{t('mfa.verification_required_for_this_transaction')}</p>
                            <CustomMessage message={[responseMsg]} type={responseMsgType} canTranslate={true} />
                            <h1>{t('mfa.enter_security_code')}</h1>
                            <div className="mb-3">
                                <label htmlFor="inputPassword5" className="form-label">{t('mfa.please_type_in')} <span>{phoneNumber && this.hideData(phoneNumber.split(""), phoneNumber.length - 3)}</span> / {t('mfa.email_id')} <span>{emailAddress && this.hideData(emailAddress.split(""), emailAddress.split("@")[1].length + 1)}</span></label>
                                <input type="text" id="inputPassword5" className="" value={token} onChange={(e) => this.updateState("token", e.target.value)} disabled={maxAttempts < 0} />
                            </div>
                            <div className="mb-3 align-items-center row">
                                <div className="col-lg-6">
                                    {timer == 0 && maxAttempts > 0 && <a className={"disabled"} onClick={() => this.resendToken()} role="button">{t('mfa.resend_code')}</a>}
                                    {showRemainingAttempts && timer == 0 && <div className="">
                                        {/* <div className="text-danger">{t('mfa.code_resend')}! <span>(30)</span> */}
                                        <div className="text-danger">{maxAttempts > 0 ? `${maxAttempts} ${t('mfa.attempts_pending')}` : t('mfa.no_attempt_pending')} {}</div>
                                        {/* </div> */}
                                    </div>}
                                    {timer > 0 && maxAttempts >= 0 && <span><Knob value={timer} min={0} max={resendTimeoutInSeconds} /></span>}
                                </div>
                                <div className="col-lg-6 col-md-12 text-right btn-wrap btn-wrap--grp">
                                    <button className="btn btn-outline-primary" type="button" onClick={() => closeMfaTab()}>{t('mfa.cancel')}</button>
                                    <button className={`btn btn-primary ${isTokenGenerated && token.length > 0 ? "" : "disabled"} ${maxAttempts >= 0 ? "" : "disabled"}`} type="button" onClick={this.verify}>{t('mfa.verify')}</button>
                                </div>
                            </div>
                            <div className="mb-3">
                                {t('mfa.having_issue')} <Link to={`${NAVIGATE_FEEDBACK}`}>{t('mfa.click_here')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
MultiFactorAuthentication.contextType = MfaContext

const mapStateToProps = state => {
    return {
        profileData: state.profileDataReducer.profileData,
        token: state.mfaReducer.token,
        validationStatus: state.mfaReducer.validate,
        response: state.mfaReducer.response,
        defaultConfig: state.configurationReducer[CONFIG_SECTION_DEFAULT]
    }
}

const mapDispatchToProps = {
    createSecurityToken,
    validateToken
}
export default withSuspense()(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MultiFactorAuthentication)));