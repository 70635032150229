/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
    doPost
} from '../../common/utils/api'
import {
    _URL_SUBSCRIBE,
    _URL_UNSUBSCRIBE,

} from '../../common/config/config'
import {
    getApiErrorMessage
} from '../../common/utils'
import {
    startButtonSpinner,
    stopButtonSpinner,
    startApiLoading,
    stopApiLoading
} from '../../common/components/fieldbank/loader/action';
import { DUPLICATE_SUBSCRIPTION_MESSAGE } from './Constants';

// Action Type
export const SUBSCRIBE_DATA = "SUBSCRIBE_DATA";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";
export const UNSUBSCRIBE_DATA = "UNSUBSCRIBE_DATA";
export const UNSUBSCRIBE_ERROR = "UNSUBSCRIBE_ERROR";


export const subscribe = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "subscribe"))
        await doPost(_URL_SUBSCRIBE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "subscribe"))
                if(response.data 
                    && response.data.object 
                    && response.data.object.data 
                    && response.data.object.data.ids){
                        const { ids, errors } = response.data.object.data
                    if (errors && Object.keys(errors).length) {
                        if (JSON.stringify(errors).includes(DUPLICATE_SUBSCRIPTION_MESSAGE)) {
                            dispatch({
                                type: SUBSCRIBE_ERROR,
                                payload: { error: ["footer.subscribe_duplicate"] }
                            })
                        } else {
                            dispatch({
                                type: SUBSCRIBE_ERROR,
                                payload: { error: ["footer.subscribe_failed_msg"] }
                            })
                        }
                    } else {
                        dispatch({
                            type: SUBSCRIBE_DATA,
                            payload: ids
                        })
                    }
                }
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "subscribe"))
                dispatch({
                    type: SUBSCRIBE_ERROR,
                    payload: { error: ["footer.subscribe_failed_msg"] }
                })
            })
    };
}
export const unSubscribe = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("unsubscribe"))
        await doPost(_URL_UNSUBSCRIBE, payload)
            .then((response) => {
                dispatch(stopApiLoading("unsubscribe"))
                dispatch({
                    type: UNSUBSCRIBE_DATA,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("unsubscribe"))
                dispatch({
                    type: UNSUBSCRIBE_ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    };
}