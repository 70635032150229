/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { MFA_SECURITY_TOKEN, MFA_VALIDATE_SECURITY_TOKEN, MFA_RESPONSE } from "./action";

export default function mfaReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === MFA_SECURITY_TOKEN) {
        return {
            token: payload
        }
    } else if(type === MFA_VALIDATE_SECURITY_TOKEN){
        return {
            validate: payload
        }
    }else if(type === MFA_RESPONSE){
        return{
            response: payload
        }
    } else {
        return state;
    }
}
