/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
    TRANSACTION_AUTHENTICATE_SUCCESS,
    BUY_POINT_SUCCESS,
    BUY_POINT_ACCEPT_PAYMENT_SUCCESS,
    TRANSACTION_AUTHENTICATE_LOGOUT_SUCCESS,
    SIMULATE_RULE_SERVICE,
    TIER_UPGRADE_SUCCESS,
    GIFT_POINTS_SUCCESS
} from './action';

export function authenticateTransactionReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if (type === TRANSACTION_AUTHENTICATE_SUCCESS) {
        const authenticateTransaction = payload.object
        return {
            authenticateTransaction
        }
    } else {
        return state
    }
}

export function buyPointReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === BUY_POINT_SUCCESS) {
        const buyPointData = payload.object
        return {
            buyPointData
        }
    } else {
        return state
    }
}

export function buyPointAcceptReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === BUY_POINT_ACCEPT_PAYMENT_SUCCESS) {
        const acceptPayment = payload.object
        return {
            acceptPayment
        }
    } else {
        return state
    }
}

export function logoutTransactionReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === TRANSACTION_AUTHENTICATE_LOGOUT_SUCCESS) {
        const logoutTransaction = payload.object
        return {
            logoutTransaction
        }
    } else {
        return state
    }
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function simulateRuleReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === SIMULATE_RULE_SERVICE) {
        const simulateRule = payload.object
        return {
            simulateRule
        }
    } else {
        return state
    }
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function tierUpgradeReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === TIER_UPGRADE_SUCCESS) {
        const tierUpgrade = payload.object
        return {
            tierUpgrade
        }
    } else {
        return state
    }
}

export function giftPointsReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === GIFT_POINTS_SUCCESS) {
        const giftPointData = payload.object
        return {
            giftPointData
        }
    } else {
        return state
    }
}