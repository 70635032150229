/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React, { Component } from 'react';
import parse from 'html-react-parser';
import Button from '../../common/components/fieldbank/Button';
import { redirectToPage } from '../../common/utils';

class CommonMessage extends Component {

    getImage = (image, type, imgAlt) => {
        if(type == "inline") {
            return parse(image)
        } else {
            return <img src={image} alt={imgAlt?imgAlt:""} />
        }
    }

    handleOnClick = (link) => {
        redirectToPage(`${link}`)
        window.location.reload()
    }

    render() {
        const { image, imageType, imgAlt, title, description1, description2, buttons } = this.props
        return (
            <div className={`status-page ${image?"status-page--with-pic":"text-center"}`}>
                <div className="status-page__inner">
                    <div className="row">
                        {
                            image ?
                            <div className="col-lg-4 col-md-4 status-page_img">
                                {
                                    this.getImage(image, imageType, imgAlt)
                                }
                            </div>:<></>
                        }
                        <div id="id-error-message" className="col status-page_description">
                            {title?parse(title):<></>}
                            {description1?<p>{description1}</p>:<></>}
                            {description2?<p>{description2}</p>:<></>}
                            {
                                buttons ?
                                <div className="btn-wrap">
                                    {
                                        buttons.map( (button, idx) => {
                                            return <Button
                                                className={button.className?button.className:"btn btn-primary"}
                                                handleOnClick={() => this.handleOnClick(button.link)}
                                                key={button.id?button.id:idx}
                                                id={button.id?button.id:idx}
                                                data-test={button.id?button.id:idx}
                                                label={button.label?button.label:""} 
                                            />
                                        })
                                    }
                                </div>:<></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonMessage;