/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import axios from 'axios'
export const axiosInstance = axios.create();
import {
    _DEFAULT_CLIENT_TYPE,
    _DEFAULT_LANGUAGE,
    _URL_FETCH_REFRESH_TOKEN,
    _URL_FETCH_TOKEN,
    _URL_FETCH_PS_TOKEN_WITH_ID_TOKEN
} from '../config/config'
import {
    getItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_TOKEN,
    BROWSER_STORAGE_KEY_IDP_TOKEN,
    BROWSER_STORAGE_KEY_i18_LANG,
    BROWSER_STORAGE_KEY_REFRESH_TOKEN,
    setItemToBrowserStorage,
    BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING,
    removeItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_ACCESS_KEY,
    BROWSER_STORAGE_KEY_CID,
    clearBrowserStorage,
    BROWSER_STORAGE_TYPE_SESSION,
    BROWSER_STORAGE_CUSTOMER_GUID
} from './storage.utils'
import { isEmptyOrSpaces, sleep, redirectToPage } from '.';
import { ROUTER_CONFIG } from '../config/routing';
import { store } from '../middleware/store';
import { FETCH_TOKEN } from '../middleware/redux/commonAction';
import { _RESPONSE_HEADER_REFRESH_TOKEN, _RESPONSE_HEADER_TOKEN } from './urlParams.utils';
import { CLIENT_ID, SECRET, MFA } from './Constants';
import {Authsignal} from "./../@authsignal/browser";

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    async config => {
        const lan = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
        const guId = getItemFromBrowserStorage(BROWSER_STORAGE_CUSTOMER_GUID)
        const state = store.getState()
        const psToken =  getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        const token =  getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IDP_TOKEN)
        config.headers = {
            'Content-Type': 'application/json',
            'Accept-Language': lan ? JSON.parse(lan).code : _DEFAULT_LANGUAGE,
            ...config.headers
        }
        if (!isEmptyOrSpaces(guId)) {
            config.headers['userId'] = guId;
        }
        // if (!isEmptyOrSpaces(psToken) && config.url !== _URL_FETCH_PS_TOKEN_WITH_ID_TOKEN) {
        if (!isEmptyOrSpaces(psToken)) {
            config.headers['Authorization'] = 'Bearer ' + psToken
        } 
        if (!isEmptyOrSpaces(token)) {
            config.headers['idpToken'] = token
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

let accessTokenResponse = {}

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config
    const state = store.getState()
    let { token, refreshToken } =  state.tokenReducer
    let isRefreshTokenFetchInprogress = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING)
    // if(isEmptyOrSpaces(refreshToken)) {
    //     const { headers } = error.response
    //     token = headers[_RESPONSE_HEADER_TOKEN]
    //     refreshToken = headers[_RESPONSE_HEADER_REFRESH_TOKEN]
    // }
    if (!isEmptyOrSpaces(refreshToken) &&
        error &&
        error.response &&
        error.response.status == 401 && 
        error.response.data &&
        error.response.data.message && 
        (error.response.data.message.includes("Token expired") 
        || error.response.data.message.includes("Invalid access token")) && 
        !originalRequest._retry) {
        originalRequest._retry = true;
        if (isRefreshTokenFetchInprogress !== 'true') {
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING, true)
            accessTokenResponse = await getRefreshAccessToken(_URL_FETCH_REFRESH_TOKEN, 
                {
                    "RefreshToken": refreshToken,
                    "Authorization": 'Bearer ' + token,
                    secret: window.sessionStorage.getItem(SECRET),
                    clientId: window.sessionStorage.getItem(CLIENT_ID),
                    clientType: _DEFAULT_CLIENT_TYPE
                })
        } else {
            while(isRefreshTokenFetchInprogress === 'true') {
                isRefreshTokenFetchInprogress = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING)
                await sleep(1000);
            }
        }
        if (accessTokenResponse) {
            const { data, headers } = accessTokenResponse
            const refreshToken = headers[_RESPONSE_HEADER_REFRESH_TOKEN]
            const token = headers[_RESPONSE_HEADER_TOKEN]
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING, false)
            if (data.success && refreshToken && token) {
                var date = new Date();
                date.setDate(date.getDate() + 1);
                store.dispatch({
                    type: FETCH_TOKEN,
                    payload: {
                        token,
                        refreshToken
                    }
                })
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            }
        }
        return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
});


// axiosInstance.interceptors.response.use( 
//     response => {
//         return response;
//     },
//     async error => {
//         if (error.response.status === 412 && error.response.data.error.additionalInfo.url) {
//             const authsignal = new Authsignal( MFA.AUTHSIGNAL )
//             if (error.response.data.error.additionalInfo.url) {
//                 let url = error.response.data.error.additionalInfo.url+encodeURIComponent(window.location.protocol+'//'+window.location.host);
//                 let popup = await authsignal.launch(url, {
//                     mode: "popup",
//                     popupOptions: {
//                         width: "500px",
//                     },
//                 });
//                 let mfaheaderParams = {"mfaCompleted": "true", "idempotencyKey": error.response.data.error.additionalInfo.idempotencyKey};
//                 error.config.headers = {...error.config.headers, ...mfaheaderParams};
//                 return axiosInstance(error.config);
//             } else {
//                 let error = { 
//                     "response": {
//                       "data": {
//                         "statuscode": "400",
//                         "statusMessage": "FAILURE",
//                         "error": {
//                           "code": "400",
//                           "type": "BAD_REQUEST",
//                           "message": "",
//                           "errorDetails": [{
//                               "message": "We could not verify your multifactor authentication. Retry action to proceed."
//                            }]
//                         }
//                       }
//                     }
//                   }
//                 return Promise.reject(error);
//             }
//         } else {
//             return Promise.reject(error);
//         }
//     }
//   );

export const getRefreshAccessToken = async (url, headers = {}) => {
    try {
        axiosInstance.get(url, { headers })
        .then(response => {
        return response
        }).catch(error=> {
            console.log(error)
        });
    } catch (error) {
        clearBrowserStorage();
        clearBrowserStorage(BROWSER_STORAGE_TYPE_SESSION);
        redirectToPage(ROUTER_CONFIG.default.url);
        window.location.reload();
    }
}

export const doPost = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doGet = (url, headers = {}) => {
    return axiosInstance.get(url, { headers })
}

export const doPostEnrol = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doPostResetPassword = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doPostLogin = (url, payload, headers = {}) => {
    const lan = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
    const header = {
        'Content-Type': 'application/json',
        'clientId': payload.clientId,
        'secret': payload.secret,
        'clientType' : payload.clientType,
        'Accept-Language': lan ? JSON.parse(lan).code : _DEFAULT_LANGUAGE,
        ...headers
    }
    return axios.post(url, payload.data, { headers: header })
}

