/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { doPost } from '../../common/utils/api'
import {
    _URL_FETCH_FLIGHTS
} from '../../common/config/config'
import { startApiLoading, stopApiLoading } from '../../common/components/fieldbank/loader/action';

// Action Type
export const ACTION_FETCH_FLIGHTS_SUCCESS = "ACTION_FETCH_FLIGHTS_SUCCESS";
export const ACTION_FETCH_FLIGHTS_FAILURE = "ACTION_FETCH_FLIGHTS_FAILURE";

/**
 * 
 * @param {string} uriPath 
 * @param {object} payload 
 */
export const actionFetchFlights = (uriPath, payload={}) => {
    let _URL = `${_URL_FETCH_FLIGHTS}${uriPath}`
    return async dispatch => {
        dispatch(startApiLoading("actionFetchFlights"))
        return await doPost(_URL, payload)
        .then(response=>{
            dispatch(stopApiLoading("actionFetchFlights"))
            dispatch({
                type: ACTION_FETCH_FLIGHTS_SUCCESS,
                payload: {
                    ...response.data,
                    dataType: uriPath.replace("/", "")
                }
            });
        }).catch(error => {
            dispatch(stopApiLoading("actionFetchFlights"))
            dispatch({
                type: ACTION_FETCH_FLIGHTS_FAILURE,
                payload: error
            })
        })
    }
}