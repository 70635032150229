/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import { doPost } from "../../../../common/utils/api"
import { 
    _URL_RETRIEVE_TRAVELLERS, 
    _URL_RETRIEVE_ACCOUNT_USERS, 
    _URL_RETRIEVE_CUSTOMER_PROFILE,
    _URL_ADD_TRAVELLER,
    _URL_ADD_NOMINEE,
    _URL_UPDATE_TRAVELLER,
    _URL_UPDATE_NOMINEE,
    _URL_DELETE_TRAVELLER,
    _URL_DELETE_NOMINEE
} from "../../../../common/config/config"
import { getApiErrorMessage } from "../../../../common/utils"
import { ERROR } from "../../../../common/middleware/redux/commonAction"
import { TAB_TRAVELLERS, TAB_ACCOUNT_USERS } from "./Constants"
import { startButtonSpinner, stopButtonSpinner, startApiLoading, stopApiLoading } from "../../../../common/components/fieldbank/loader/action";

export const ACTION_RETRIEVE_NOMINEES_SUCCESS = "ACTION_RETRIEVE_NOMINEES_SUCCESS"
export const ACTION_RETRIEVE_CUSTOMER_SUCCESS = "ACTION_RETRIEVE_CUSTOMER_SUCCESS"
export const ACTION_ADD_TRAVELLER_SUCCESS = "ACTION_ADD_TRAVELLER_SUCCESS"
export const ACTION_ADD_NOMINEE_SUCCESS = "ACTION_ADD_NOMINEE_SUCCESS"
export const ACTION_UPDATE_TRAVELLER_SUCCESS = "ACTION_UPDATE_TRAVELLER_SUCCESS"
export const ACTION_UPDATE_NOMINEE_SUCCESS = "ACTION_UPDATE_NOMINEE_SUCCESS"
export const ACTION_DELETE_TRAVELLER_SUCCESS = "ACTION_DELETE_TRAVELLER_SUCCESS"
export const ACTION_DELETE_NOMINEE_SUCCESS = "ACTION_DELETE_NOMINEE_SUCCESS"

export const retrieveNominees = (payload, typeOfNominee) => {
    const URL = (typeOfNominee == TAB_TRAVELLERS) ? _URL_RETRIEVE_TRAVELLERS : (typeOfNominee == TAB_ACCOUNT_USERS) ? _URL_RETRIEVE_ACCOUNT_USERS : ""

    return async dispatch => {
        dispatch(startApiLoading("retrieveNominees"))
        await doPost(URL, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveNominees"))
                dispatch({
                    type: ACTION_RETRIEVE_NOMINEES_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveNominees"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const retrieveCustomer = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("retrieveCustomer"))
        await doPost(_URL_RETRIEVE_CUSTOMER_PROFILE, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveCustomer"))
                dispatch({
                    type: ACTION_RETRIEVE_CUSTOMER_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveCustomer"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const addTraveller = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "addTraveller"))
        await doPost(_URL_ADD_TRAVELLER, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "addTraveller"))
                dispatch({
                    type: ACTION_ADD_TRAVELLER_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "addTraveller"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const addNominee = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "addNominee"))
        await doPost(_URL_ADD_NOMINEE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "addNominee"))
                dispatch({
                    type: ACTION_ADD_NOMINEE_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "addNominee"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const updateTraveller = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("updateTraveller"))
        await doPost(_URL_UPDATE_TRAVELLER, payload)
            .then((response) => {
                dispatch(stopApiLoading("updateTraveller"))
                dispatch({
                    type: ACTION_UPDATE_TRAVELLER_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("updateTraveller"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const updateNominee = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("updateNominee"))
        await doPost(_URL_UPDATE_NOMINEE, payload)
            .then((response) => {
                dispatch(stopApiLoading("updateNominee"))
                dispatch({
                    type: ACTION_UPDATE_NOMINEE_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("updateNominee"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const deleteTraveller = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("deleteTraveller"))
        await doPost(_URL_DELETE_TRAVELLER, payload)
            .then((response) => {
                dispatch(stopApiLoading("deleteTraveller"))
                dispatch({
                    type: ACTION_DELETE_TRAVELLER_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("deleteTraveller"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const deleteNominee = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("deleteNominee"))
        await doPost(_URL_DELETE_NOMINEE, payload)
            .then((response) => {
                dispatch(stopApiLoading("deleteNominee"))
                dispatch({
                    type: ACTION_DELETE_NOMINEE_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("deleteNominee"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}