/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
  CONFIG_CLAIM_SUMMARY_SUCCESS,
  RETRIEVE_RETRO_CLAIM_SUCCESS
} from './actions';

export function retroClaimRequestsReducer(state, action) {
  const { type, payload } = action;
  if(!state) state = {}
  switch (type) {
    case RETRIEVE_RETRO_CLAIM_SUCCESS:
      return {
        ...state,
        retrievedRetroClaimRequests: payload.object
      }

    case CONFIG_CLAIM_SUMMARY_SUCCESS:
      return {
        ...state,
        claimSummaryConfig: payload
      }

    default:
      return state;
  }
}