/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React from 'react'
import {
    _URL_SOCIAL_LOGIN, _URL_CALLBACK
} from '../config/config';
var jwtDecode = require('jwt-decode')
import axios from 'axios'

export const openPopup = (platform, source="enroll") => {
    const width = 600, height = 600;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    const url = _URL_SOCIAL_LOGIN + "/" + platform + "?redirect_uri=" + encodeURIComponent(_URL_CALLBACK)+'&companyCode=IBS&programCode=PRG14&source=enroll'
    window.open(url, '',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbar=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
}

export const getUserBySocialMedia = (token, url, headers={}) => { 
    const header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        ...headers
    }
    return axios.get(url,{ headers: header })
}

export const getActivePage = () =>{
    const page = location.href;
    return page.split('#')[1].replace('/',''); 
}

export const extractJWT = (token) => {
    return jwtDecode(token);
}

export const getMyFlights = () =>{    
    return axios.get('data/myflights.json').then((response)=>{
       return response.data;
    })
}

export const prepareMasterEntityLookup = (masterEntityData) => {
    const masterEntityLookup = {}
    masterEntityData.forEach(entity => {
        masterEntityLookup[entity.key] = entity.value
    })
    return masterEntityLookup
}

export const prepareMasterEntityLookupFilters = (masterEntityData) => {
    const masterEntityLookupFilters = {}
    masterEntityData.forEach(entity => {
       if(entity.filterFieldDetail){
        masterEntityLookupFilters[entity.key] = entity.filterFieldDetail
       }
    })
    return masterEntityLookupFilters
}
export const getLogoutMessageData = (action) => {
    if(action == "auto") {
        return `ti=alot&de=alod`
    } else {
        return `ti=lot&de=lod`
    }
}