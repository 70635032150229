/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import React from 'react';
import { withSuspense } from '../../utils';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonLoader from './loader/ButtonLoader';

/**
 * @name Custom Button component(Button).
 * @description Primary button component.
 * 
 * @author Rohith
 */
class Button extends React.Component {

    render() {
        const {
            label,
            id,
            testIdentifier,
            enabled,
            className,
            handleOnClick,
            type,
            modal
        } = this.props
        const isEnable = this.props.loaderReducer && this.props.loaderReducer.inprogress != 0

        return (
            <>
                <label className="sr-only" htmlFor={id}>{label}</label>
                <button
                    id={id}
                    disabled={!enabled || isEnable}
                    onClick={(e) => {handleOnClick(e) }}
                    data-test={testIdentifier}
                    type={type}
                    data-dismiss={modal}
                    className={className}>
                    <ButtonLoader buttonId={id} />
                    {label}
                </button>
            </>
        )

    }
}

Button.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    testIdentifier: PropTypes.string,
};

Button.defaultProps = {
    label: "",
    enabled: true,
    className: "btn btn-primary",
    type: "button"
}

function mapStateToProps(state) {
    return {
        loaderReducer: state.loaderReducer
    }
}

export default withSuspense()(connect(mapStateToProps, {})(withTranslation()(Button)));