import jwt from 'jsonwebtoken';

const checkTokenExpiry = (token) => {
    try {
        const decoded = jwt.decode(token);
        const expirationTime = decoded.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        return expirationTime > currentTime;
    } catch (error) {
        // Token decoding failed
        console.error('Token decoding failed:', error.message);
        return false;
    }
};

export default checkTokenExpiry;