/*************************************************************************
 * Copyright (C) IBS Software 2019
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 **************************************************************************/

import {
    startApiLoading,

    startButtonSpinner, stopApiLoading,

    stopButtonSpinner
} from '../../../common/components/fieldbank/loader/action';
import {
    _URL_FETCH_PROFILE_DETAILS, _URL_UPDATE_PROFILE_DETAILS
} from '../../../common/config/config';
import {
    fetchAccountSummary
} from '../../../common/middleware/redux/commonAction';
import { getApiErrorMessage, redirectToPage } from '../../../common/utils';
import { doPost } from '../../../common/utils/api';
import { PROGRAM_TYPE_INDIVIDUAL } from '../../../common/utils/Constants';
import {
    BROWSER_STORAGE_KEY_COMPANY_CODE,
    BROWSER_STORAGE_KEY_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE, getItemFromBrowserStorage
} from '../../../common/utils/storage.utils';
import { NAVIGATE_PROFILE_COMPLETION } from '../../../common/utils/urlConstants';
import setProfileData from './reducer';
import { _CONTEXT_PATH } from './../../../common/config/config';
import { NAVIGATE_CORPORATE } from './../../../common/utils/urlConstants';

// Action Type
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PROFILE_DATA_COMP = "SET_PROFILE_DATA_COMP"
export const SET_PROFILE_PICTURE = "SET_PROFILE_PICTURE";
export const ERROR_PROFILE_FETCH = "ERROR_PROFILE_FETCH";
export const SET_PROFILE_CONFIG = "SET_PROFILE_CONFIG";
export const ERROR = 'ERROR';


export const fetchCompanyProfile = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            // membershipNumber: "CC4962",
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("companyProfile"))
        await doPost(_URL_FETCH_PROFILE_DETAILS, requestBody)
            .then((response) => {
                dispatch(stopApiLoading("companyProfile"))
                const object = response.data.object;
                dispatch({
                    type : SET_PROFILE_DATA_COMP,
                    payload : object
                })
                
            })
            .catch((error) => {
                dispatch(stopApiLoading("companyProfile"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const updateProfileDetails = (profileData, id) => {
    const newRequest = JSON.parse(JSON.stringify(profileData));
    delete newRequest.object.memberAccount.memberProfile.individualInfo;
    return dispatch => {
        dispatch(startButtonSpinner(id, "updateProfileDetails"))
        return doPost(_URL_UPDATE_PROFILE_DETAILS, newRequest)
            .then(response => {
                if(profileData.object.memberAccount.accountStatus == 'C') {
                    window.location.href = `${_CONTEXT_PATH}${NAVIGATE_CORPORATE}?accountStatus=close`;
                } else {
                    dispatch(stopButtonSpinner(id, "updateProfileDetails"))
                    dispatch(fetchCompanyProfile())
                    dispatch({
                        type: ERROR,
                        payload: { error: ['profile.successMessage'] }
                    })
                }
             window.scrollTo(0, 0)
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "updateProfileDetails"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}